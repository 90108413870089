import React, { useState, useEffect } from "react";

import {
    requestWithBody,
    handleRequestError
} from '../../../../utils';

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

const ClientArchive = ({clientAgent, success, errorCallback}) => {

    const [submitting, setSubmitting] = useState(false);

    const archive = (st) => {
        setSubmitting(true);

        // EDIT
        requestWithBody(
            "PATCH",
            process.env.REACT_APP_API_URL + '/crm/client_agents/' + clientAgent.id + '/',
            {archived: st}
        ).then(response => {
            console.log(
                "Client updated with success!",
                response
            );
            success(response);
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                "Error updating client!"
            )
            .then(e => {
                errorCallback(e.detail);
            })
        ).finally(() => {
            setSubmitting(false);
        });

    }

    return (
        <Grid ml={1}>
            <Button 
                color="primary" 
                variant="outlined" 
                sx={{ mr: 1 }}
                onClick={() => archive(!clientAgent.archived)}
                disabled={submitting}
            >
                {clientAgent.archived ? 'Remover do arquivo' : 'Arquivar'}
            </Button>
        </Grid>
    );
}

export default ClientArchive;