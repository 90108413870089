import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import FormGroup from "../../../../../Chavelusa/FormGroup";
import PropertySheetElementsGlobalForm from "../GlobalForm";

import { colors } from "../../../../../../utils";
import { TYPE_AGENT } from '../../enum';

/**
 * AGENT ELEMENT COMPONENT 
 * 
 * Element structure:
 * COMMON (refer to GlobalForm for complete structure)
 * - type: str          AGENT
 * SPECIFIC
 * - image: str         Agent image url
 * - name: str          Agent name
 * - contact: str       Agent contact
 */

const PropertySheetElementAgent = ({ element, setElement, property, totalCols }) => {


    const edit = !!setElement;

    console.log("PropertySheetElementAgent", element, edit);

    const [localElement, setLocalElement] = useState({});

    useEffect(() => {
        let localCandidate = element ? { ...element } : {};
        // Default properties
        if (!('height' in localCandidate)) localCandidate['height'] = 30;
        if (!('cols' in localCandidate)) localCandidate['cols'] = 4;
        if (!('type' in localCandidate)) localCandidate['type'] = TYPE_AGENT;
        if (property) {
            if (!('image' in localCandidate)) localCandidate['image'] = property.agent.image;
            if (!('name' in localCandidate)) localCandidate['name'] = property.agent.name;
            if (property.agent.contact)
                if (!('contact' in localCandidate)) localCandidate['contact'] = property.agent.contact.toString().toLocaleString();
        }
        setLocalElement(localCandidate);
    }, [element]);

    useEffect(() => {
        console.log("PropertySheetElementAgent localElement", localElement);
    }, [localElement]);

    return (
        <Grid
            xs={12}
            sx={edit ? {} : {
                height: '100%'
            }}
        >
            {
                edit &&
                <Typography variant='subtitle'>
                    Pré-visualização
                </Typography>
            }
            <Grid
                xs={edit ? (localElement.cols ? localElement.cols : 12) : 12}
                sx={!edit ? {
                    height: '100%',
                } : {
                    height: localElement.height ? localElement.height + 'vh' : '',
                    border: '1px dashed ' + colors.primary,
                    overflow: 'hidden',
                    mx: 'auto'
                }}
                container
                direction="column"
                wrap="nowrap"
                alignItems='center'
                justifyContent='center'
            >
                {
                    localElement.image &&
                    <img 
                        src={process.env.REACT_APP_STATIC + localElement.image} 
                        style={{
                            borderRadius: '50%',
                            height: 'auto',
                            width: '50%',
                            maxWidth: '300px'
                        }}
                    />
                }
                <Typography variant="h7" sx={{fontWeight: 'bold', mt: 2, mb: 1, textAlign: 'center'}}>{localElement.name}</Typography>
                {
                    localElement.contact &&
                    <Typography variant="body1" sx={{color: colors.primary, textAlign: 'center'}}>{localElement.contact}</Typography>
                }
            </Grid>
            {
                edit &&
                <>
                    <Grid mt={3}>
                        <Typography variant='subtitle'>
                            Editar propriedades do agente
                        </Typography>
                        {
                            localElement && Object.keys(localElement).length > 0 &&
                            <>
                                <PropertySheetElementsGlobalForm
                                    element={localElement}
                                    setElement={setLocalElement}
                                    totalCols={totalCols}
                                    elementOriginal={element}
                                />
                            </>
                        }
                        {
                            localElement &&
                            <Button color="primary" variant="contained" sx={{ width: '100%', mt: 1 }} onClick={() => setElement(localElement)}>
                                {!element ? "Adicionar agente" : "Editar agente"}
                            </Button>
                        }
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default PropertySheetElementAgent;