import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography
} from '@mui/material';

import Stepper from  '../../../Chavelusa/Stepper';

import TemplatesFormChoice from './Choice';
import TemplatesFormCustomize from "./Customize";

/**
 * TemplatesForm components
 * 
 * @param {dict} recipients List of recipients following the structure {<id:Integer>: <model:String>} 
 * @param {method} success Method called when message sent, receives parameter list of MessageRecipients returned by server
 */
const TemplatesForm = ({recipients, success}) => {

    console.log("TemplatesForm", recipients, success);

    const [activeStep, setActiveStep] = useState(undefined);
    const [template, setTemplate] = useState(undefined);

    return (
        <Stepper 
            title={'Enviar mensagem'}
            steps={[
                {
                    label: 'Escolher modelo',
                    component: <TemplatesFormChoice 
                        selectTemplate={(templateId) => {
                            setTemplate(templateId);
                            setActiveStep(1);
                        }}
                    />
                },
                {
                    label: 'Escrever mensagem',
                    component: <TemplatesFormCustomize 
                        templateId={template}
                        recipients={recipients}
                        success={success}
                    />
                },
                {
                    label: 'Pré-visualizar e enviar'
                }
            ]}
            activeStepsInitial={[0]}
            activeStepOverride={activeStep}
        />
    );
}

export default TemplatesForm;