import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip
} from '@mui/material';

import {
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    Event as EventIcon,
    Home as HomeIcon,
    KingBed as KingBedIcon,
    Archive as ArchiveIcon,
    StickyNote2 as StickyNote2Icon,
    Assignment as AssignmentIcon,
    Euro as EuroIcon,
    SyncLock as SyncLockIcon
} from '@mui/icons-material'

import {reduceString} from "../../../../utils";

import ClientClassificationWindow from "../Window";


const ClientClassificationBanner = ({ classification, clientAgent, setWindow, windowChanged, updateElement, openEdit, selected }) => {

    console.log("COMPONENT ClientClassificationBanner", classification);
    
    const getWindow = () => {
        return (
            <ClientClassificationWindow 
                classification={classification} 
                clientAgent={clientAgent} 
                updateElement={updateElement} 
                openEdit={openEdit} 
                paps={this}
                windowChanged={windowChanged}
                setWindow={setWindow}
                backToClassificationWindow={() => setWindow(getWindow())}
            />
        )
    }

    // Handle window
    const open = () => {
        if (!selected) {
            console.log("Opening classification window...");
            setWindow(getWindow());
        } else {
            setWindow(null);
        }
    }

    // When classification instance is updated, if selected, update window
    useEffect(() => {
        if (selected) {
            setWindow(getWindow());
        }
    }, [classification]);

    return (
        <Grid
            container
            direction="row"
            className={!selected ? "elementSelectable" : "elementSelectable selected"}
            my={4}
            onClick={open}
        >
            {
                <Grid
                    xs={2}
                    m={0}
                    container
                >
                    <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                        <AssignmentIcon color={(classification.closed || !classification.editable) ? "secondary" : "primary"} fontSize="inherit"/> 
                    </Typography>
                </Grid>
            }
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>Qualificação</Typography>
                <Grid container>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Tipo de cliente">
                            <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {classification.clientType}
                        {classification.transfer && " / Aceita trespasse"}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Tipos de propriedade">
                            <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {classification.propertyTypes.map(pt => pt.propertyType.name).join(', ')}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Localizações">
                            <LocationOnIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {classification.locations.map(l => 
                            l.parish ? l.parish.name + ', ' + l.parish.county.name + ', ' + l.parish.county.district.name
                            : l.county ? l.county.name + ', ' + l.county.district.name 
                            : l.district ? l.district.name
                            : ''
                        ).join(' / ')}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Quartos">
                            <KingBedIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {classification.bedrooms}
                    </Typography>   
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Investimento">
                            <EuroIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {classification.investment.toLocaleString()}€ 
                        {classification.financing && " / Tem financiamento"}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Criada a">
                            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {new Date(classification.created).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </Typography>
                </Grid>
                {
                    (classification.editable && (!classification.closed && classification.notes) || (classification.closed && classification.closingNote)) && 
                    <Tooltip 
                        title={<div style={{ whiteSpace: 'pre-line' }}>{classification.closed ? classification.closingNote : classification.notes}</div>}
                    >
                        <Typography my={2}>
                            <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                            {reduceString(classification.closed ? classification.closingNote : classification.notes, 75)}
                        </Typography>
                    </Tooltip>
                }
                {
                    (classification.closed || !classification.editable) &&
                    <Chip
                        label={!classification.generated_by ? "Qualificação fechada" : "Qualificação gerada por agente anterior"}
                        icon={classification.editable ? <ArchiveIcon fontSize="small" /> : <SyncLockIcon fontSize="small" />}
                        color="secondary"
                        variant="outlined"
                        sx={{ mt: 3 }}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default ClientClassificationBanner;