import React from "react";

import {
    Typography,
    Link,
    Grid,
    Tooltip
} from '@material-ui/core';

import {
    Phone as PhoneIcon,
    Email as EmailIcon,
    Verified as VerifiedIcon
} from '@mui/icons-material';

const Contacts = ({ phone, phoneSecondary, email, emailSecondary, preferential }) => {
    return (
        <Grid
            container
            direction="row"
            mt={5}
        >
            {
                phone &&
                <Grid
                    container
                    direction="row"
                    xs="auto"
                    mr={3}
                >
                    <Link
                        href={"tel:" + phone}
                        variant="subtitle1"
                        underline="none"
                        sx={{ display: 'flex' }}
                    >
                        {preferential==='C' && <Tooltip title="Método de contacto preferencial"><VerifiedIcon sx={{mr:1}} /></Tooltip>}
                        <PhoneIcon sx={{ mr: 1 }} />
                        <Typography variant='subtitle1'>{phone}</Typography>
                    </Link>
                </Grid>
            }

            {
                phoneSecondary &&
                <Grid
                    container
                    direction="row"
                    xs="auto"
                    mr={3}
                >
                    <Link
                        href={"tel:" + phoneSecondary}
                        variant="subtitle1"
                        underline="none"
                        sx={{ display: 'flex' }}
                    >
                        <PhoneIcon sx={{ mr: 1 }} />
                        <Typography variant='subtitle1'>{phoneSecondary}</Typography>
                    </Link>
                </Grid>
            }

            {
                email &&
                <Grid
                    container
                    direction="row"
                    xs="auto"
                    mr={3}
                >
                    <Link
                        href={"mailto:" + email}
                        variant="subtitle1"
                        underline="none"
                        sx={{ display: 'flex' }}
                    >
                        {preferential==='E' && <Tooltip title="Método de contacto preferencial"><VerifiedIcon sx={{mr:1}} /></Tooltip>}
                        <EmailIcon sx={{ mr: 1 }} />
                        <Typography variant='subtitle1'>{email}</Typography>
                    </Link>
                </Grid>
            }

{
                emailSecondary &&
                <Grid
                    container
                    direction="row"
                    xs="auto"
                    mr={3}
                >
                    <Link
                        href={"mailto:" + emailSecondary}
                        variant="subtitle1"
                        underline="none"
                        sx={{ display: 'flex' }}
                    >
                        <EmailIcon sx={{ mr: 1 }} />
                        <Typography variant='subtitle1'>{emailSecondary}</Typography>
                    </Link>
                </Grid>
            }

        </Grid>
    );
}

export default Contacts;