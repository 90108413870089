import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button,
    Snackbar, Alert
} from '@mui/material';

import SelectTable from "../../../../Components/Chavelusa/SelectTable";

import {
    buildParams,
    requestGET,
    handleRequestError
} from '../../../../utils';

import Window from "../../../../Components/Chavelusa/Window";
import PresentationReproduce from "../../../../Components/Realstate/Presentation/Reproduce";

import { presentationForm } from "../../../../Components/Realstate/Presentation/Form/launcher";

let aborter = null;

/**
 * Optional parameters when page is used as an inner component
 *
 */
const PresentationList = ({
}) => {

    // Loading
    const [loading, setLoading] = useState(true);
    // Content loading and management
    const utils = require('./utils.js');
    const url = process.env.REACT_APP_API_URL + '/realstate/presentations/';
    const [params, setParams] = useState({});
    const [data, setData] = useState(null);

    // When component initialized, load filters
    useEffect(() => {
        loadFilters();
    }, []);

    useEffect(() => {
        // Load clients from API
        loadData();
    }, [params]);

    // Get data from API
    const loadData = () => {
        setLoading(true);

        // cancel pending request if any
        if (aborter) aborter.abort();
        // make our request cancellable
        aborter = new AbortController();
        const signal = aborter.signal;

        requestGET(url + buildParams(params), signal)
            .then(response => {
                aborter = null;
                setData(response);
                setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting presentations list"
                )
            ).finally(() => {
                aborter = null;
            });
    }

    // Get filters from API
    const [filters, setFilters] = useState([]);
    const [filtersData, setFiltersData] = useState({});
    const loadFilters = () => {
    }
    useEffect(() => {
        setFilters(utils.buildFilters(filtersData));
    }, [filtersData]);

    const addParam = (newVals) => {
        setParams({
            ...params,
            ...newVals
        })
    }

    /**
     * A method to update current state 
     * @param {String[]} paramsRemove An array with the ids of the fields to delete
     * @param {String} id The id of the field to update 
     * @param {*} val The value to update the last with     
     */
    const updateParams = (paramsRemove, id = null, val = null) => {
        let copy = null;
        if (id) {
            copy = { ...params, [id]: val }
        } else {
            copy = { ...params }
        }
        paramsRemove.forEach((key) => {
            delete copy[key];
        })
        setParams(copy);
    }

    // Window 
    const [window, setWindow] = useState(undefined);
    const [windowChanged, setWindowChanged] = useState(false);
    useEffect(() => {
        // When window changes, reset changed property
        if (window === null)
            setWindowChanged(false);
    }, [window]);

    const openWindow = (w, id = null, transition = false) => {
        if (transition) {
            setWindow(null);
            setTimeout(() => {
                setWindow(w);
            }, 500);
        } else {
            setWindow(w);
        }
    }

    // NOTIFY
    const [notification, setNotification] = useState(undefined);

    // FORM 
    const openPresentationForm = (obj) => {
        openWindow(presentationForm(
            obj,
            (newInstance) => {
                setNotification({ status: 'success', text: !obj ? 'Apresentação criada com sucesso' : 'Apresentação atualizada com sucesso' })
                setWindow(null);
                setData((oldData) => {
                    var index = oldData.results.findIndex(d => d.id === newInstance.id);
                    var newData = { ...oldData };
                    if (index>=0)
                        newData.results[index] = newInstance;
                    else 
                        newData.results.unshift(newInstance);
                    return newData;
                });
            },
            () => setWindowChanged(true)
        ), null, true)
    }

    return (
        <Grid mt={5} pr={window ? 3 : 0} xs={!window ? 12 : 6}>
            <SelectTable
                entityNameSingular={'apresentação'}
                data={data}
                loading={loading}
                cols={utils.cols}
                getRow={(obj) => utils.getRow(
                    obj,
                    () => openWindow(<PresentationReproduce presentation={obj} setNotification={setNotification} />, null, true),
                    () => openPresentationForm(obj)
                )}
                defaultOrder='desc'
                defaultOrderBy={'created'}
                filters={filters}
                params={params}
                addParam={addParam}
                updateParams={updateParams}
                tutorial={utils.buildTutorial}
                allowSelect={!!utils.enableSelect}
                allowSearch={!!utils.enableSearch}
                addNew={() => openPresentationForm(null)}
            />

            {
                window &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{window}</Window>
            }

            <Snackbar
                open={notification != undefined}
                autoHideDuration={6000}
                onClose={() => setNotification(undefined)}
            >
                <Alert onClose={() => setNotification(undefined)} severity={notification && notification.status} sx={{ width: '100%' }}>
                    {notification && notification.text}
                </Alert>
            </Snackbar>
        </Grid>
    );
}

export default PresentationList;