import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Button
} from '@mui/material';

import {
    Event as EventIcon,
    Computer as ComputerIcon,
    Chat as ChatIcon
} from '@mui/icons-material';

import { 
    is_agent,
    canWriteSensitiveData
} from "../../../../../Services/permissions";

import { 
    openChaveLusaAdmin, 
    requestWithBody,
    handleRequestError
} from "../../../../utils";

import Alert from "../../../Chavelusa/Alert";
import PropertyCardHorizontal from "../../../Realstate/Property/CardHorizontal";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import {QUALIFICATIONS} from '../../../../Pages/CRM/ClientPage/Feed';

const ContactFormWindow = ({ form, clientAgent, updateElement }) => {

    console.log("COMPONENT ContactFormWindow", form);

    // Operations
    const [alert, setAlert] = useState(undefined);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const changeState = (processed) => {
        setLoadingRequest(true);
        console.log("CHANGING FORM STATE TO processed", processed);

        requestWithBody(
            'PATCH',
            process.env.REACT_APP_API_URL + '/crm/forms/' + form.id + '/?client=' + clientAgent.client.id,
            {processed: processed}
        ).then(response => {
            updateElement(response);
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                "Error editing client form state!",
            ).then(() => setAlert({error: true}))
        ).finally(() => {
            setLoadingRequest(false);
        });
    }

    const generateClassification = () => {
        setLoadingRequest(true);
        console.log("generateClassification");

        requestWithBody(
            'POST',
            process.env.REACT_APP_API_URL + '/crm/forms/' + form.id + '/generateClassification/?clientAgent=' + clientAgent.id,
            null
        ).then(response => {
            console.log("Generated classification from form", response);
            updateElement(response, QUALIFICATIONS, "A qualificação foi gerada com sucesso!", true);
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                "Error generating classification based on form!",
            ).then(() => setAlert({error: true}))
        ).finally(() => {
            setLoadingRequest(false);
        });
    }

    // Static variables
    const ALERT_PROCESS = {
        title: 'Tem a certeza?',
        text: 'Ao marcar como processado, deixará de ver o alerta para este cliente.',
        action: () => changeState(true)
    };
    const ALERT_UNPROCESS = {
        title: 'Tem a certeza?',
        text: 'Ao reverter o processamento, passará a ver o alerta para este cliente.',
        action: () => changeState(false)
    };

    return (
        <Grid sx={{width:'100%'}}>
            <Typography variant="h6" mb={0}>Formulário de contacto</Typography>
            <Typography variant="caption" mb={0}>
                Última alteração a {new Date(form.last_changed).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
            </Typography>

            { /* State */}
            <Grid xs={12}>
                {
                    !form.processed 
                    ?
                    <Chip
                        label="Formulário de contacto pendente"
                        icon={<ChatIcon fontSize="small" />}
                        color="warning"
                        sx={{ my: 3 }}
                    />
                    :
                    <Chip
                        label="Formulário de contacto processado"
                        icon={<ChatIcon fontSize="small" />}
                        color="primary"
                        variant="outlined"
                        sx={{ my: 3 }}
                    />
                }
            </Grid>

            { /* Operations */}
            {
                !clientAgent.archived && (is_agent || canWriteSensitiveData) &&
                <Grid mb={3} xs={12} container direction='row'>
                    {
                        !loadingRequest
                        ? 
                        (
                            !form.processed
                            ?
                            <Button 
                                color="primary" 
                                variant="contained"
                                onClick={() => setAlert(ALERT_PROCESS)}
                                sx={{mr: 2, mt: 1}}
                            >
                                Marcar como processado
                            </Button>
                            :
                            <Button 
                                color="primary" 
                                variant="outlined"
                                onClick={() => setAlert(ALERT_UNPROCESS)}
                                sx={{mr: 2, mt: 1}}
                            >
                                Marcar como não processado
                            </Button>
                        )
                        :
                        <LoadingButton
                            loading
                            color="primary" 
                            variant="contained" 
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            sx={{mr: 2, mt: 1}}
                        >
                            {!form.processed ? "Marcar como processado" : "Marcar como não processado"}
                        </LoadingButton>
                    }
                    {
                        !loadingRequest
                        ? 
                        <Button 
                            color="primary" 
                            variant="contained"
                            onClick={generateClassification}
                            sx={{mr: 2, mt: 1}}
                        >
                            Gerar qualificação
                        </Button>
                        :
                        <LoadingButton
                            loading
                            color="primary" 
                            variant="contained" 
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            sx={{mr: 2, mt: 1}}
                        >
                            Gerar qualificação
                        </LoadingButton>
                    }
                </Grid>
            }

            { /* Details */}
            <Grid my={3}>
                <Typography>
                    <Tooltip title="Submetido a">
                        <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {new Date(form.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </Typography>
                <Typography>
                    <Tooltip title="Submetido através de">
                        <ComputerIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {form.app}
                </Typography>
            </Grid>

            { /* Property */}
            <Grid my={3}>
                <Typography variant="h6">Propriedade</Typography>
                <PropertyCardHorizontal 
                    property={form.property} 
                    click={() => window.open('/propriedades/' + form.property.id, '_blank')}
                />
            </Grid>

            { /* Message */}
            <Grid my={3}>
                <Typography variant="h6">Mensagem</Typography>
                <Typography>{form.message}</Typography>
            </Grid>

            { /* Operations confirmation box */}
            {
                alert && 
                <Alert 
                    error={alert.error}
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default ContactFormWindow;