import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    TextField,
    Tooltip,
    IconButton,
    CircularProgress
} from '@mui/material';

import {
    Send as SendIcon
} from '@mui/icons-material';


import {
    colors,
    DateHelper,
    requestWithBody,
    handleRequestError,
    requestGET
} from "../../../../utils";

import { USER_ID } from '../../../../../Services/permissions';

/** 
 * Component to render property reviews chat
 * 
 * @Param reviews {Array} List of property review beans sorted asceding (from oldest to latest)
*/
const PropertyReviewWindow = ({ propertyId, setNotify, reloadPending }) => {

    // Load reviews from server
    const [reviews, setReviews] = useState(undefined);
    useEffect(() => {
        // Load property reviews from API
        requestGET(process.env.REACT_APP_API_URL + '/realstate/reviews/?property=' + propertyId)
            .then(response => {
                console.log("GOT reviews", response);
                setReviews(response);
                reloadPending();
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting reviews",
                )
            );
    }, []);

    // Utils
    const scrollToBottom = () => {
        if (!document.querySelector('#chat-message-last')) return;
        document.querySelector('#chat-message-last').scrollIntoView({ behavior: 'smooth' });
    }

    // Last message (most recent one)
    const [lastMessage, setLastMessage] = useState(undefined);
    useEffect(() => {
        if (!reviews || reviews.length === 0) {
            setLastMessage(undefined);
        } else {
            setLastMessage(reviews[reviews.length - 1]);
            scrollToBottom();
        }
    }, [reviews]);

    // Write new messages
    const [newMessage, setNewMessage] = useState("");
    const [sendingMessage, setSendingMessage] = useState(false);
    const sendMessage = () => {
        if (!newMessage) return;
        setSendingMessage(true);

        requestWithBody("POST", process.env.REACT_APP_API_URL + '/realstate/reviews/', {
            property: propertyId,
            message: newMessage
        }).then(response => {
            console.log("Message POST success", response);
            setNewMessage("");
            setNotify({ text: "Mensagem enviada com sucesso." });
            setLastMessage(response);
            setReviews(old => [...old, response]);
            scrollToBottom();
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error POSTing message",
            ).then(e => setNotify({ text: "Ocorreu um erro, tente novamente", error: true }))
        }).finally(() => {
            setSendingMessage(false);
        });
    }

    // Template
    return (
        <Grid sx={{ width: '100%', height: '100%', flexWrap: 'nowrap', maxHeight: '95vh' }} container direction="column">
            <Typography variant="h6" mb={0}>Conversa da propriedade</Typography>
            {
                reviews === undefined
                    ?
                    <CircularProgress sx={{ my: 'auto', mx: 'auto' }} />
                    :
                    <>
                        <Typography variant="caption" mb={0}>
                            {
                                !lastMessage
                                    ? "Ainda não foram enviadas mensagens"
                                    : `Última mensagem a ${new Date(lastMessage.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`
                            }
                        </Typography>

                        { /* Past reviews */}
                        <Grid xs={12} sx={{ 'flex-grow': 1, overflowY: 'scroll', flexWrap: 'nowrap' }} container direction='column'>
                            {
                                reviews.map(
                                    (r, i) => {
                                        let showUser = false, showTime = false, showDate = false;
                                        // On several messages from the same user....
                                        // ... only show user name on first message
                                        if (i === 0 || reviews[i - 1].user != r.user)
                                            showUser = true;
                                        // ... only show timestamp ...
                                        if (
                                            i + 1 === reviews.length // last message must have it
                                            || reviews[i + 1].user !== r.user // last message for user must have it
                                            || !DateHelper.datesAreOnSameDay(new Date(r.moment), new Date(reviews[i + 1].moment)) // last message from user on each day must have it
                                            || reviews[i + 1].user === r.user && DateHelper.diff_minutes(new Date(reviews[i + 1].moment), new Date(r.moment)) > 5 // if next message is for same user, but was written more than 5 minutes after
                                            || reviews[i].status_change || i > 0 && reviews[i - 1].status_change // curent or previous message has status change
                                        ) {
                                            showTime = true;
                                        }
                                        // ... only show date on first message for that day
                                        if (i === 0 || !DateHelper.datesAreOnSameDay(new Date(r.moment), new Date(reviews[i - 1].moment)))
                                            showDate = true;


                                        return (
                                            <>
                                                {
                                                    showDate &&
                                                    <Grid
                                                        sx={{
                                                            textAlign: 'center',
                                                            borderTop: '1px solid',
                                                            borderColor: colors.gray,
                                                            my: 2,
                                                            pt: 3
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                        >{new Date(r.moment).toLocaleDateString('pt-PT', DateHelper.format_date)}</Typography>
                                                    </Grid>
                                                }
                                                <Grid
                                                    container
                                                    direction="column"
                                                    ml={r.user === USER_ID ? 'auto' : 0}
                                                    sx={{
                                                        maxWidth: '45%',
                                                        width: 'auto',
                                                        px: 2,
                                                        py: 1,
                                                        mt: .5,
                                                        mb: showTime ? 1.5 : 0,
                                                        borderRadius: '8px',
                                                        bgcolor: r.user === USER_ID ? colors.primary : colors.gray,
                                                        color: r.user === USER_ID ? colors.white : colors.black
                                                    }}
                                                    id={i + 1 === reviews.length ? 'chat-message-last' : 'chat-message-' + i}
                                                >
                                                    {
                                                        r.user !== USER_ID && showUser &&
                                                        <Typography variant='caption'>{r.user_display.user.name}</Typography>
                                                    }
                                                    <Typography>{r.message}</Typography>
                                                    {
                                                        showTime &&
                                                        <Typography
                                                            variant='caption'
                                                            sx={{
                                                                textAlign: r.user !== USER_ID ? 'left' : 'right'
                                                            }}
                                                        >{new Date(r.moment).toLocaleTimeString('pt-PT', DateHelper.format_hour)}</Typography>
                                                    }
                                                </Grid>
                                                {
                                                    r.status_change &&
                                                    <Grid
                                                        sx={{
                                                            textAlign: 'center',
                                                            my: 2
                                                        }}
                                                    >
                                                        <Typography variant='caption'>
                                                            {new Date(r.moment).toLocaleTimeString('pt-PT', DateHelper.format_hour)} |
                                                            Alteração de estado para {r.status_change_display.toLowerCase()} por {r.user_display.user.name}
                                                        </Typography>
                                                    </Grid>
                                                }
                                            </>
                                        )
                                    }
                                )
                            }
                        </Grid>

                        { /* Send new review */}
                        <Grid xs={12} style={{ 'flex-basis': 0 }}>
                            <Grid container>
                                <TextField
                                    variant="standard"
                                    label="A sua mensagem..."
                                    sx={{ flexGrow: 1 }}
                                    value={newMessage}
                                    onChange={(event) => setNewMessage(event.target.value)}
                                    disabled={sendingMessage}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            sendMessage();
                                        }
                                    }}
                                />
                                <Tooltip title="Enviar mensagem">
                                    <IconButton
                                        color={"primary"}
                                        variant={newMessage ? "contained" : "outlined"}
                                        onClick={sendMessage}
                                        disabled={sendingMessage || !newMessage}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
            }
        </Grid>
    );
}

export default PropertyReviewWindow;