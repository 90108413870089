import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    Tooltip
} from '@mui/material';

import {
    Email as EmailIcon,
    Event as EventIcon,
    Person as PersonIcon,
    AttachFile as AttachFileIcon
} from '@mui/icons-material';

import {
    DateHelper
} from '../../../../utils';

import Message from "../Message";

const MessageBanner = ({mr, clientAgent, selected, setWindow, openWindow}) => {

    useEffect(() => {
        openWindow && setWindow(getWindow());
    }, []);

    const getWindow = () => {
        return (<Message 
            subject={mr.message.subject}
            sender={mr.message.sender}
            recipients={[{contact: clientAgent.client.email, name: clientAgent.client.name}]}
            sent={mr.sent && new Date(mr.sent).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
            model={"crm.ClientAgent"}
            reference={clientAgent.id}
            id={mr.id}
        />);
    }

    return (
        <Grid
            container
            direction="row"
            className={!selected ? "elementSelectable" : "elementSelectable selected"}
            my={4}
            onClick={() => setWindow(getWindow())}
        >
            <Grid
                xs={2}
                m={0}
                container
            >
                <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                    <EmailIcon 
                        color={
                            mr.status === 'Q' ? "primary"
                            : mr.status === 'S' ? "secondary"
                            : "error"
                        } 
                        fontSize="inherit"
                    /> 
                </Typography>
            </Grid>
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>{mr.message.subject}</Typography>
                <Grid container>
                    {
                        mr.sent && 
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Enviado a">
                                <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {new Date(mr.sent).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                        </Typography>
                    }
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Estado">
                            <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {mr.status_message}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Enviado por">
                            <PersonIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {mr.message.sender.name}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MessageBanner;