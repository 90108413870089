import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import FormGroup from "../../../../../Chavelusa/FormGroup";
import PropertySheetElementsGlobalForm from "../GlobalForm";

import {buildFilterSelect, buildFilterText} from "../../../../../Chavelusa/FormGroup/utils";
import { colors } from "../../../../../../utils";
import {TYPE_IMAGE} from '../../enum';

/**
 * IMAGE ELEMENT COMPONENT 
 * 
 * Element structure:
 * COMMON (refer to GlobalForm for complete structure)
 * - type: str          IMAGE
 * SPECIFIC
 * - url: str           Image static url
 * 
 * - position: str      (Optional, default center) Image background relative position
 * - borderRadius: int  (Optional, default 0) Number of pixels
 * - size: str          (Optional, default cover) Image background-size
 */

const formFields = [
    buildFilterSelect(
        'Alinhamento vertical',
        'select',
        'position',
        [
            {label: 'Topo', id: 'top'},
            {label: '10%', id: 'center 10%'},
            {label: '20%', id: 'center 20%'},
            {label: '30%', id: 'center 30%'},
            {label: '40%', id: 'center 40%'},
            {label: 'Centro', id: 'center'},
            {label: '60%', id: 'center 60%'},
            {label: '70%', id: 'center 70%'},
            {label: '80%', id: 'center 80%'},
            {label: '90%', id: 'center 90%'},
            {label: 'Fundo', id: 'bottom'}
        ],
        null, 
        true
    ),
    buildFilterSelect(
        'Distribuição',
        'select',
        'size',
        [
            {label: 'Preencher', id: 'cover'},
            {label: 'Altura 100%, Comprimento variável', id: 'auto 100%'},
            {label: 'Altura variável, Comprimento 100%', id: '100% auto'},
            {label: 'Altura variável, Comprimento variável', id: 'auto auto'},
            {label: 'Altura 100%, Comprimento 100%', id: '100% 100%'},
        ],
        null, 
        true
    ),
    {
        ...buildFilterText(
            'Bordas redondeadas',
            'borderRadius',
            true,
            (val) => {return val>=0;},
            undefined,
            'number'
        ),
        info: '0 coloca bordas retangulares. Quando maior o número, maior a circunferência.',
        acceptsZero: true
    }
];

const PropertySheetElementImage = ({ element, setElement, propertyImages, totalCols }) => {

    
    const edit = !!setElement;

    console.log("PropertySheetElementImage", element, edit);

    const [localElement, setLocalElement] = useState({});

    const defaultImgProperties = {
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }

    useEffect(() => {
        let localCandidate = element ? {...element} : {};
        // Default properties
        if (!('height' in localCandidate)) localCandidate['height'] = 25;
        if (!('position' in localCandidate)) localCandidate['position'] = 'center';
        if (!('type' in localCandidate)) localCandidate['type'] = TYPE_IMAGE;
        if (!('borderRadius' in localCandidate)) localCandidate['borderRadius'] = 0;
        if (!('size' in localCandidate)) localCandidate['size'] = 'cover';
        setLocalElement(localCandidate);
    }, [element]);

    useEffect(() => {
        console.log("PropertySheetElementImage localElement", localElement);
    }, [localElement]);

    return (
        <Grid
            xs={12}
            sx={edit ? {} : {
                ...defaultImgProperties,
                backgroundImage: localElement.url ? 'url(' + process.env.REACT_APP_STATIC + localElement.url + ')' : '',
                backgroundSize: localElement.size ? localElement.size : 'cover',
                height: '100%',
                backgroundPosition: localElement.position ? localElement.position : 'center',
                borderRadius: localElement.borderRadius ? localElement.borderRadius+'px' : 0,
            }}
        >
            {
                edit &&
                <>
                    {
                        localElement.url &&
                        <>
                            <Typography variant='subtitle'>
                                Pré-visualização
                            </Typography>
                            <Grid xs={12} container>
                                <Grid
                                    xs={localElement.cols ? localElement.cols : 12}
                                    sx={{
                                        ...defaultImgProperties,
                                        backgroundImage: localElement.url ? 'url(' + process.env.REACT_APP_STATIC + localElement.url + ')' : '',
                                        backgroundSize: localElement.size ? localElement.size : 'cover',
                                        height: localElement.height ? localElement.height + 'vh' : '',
                                        backgroundPosition: localElement.position ? localElement.position : 'center',
                                        mx: 'auto',
                                        borderRadius: localElement.borderRadius ? localElement.borderRadius+'px' : 0,
                                        border: '1px dashed ' + colors.primary
                                    }}
                                />
                            </Grid>
                        </>
                    }
                    <Grid mt={3}>
                        <Typography variant='subtitle'>
                            Editar propriedades da imagem
                        </Typography>
                        <Grid container direction="row" wrap="wrap" my={2}>
                            {
                                [
                                    {image_url: '/static/assets/logo.png', setSize: 'auto 100%'},
                                    {image_url: '/static/assets/logow.png', setSize: 'auto 100%'},
                                    ...propertyImages
                                ].map(
                                    pi =>
                                        <Grid
                                            xs={2}
                                            sx={{
                                                ...defaultImgProperties,
                                                height: '10vh',
                                                backgroundImage: 'url(' + process.env.REACT_APP_STATIC + pi.image_url + ')',
                                                position: 'relative',
                                                cursor: 'pointer',
                                                backgroundSize: pi.setSize ? pi.setSize : 'cover',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(0,0,0,0.3)'
                                                }
                                            }}
                                            onClick={
                                                () => setLocalElement(old => {
                                                    return { ...old, url: pi.image_url, size: pi.setSize ? pi.setSize : old.size }
                                                })
                                            }
                                        >
                                            {
                                                localElement.url === pi.image_url &&
                                                <Grid
                                                    container
                                                    sx={{
                                                        backgroundColor: 'rgb(0,0,0,0.3)',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <CheckCircleIcon sx={{ margin: 'auto', color: colors.primary }} />
                                                </Grid>
                                            }
                                        </Grid>
                                )
                            }
                        </Grid>
                        {
                            localElement && Object.keys(localElement).length>0 && 
                            <>
                                <FormGroup
                                    fields={formFields}
                                    updateParams={(to_clean, id, val) => {
                                        setLocalElement({ ...localElement, [id]: val });
                                    }}
                                    initialVals={localElement}
                                    sx={{ width: '100%' }}
                                />
                                <PropertySheetElementsGlobalForm
                                    element={localElement}
                                    setElement={setLocalElement}
                                    totalCols={totalCols}
                                    elementOriginal={element}
                                />
                            </>
                        }
                        {
                            localElement && localElement.url &&
                            <Button color="primary" variant="contained" sx={{ width: '100%', mt: 1 }} onClick={() => setElement(localElement)}>
                                {!element ? "Adicionar imagem": "Editar imagem"}
                            </Button>
                        }
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default PropertySheetElementImage;