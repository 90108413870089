import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import { requestWithBody, handleRequestError } from "../../../../utils";

import FormGroup from "../../../Chavelusa/FormGroup";
import { buildFilterText } from "../../../Chavelusa/FormGroup/utils";

const PresentationReproduce = ({ presentation, setNotification }) => {

    const [loading, setLoading] = useState(false);
    const[error, setError] = useState(undefined);

    const validate = (code) => {
        if (!code) setNotification({ status: 'error', text: 'Código inválido' });
        setLoading(true);

        requestWithBody("PATCH", process.env.REACT_APP_API_URL + '/realstate/presentations/' + presentation.id + '/', {
            code: code
        }).then(response => {
            if (response.validated)
                setNotification({ status: 'success', text: 'Código validado com sucesso!' })
            else 
                setNotification({ status: 'error', text: 'Código inválido' })
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error validating presentation",
            ).then(e => {
                setError(e);
                setNotification({ status: 'error', text: 'Código inválido' });
            });
        }).finally(() => {
            setLoading(false);
        });

    }

    return (
        <Grid>
            <Typography variant="h6">Reproduzir apresentação</Typography>
            <Typography>Pode optar por uma das seguintes opções:</Typography>
            <Grid my={3} container direction="column">
                <Typography>1. Para reproduzir neste dispositivo, clique no botão abaixo:</Typography>
                <Button color="primary" variant="outlined" href={'/tv/' + presentation.id} sx={{ mx: 'auto' }} target="_blank">Apresentar</Button>
            </Grid>
            <Grid my={3}>
                <Typography>2. Para reproduzir noutro dispositivo:</Typography>
                <Typography>2.1. Aceda ao seguinte URL no dispositivo onde deseja apresentar:</Typography>
                <Typography variant="subtitle1" color="primary" textAlign="center">{window.location.hostname}/tv/{presentation.id}</Typography>
                <Typography>2.2. Insira o código que aparece no ecrã do dispositivo onde vai apresentar abaixo, para o autenticar:</Typography>
                <FormGroup
                    fields={[
                        buildFilterText(
                            'Código de validação',
                            'code',
                            false,
                            (val) => {
                                return val >= 100000 && val <= 999999;
                            },
                            undefined,
                            'number'
                        )
                    ]}
                    onSubmit={(vals) => validate(vals.code)}
                    disabled={loading}
                    error={error}
                    sx={{width: '100%'}}
                    submitText={'Autenticar'}
                />
                <Typography sx={{mt: 1}}>2.3. Após a validação, aguarde alguns segundos e a apresentação iniciar-se-á automaticamente no seu dispositivo.</Typography>
            </Grid>
        </Grid>
    );
}

export default PresentationReproduce;