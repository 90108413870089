export const tutorial = [
    {
        element: '.property-header',
        intro: 'Esta é a parte superior da página da propriedade...'
    },
    {
        element: '.property-header .tags',
        intro: 'Aqui pode acompanhar o seu estado e os principais alertas.'
    },
    {
        element: '.property-header .operations',
        intro: 'Pode realizar operações sobre a propriedade, como editar os seus atributos, alterar o seu estado ou consultar as mensagens.'
    },
    {
        element: '.property-header .property-chat-button',
        intro: 'Destaque para a secção de mensagens, uma conversa onde os vários elementos da agência podem discutir melhorias e alterações a fazer à propriedade.'
    },
    {
        element: '.property-header .form-notes',
        intro: 'Por fim temos o campo para fazer anotações. O que escrever aqui será visível na listagem das propriedades para o ajudar a ter contexto.'
    },
    {
        element: '.instance-feed',
        intro: 'No feed é onde poderá ver toda a informação relacionada com a propriedade.'
    },
    {
        element: '.instance-feed .instance-feed-tabs',
        intro: 'Utilize os separadores para consultar as várias secções.'
    },
    {
        element: '.instance-feed .instance-feed-operations',
        intro: 'Quando o separador selecionado suportar, verá na secção superior os filtros e/ou as operações disponíveis.'
    },
    {
        element: '.instance-feed .instance-feed-cards',
        intro: 'Por fim, aqui verá os cartões correspondentes ao separador selecionado. Os que escurecem ao passar sobre eles são clicáveis, podendo consultar mais detalhes ao clicar sobre eles.'
    },
    {
        element: '.instance-sidebar',
        intro: 'Nesta barra lateral serão apresentados alertas relacionados com a propriedade.'
    },
];

export const compareClientAgentForProperty = (a, b) => {
    // 1st next visits
    if (a.visits_next && b.visits_next)
        return new Date(a.visits_next) < new Date(b.visits_next);
    if (a.visits_next)
        return false;
    if (b.visits_next)
        return true;
    // 2nd forms pending on property
    if (a.forms_pending && b.forms_pending)
        return new Date(a.forms_last) < new Date(b.forms_last);
    if (a.forms_pending)
        return false;
    if (b.forms_pending)
        return true;
    // 3rd classified on property
    if (a.classifications && b.classifications)
        return new Date(a.classification_last) < new Date(b.classification_last);
    if (a.classifications)
        return false
    if (b.classifications)
        return true;
    // 4th most recent of last classification, last visit, last form (and finally client agent creation date if non of the previous)
    return new Date(a.classification_last || a.visits_last || a.forms_last || a.clientAgent.created) < new Date(b.classification_last || b.visits_last || b.forms_last || b.clientAgent.created)
}