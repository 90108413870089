import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import FilesUploader from "../../../Chavelusa/FilesUploader";
import PropertyDocumentBanner from "../Banner";

const PropertyDocumentForm = ({ doc, property, updateElement, close, notify }) => {

    const [documents, setDocuments] = useState([]);

    const docUploaded = (instance) => {
        setDocuments(old => [instance, ...old]);
        updateElement(instance);
        notify("Documentos carregados com sucesso!");
    }

    return (
        <Grid sx={{ width: '100%' }}>
            <Typography variant="h6" mb={3}>
                {doc ? 'Editar documento' : 'Carregar documentos'}
            </Typography>

            <FilesUploader
                id={'document'}
                url={process.env.REACT_APP_API_URL + '/realstate/documents/'}
                filesAttr={'document'}

                initialFiles={doc ? [{ label: doc.name, url: doc.document }] : []}
                maxFiles={99}
                update={docUploaded}
                allowDelete={false}

                method={"POST"}
                initialBody={{ 'property': property.id }}
            />

            {
                documents.length>0 &&
                <Grid>
                    {
                        documents.map(
                            document =>
                            <PropertyDocumentBanner
                                doc={document}
                                editionMode={true}
                                updateElement={updateElement}
                                setNotify={notify}
                            />
                        )
                    }
                </Grid>
            }

            {
                documents.length>0 && 
                <Button
                    color="primary"
                    variant={documents ? "outlined" : "contained"}
                    sx={{ width: '100%', my: 3 }}
                    onClick={() => {
                        close();
                    }}
                >
                    Concluir
                </Button>
            }
        </Grid>
    );
}

export default PropertyDocumentForm;