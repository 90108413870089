import React, { useState, useEffect, useMemo } from "react";

import {
    requestFile,
    requestWithBody,
    handleRequestError
} from '../../../utils';

import {
    Grid,
    Typography,
    Chip,
    Link,
    FormHelperText
} from '@mui/material';

import { useDropzone } from 'react-dropzone';

import {
    baseStyle,
    focusedStyle,
    acceptStyle,
    rejectStyle
} from './styles';

import { default as ChaveLusaAlert } from "../Alert";

/**
 * Component to handle files library management (upload, delete)
 * 
 * Using https://react-dropzone.js.org/
 * 
 * @param {String} id Name of the field file to upload
 * @param {String} url To PATCH the files
 * @param {String} filesAttr Attribute of the files in the request body to update them in the server
 * 
 * OPTIONAL
 * @param {Array} initialFiles List of objects {label: <String>, url: <String>}
 * @param {Integer} maxFiles Max number of files (0 means unlimited and is default)
 * @param {Integer} maxFiles Max size for each file (in bytes, default infinity)
 * @param {String, String[]} accept Type of file(s) acceptable
 * @param {Method} update Called when PATCH request is done. Parameters: (<PATCH JSON response>, <indexOfFileUploaded>) 
 * @param {Boolean} allowDelete Enable file delete or not
 * @param {String} (Optional) method. If not defined, PATCH will be performed 
 * @param {Object} (Optional) initialBody Additional elements to send with request when uploading file
 * @param {Boolean} (Optional) hidePreview If true, files uploaded are not shown
 */
const FilesUploader = ({ id, url, filesAttr, initialFiles, maxFiles=0, maxSize=Infinity, accept, update, allowDelete, method, initialBody, hidePreview }) => {

    // Internal state
    const [files, setFiles] = useState([]);
    const [invalidFiles, setInvalidFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    // > Initialize files with initialFiles if provided
    useEffect(() => {
        initialFiles && initialFiles.length && setFiles(initialFiles);
    }, [initialFiles]);

    // > Handle file upload
    const filesUpload = (files) => {
        if (!files || !files.length) return;

        setLoading(true);
        setInvalidFiles([]);
        console.log("FilesUploader files upload", files);
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            console.log("FilesUploader going to upload file", file);
            const body = new FormData();
            body.append(id, file);
            if (initialBody) {
                for (let key in initialBody) {
                    body.append(key, initialBody[key]);
                }
            }
            console.log("FilesUploader going to upload", body);
            console.log("FilesUploader going to upload entries", body.entries());
            
            requestFile(method ? method : "PATCH", url, body).then(response => {
                console.log("FilesUploader UPLOAD success", response);
                setFiles(old => {
                    var namesplit = response[filesAttr].split("/");
                    return [...old, {label: namesplit[namesplit.length-1], url: response[filesAttr]}];
                });
                update && update(response, i);
            }).catch(error => {
                handleRequestError(
                    error,
                    [],
                    "Error uploading file",
                ).then((e) => {
                    setInvalidFiles(old => [...old, {file: file, errors: [{
                        message: 'detail' in e ? e['detail'] : 'Ocorreu um erro no carregamento, por favor tente novamente.'
                    }]}]);
                });
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    // > Handle file delete
    const deleteFile = (file) => {
        if (!allowDelete) return;
        setLoading(true);
        setInvalidFiles([]);

        requestWithBody("PATCH", url, {
            [filesAttr]: (files.length-1>0 ? [] : null)
        }).then(response => {
            console.log("FilesUploader DELETE success", response);
            setFiles(old => old.filter(f => f.url !== file.url));
            update && update(response, 0);
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error deleting file",
            );
            setInvalidFiles(old => [...old, {file: file, errors: [{message: 'Ocorreu um erro ao eliminar, por favor tente novamente.'}]}])
        }).finally(() => {
            setLoading(false);
        });
    }

    // > Handle invalid files 
    const filesInvalid = (invalids) => {
        setInvalidFiles(invalids);
        setLoading(false);
    }
    
    // Dropzone initialization
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        // Properties
        accept: accept,
        disabled: loading || maxFiles===files.length,
        maxFiles: (maxFiles - files.length),
        maxSize: maxSize,
        // Handlers
        onDrop: filesUpload,
        onDropRejected: filesInvalid,
    });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    // Alert before closing when changed
    const [alert, setAlert] = useState(undefined);
    const ALERT_CONFIRM_DELETE = {
        title: 'Esta operação é irreversível!',
        text: 'Tem a certeza que pretende eliminar este ficheiro?',
        action: undefined
    };


    return (
        <Grid>
            <Grid container>
                {
                    !!hidePreview && files.map(file =>
                        <Link href={file.url} key={file.url} target={"_blank"}>
                            <Chip
                                label={file.label}
                                variant="outlined"
                                color="primary"
                                onDelete={!allowDelete ? undefined : (e) => {
                                    e.preventDefault();
                                    ALERT_CONFIRM_DELETE.action = () => deleteFile(file);
                                    setAlert(ALERT_CONFIRM_DELETE);
                                }}
                            />
                        </Link>
                    )
                }
            </Grid>

            <Grid container my={3}>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {
                        loading ? <p>A carregar...</p>
                        : maxFiles===files.length ? 
                            (
                                allowDelete 
                                ? <p>O número limite de ficheiros foi atingido, elimine algum para habilitar o carregamento.</p>
                                : <p>O número limite de ficheiros foi atingido.</p>
                            )
                        : <p>Arraste ficheiros ou clique para procurar</p>
                    }
                </div>
            </Grid>
            {
                invalidFiles && invalidFiles.length>0 && 
                <Grid>
                    <FormHelperText error={true}>
                        Os seguintes ficheiros são inválidos:
                        {
                            invalidFiles.map((fe, i) => 
                                <span key={i}><br/>{fe.file.name}: {fe.errors.map(e => e.message).join(", ")}</span>
                            )
                        }
                    </FormHelperText>
                </Grid>
            }
            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default FilesUploader;