import React, { useState, useEffect } from "react";

import {
    is_agent,
    canWriteSensitiveData
} from '../../../../Services/permissions';

import {
    openChaveLusaAdmin,
    requestGET,
    handleRequestError
} from '../../../utils';

import {
    Grid,
    Box,
    Tabs,
    Tab,
    CircularProgress,
    Typography,
    Button
} from '@mui/material';

import Stepper from "../../../Components/Chavelusa/Stepper";
import ContactFormBanner from '../../../Components/CRM/ContactForm/Banner';
import ClientClassificationBanner from '../../../Components/CRM/ClientClassification/Banner';
import MessageBanner from '../../../Components/Backoffice/Message/Banner';
import PropertyCardHorizontal from '../../../Components/Realstate/Property/CardHorizontal';
import ClientClassificationFormInitial from "../../../Components/CRM/ClientClassification/Forms/FormInitial";
import ClientClassificationFormLocations from "../../../Components/CRM/ClientClassification/Forms/FormLocations";
import ClientClassificationFormFeatures from "../../../Components/CRM/ClientClassification/Forms/FormFeatures";
import TemplatesForm from '../../../Components/Backoffice/Templates/Form';

export const QUALIFICATIONS = "Qualificações";
export const FEED = "Feed";
export const FORMS = "Formulários";
export const PROPERTIES = "Propriedades";
export const MESSAGES = "Mensagens";

const TABS = {
    [FEED]: [FORMS, QUALIFICATIONS, PROPERTIES, MESSAGES],
    [FORMS]: [FORMS],
    [QUALIFICATIONS]: [QUALIFICATIONS],
    [PROPERTIES]: [PROPERTIES],
    [MESSAGES]: [MESSAGES]
}

const jsWindow = window;

const Feed = ({ clientAgent, window, windowChanged, windowChangedState, setWindow, windowPipePop, setNotify, onUpdate }) => {

    // On load, search parameters for ops related to feed
    useEffect(() => {
        (new URLSearchParams(jsWindow.location.search).get("op") === 'classify') && classify();
    }, []);

    // Window monitoring (to know which feed element is selected)
    const [windowId, setWindowId] = useState(null);
    useEffect(() => {
        // When window is null, windowId should be null as well
        if (window === null) {
            console.log("window changed null");
            setWindowId(null);
        }
    }, [window]);

    const openWindow = (w, id = null, transition = false) => {
        if (transition) {
            setWindow(null);
            setTimeout(() => {
                setWindow(w);
                setWindowId(id);
            }, 500);
        } else {
            setWindow(w);
            setWindowId(id);
        }
    }

    // Tabs set up
    const [tab, setTab] = useState(FEED);
    useEffect(() => {
        console.log("NEW TAB", tab);
        // When tab changes, close window
        setWindow(null);
    }, [tab]);

    // On load, populate user feed
    const loadingMonitor = [true, true, true, true];
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_URL + '/crm/';
    const [feed, setFeed] = useState([]);
    useEffect(() => {
        if (!clientAgent) return false;
        setFeed([]);

        // Get forms
        requestGET(url + 'forms/?client=' + clientAgent.client.id)
            .then(response => {
                let forms = response.map(f => { return { ...f, feed: FORMS } });
                console.log("GOT FORMS", forms);
                setFeed(olde => [...olde, ...forms]);
                loadingMonitor[0] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting client forms",
                )
            );

        // Get classifications
        requestGET(url + 'classifications/?clientAgent=' + clientAgent.id)
            .then(response => {
                let qualifications = response.map(f => { return { ...f, feed: QUALIFICATIONS } });
                console.log("GOT QUALIFICATIONS", qualifications);
                setFeed(olde => [...olde, ...qualifications]);
                loadingMonitor[1] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting client qualifications",
                )
            );

        // Get properties
        requestGET(process.env.REACT_APP_API_PUBLIC_URL + '/realstate/properties/?client=' + clientAgent.client.id)
            .then(response => {
                let properties = response.map(f => { return { ...f, feed: PROPERTIES } });
                console.log("GOT PROPERTIES", properties);
                setFeed(olde => [...olde, ...properties]);
                loadingMonitor[2] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting client properties",
                )
            );

        getMessages();
    }, []);

    const getMessages = (update = false) => {
        // Get messages
        requestGET(process.env.REACT_APP_API_URL + '/backoffice/messages/?model=crm.ClientAgent&ref=' + clientAgent.id)
            .then(response => {
                let ms = response.map(m => { return { ...m, feed: MESSAGES } });
                console.log("GOT MESSAGES", ms);
                if (update) {
                    ms.forEach(mse => updateFeed(mse, MESSAGES));
                } else {
                    setFeed(olde => [...olde, ...ms]);
                    loadingMonitor[3] = false;
                    if (!loadingMonitor.some(l => l)) setLoading(false);
                }

                // If there are messages pending, repeat request in 30 seconds (inifinite loop until none pending)
                if (ms.some(m => m.status === 'Q')) {
                    setTimeout(() => getMessages(true), 30 * 1000);
                }
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting messages",
                )
            );
    }

    // When an instance is updated, update it in feed 
    const updateFeed = (instance, label, changeWindowToElement = false) => {
        console.log("updateFeed", label, changeWindowToElement, instance);
        // If changeWindowToElement set to true, change element selected
        changeWindowToElement && setWindowId(instance.id + '/' + label);

        // Update feed
        setFeed(oldfeed => {
            const olde = [...oldfeed];
            const indexToReplace = olde.findIndex(i => i.feed === label && i.id === instance.id);

            // If no index to replace, then it is new, just push to 
            if (indexToReplace === -1) {
                olde.push({ ...instance, feed: label });
                // Otherwise, update element at indexToReplace
            } else {
                olde.splice(indexToReplace, 1, { ...instance, feed: label });
            }
            return olde;
        });

        // Reload client agent from API 
        onUpdate();
    }

    // CLASSIFICATIONS
    const getClassificationDoneSteps = (classification) => {
        let done = [0];
        classification.locations.length && done.push(1);
        classification.features.length && done.push(2);
        return done;
    }
    const classify = (classification = undefined) => {
        let steps = [
            {
                label: 'Dados gerais', component: (
                    <ClientClassificationFormInitial
                        clientAgentId={clientAgent.id}
                        success={(entity) => {
                            updateFeed(entity, QUALIFICATIONS);
                            setNotify(!classification ? "A qualificação foi criada com sucesso" : "A qualificação foi editada com sucesso");
                            classify(entity);
                        }}
                        classification={classification}
                        formChanged={windowChanged}
                    />
                )
            },
            {
                label: 'Localizações', component: (
                    <ClientClassificationFormLocations
                        clientAgentId={clientAgent.id}
                        classification={classification}
                        success={(entity) => {
                            updateFeed(entity, QUALIFICATIONS);
                            setNotify("A qualificação foi editada com sucesso");
                            classify(entity);
                        }}
                        formChanged={windowChanged}
                    />
                )
            },
            {
                label: 'Características', component: (
                    <ClientClassificationFormFeatures
                        clientAgentId={clientAgent.id}
                        classification={classification}
                        success={(entity) => {
                            updateFeed(entity, QUALIFICATIONS);
                            setNotify("A qualificação foi editada com sucesso");
                            classify(entity);
                        }}
                        formChanged={windowChanged}
                    />
                )
            }
        ];

        openWindow(
            <Stepper
                title={"A qualificar cliente"}
                steps={steps}
                activeStepsInitial={!classification ? [0] : []}
                any={!!classification}
                completed={classification && getClassificationDoneSteps(classification)}
                goBack={(!classification || !windowPipePop()) ? null : (() => openWindow(windowPipePop(), classification ? classification.id + '/' + QUALIFICATIONS : null, true))}
                hasChanged={windowChangedState}
            />
            , classification ? classification.id + '/' + QUALIFICATIONS : null, true);
    }

    return (
        <Grid
            container
            mt={5}
            className="client-feed"
        >
            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            <Grid xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="client-feed-tabs">
                    <Tabs value={tab} onChange={(e, val) => setTab(val)} aria-label="basic tabs example">
                        {
                            // Only display tab FEED or other if any of the feed elements has attr feed that matches any of its includes attribute
                            Object.keys(TABS).filter(
                                t => t === FEED || TABS[t].some(v => feed.some(f => f.feed === v))
                            ).map(t => <Tab label={t} value={t} />)
                        }
                    </Tabs>
                </Box>
                <Grid>
                    <div className="client-feed-operations">
                        {
                            // OPERATIONS
                        }
                        {
                            (is_agent || canWriteSensitiveData) && !clientAgent.archived && !clientAgent.replaced_by && TABS[tab].some(v => v === FEED || v === QUALIFICATIONS) &&
                            <Button
                                sx={{ width: '100%', mt: 3 }}
                                onClick={() => classify()}
                                color="primary"
                                variant={clientAgent.classified ? "outlined" : "contained"}
                            >
                                Qualificar
                            </Button>
                        }
                        {
                            !clientAgent.archived && !clientAgent.replaced_by && TABS[tab].some(v => v === FEED || v === MESSAGES) &&
                            <Button
                                sx={{ width: '100%', mt: 3 }}
                                onClick={() => openWindow(<TemplatesForm
                                    recipients={{ [clientAgent.id]: 'crm.ClientAgent' }}
                                    success={(entities) => {
                                        entities.forEach(e => updateFeed(e, MESSAGES));
                                        openWindow(<MessageBanner
                                            mr={entities[0]}
                                            clientAgent={clientAgent}
                                            setWindow={(w) => openWindow(w, w ? entities[0].id + '/' + MESSAGES : null, true)}
                                            openWindow={true}
                                        />, entities[0].id + '/' + MESSAGES, false)
                                        setNotify("A mensagem foi enviada com sucesso");
                                    }}
                                />, null, true)}
                                color="primary"
                                variant={
                                    // TODO! Improveeeee
                                    "contained"
                                }
                            >
                                Enviar mensagem
                            </Button>
                        }
                    </div>
                    <div className="client-feed-cards">
                        {
                            // FEED CARDS
                        }
                        {
                            // Sort by last update (first)
                            feed.sort((a, b) => new Date(a.sent || a.last_changed || a.created || new Date(Date.UTC(96, 1, 2, 3, 4, 5))) < new Date(b.sent || b.last_changed || b.created || new Date(Date.UTC(96, 1, 2, 3, 4, 5)))).map(f => {
                                if (f.feed == FORMS && TABS[tab].indexOf(FORMS) >= 0)
                                    return (
                                        <ContactFormBanner
                                            form={f}
                                            clientAgent={clientAgent}
                                            setWindow={(w) => openWindow(w, w ? f.id + '/' + FORMS : null, true)}
                                            updateElement={(instance, tp, msg, changeWindowToElement) => {
                                                updateFeed(instance, tp ? tp : FORMS, changeWindowToElement);
                                                setNotify(msg ? msg : "O formulário de contacto foi atualizado com sucesso");
                                            }}
                                            selected={windowId && windowId === (f.id + '/' + FORMS)}
                                        />
                                    );
                                else if (f.feed == QUALIFICATIONS && TABS[tab].indexOf(QUALIFICATIONS) >= 0)
                                    return (
                                        <ClientClassificationBanner
                                            classification={f}
                                            clientAgent={clientAgent}
                                            setWindow={(w) => openWindow(w, w ? f.id + '/' + QUALIFICATIONS : null, true)}
                                            updateElement={(instance, message) => {
                                                updateFeed(instance, QUALIFICATIONS);
                                                setNotify(message ? message : "A qualificação foi atualizada com sucesso");
                                            }}
                                            openEdit={() => classify(f)}
                                            selected={windowId && windowId === (f.id + '/' + QUALIFICATIONS)}
                                            windowChanged={windowChanged}
                                        />
                                    );
                                else if (f.feed == PROPERTIES && TABS[tab].indexOf(PROPERTIES) >= 0)
                                    return (
                                        <PropertyCardHorizontal
                                            property={f}
                                            my={3}
                                            click={() => window.open('/propriedades/' + f.id, '_blank')}
                                        />
                                    );
                                else if (f.feed == MESSAGES && TABS[tab].indexOf(MESSAGES) >= 0)
                                    return (
                                        <MessageBanner
                                            mr={f}
                                            clientAgent={clientAgent}
                                            setWindow={(w) => openWindow(w, w ? f.id + '/' + MESSAGES : null, true)}
                                            selected={windowId && windowId === (f.id + '/' + MESSAGES)}
                                        />
                                    );
                            })
                        }
                        {
                            !loading && feed.length == 0 &&
                            <Typography py={3}>
                                O cliente não tem atividade registada.
                            </Typography>
                        }
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Feed;
