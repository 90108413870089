import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Button
} from '@mui/material';

import {
    Event as EventIcon,
    AttachFile as AttachFileIcon,
    InsertDriveFile as InsertDriveFileIcon
} from '@mui/icons-material';

import {
    DateHelper,
    requestGET,
    requestWithBody,
    handleRequestError
} from '../../../../utils';

import {
    buildFilterSelect
} from '../../../Chavelusa/FormGroup/utils';

import PropertyDocumentWindow from "../Window";
import FormGroup from "../../../Chavelusa/FormGroup";

const PropertyDocumentBanner = ({ doc, property, selected, setWindow, updateElement, setNotify, editionMode }) => {

    const getWindow = (instance) => {
        return (<PropertyDocumentWindow
            doc={instance}
            property={property}
            updateElement={(i, m) => {
                updateElement(i, m);
                setWindow(getWindow(i));
            }}
            setNotify={setNotify}
            close={() => setWindow(null)}
        />);
    }

    // Edition mode
    const [categories, setCategories] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [changed, setChanged] = useState(undefined);
    const [lastCategory, setLastCategory] = useState(null);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!editionMode)
            return;

        requestGET(process.env.REACT_APP_API_URL + '/realstate/documents/categories/')
            .then(response => {
                console.log("GOT categories", response);
                setCategories(response);
            });

        setLastCategory(doc.category ? doc.category.id : null);
    }, []);

    const syncChanges = () => {
        setSubmitting(true);
        requestWithBody("PATCH", process.env.REACT_APP_API_URL + '/realstate/documents/' + doc.id + '/?property=' + doc.property, {
            category_update: changed ? changed : 0
        }).then(response => {
            console.log("DOCUMENT UPDATE SUCCESS", response);
            updateElement(response);
            setLastCategory(response.category ? response.category.id : null);
            setNotify("Documento atualizado com sucesso");
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error updating document",
            ).then(e => {
                setError(e);
                setNotify("Ocorreu um erro, tente novamente", true);
            });
        }).finally(() => {
            setSubmitting(false);
        });
    }

    return (
        <Grid
            container
            direction="row"
            className={!editionMode ? (!selected ? "elementSelectable" : "elementSelectable selected") : ""}
            my={4}
            onClick={!editionMode ? () => setWindow(getWindow(doc)) : undefined}
        >
            <Grid
                xs={2}
                m={0}
                container
            >
                <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                    <InsertDriveFileIcon
                        color='secondary'
                        fontSize="inherit"
                    />
                </Typography>
            </Grid>
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0} sx={{wordBreak: 'break-all'}}>{doc.name}</Typography>
                {
                    !editionMode
                        ? <Grid container>
                            {
                                doc.category &&
                                <Typography variant="caption" mr={2}>
                                    <Tooltip title="Categoria">
                                        <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                    </Tooltip>
                                    {doc.category.name}
                                </Typography>
                            }
                            <Typography variant="caption" mr={2}>
                                <Tooltip title="Carregado a">
                                    <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                                {new Date(doc.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                            </Typography>
                        </Grid>
                        : categories && <Grid>
                            <FormGroup
                                fields={[
                                    buildFilterSelect(
                                        "Categoria", 
                                        "select", 
                                        "category", 
                                        categories.map(c => {return {id: c.id, label: c.name}})
                                    )
                                ]}
                                updateParams={(to_clean, id, val) => {
                                    if (id === "category") {
                                        setChanged(val==="" ? null : val);
                                    }
                                }}
                                initialVals={doc && doc.category ? {category:doc.category.id} : {}}

                                disabled={submitting}
                                errors={error}
                                setErrors={setError}

                                sx={{ width: '100%' }}
                            />
                            {
                                changed !== undefined && lastCategory !== changed &&  
                                <Button color="primary" variant="contained" onClick={syncChanges} disabled={submitting} sx={{width: '100%', mt: 1}}>
                                    Guardar alterações
                                </Button>
                            }
                        </Grid>
                }

            </Grid>
        </Grid>
    );
}

export default PropertyDocumentBanner;