import React, { useState, useEffect } from "react";

import L from 'leaflet';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css';

import markerImage from './pinproperty.png';

const ChaveLusaMap = ({ coordinates }) => {

    const markerIcon = new L.Icon({ // https://stackoverflow.com/questions/47723812/custom-marker-icon-with-react-leaflet
        iconUrl: markerImage,
        iconRetinaUrl: markerImage,
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(50, 66),
        // className: 'leaflet-div-icon'
    });

    return (
        <MapContainer center={coordinates} zoom={15} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
                position={coordinates}
                icon={markerIcon}
            >
            </Marker>
        </MapContainer>
    );
}

export default ChaveLusaMap;