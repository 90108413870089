// Status mapped from api/externalApps/models.py/PropertyPublished#getEstado
export const pp_status_pending = [
    'Em publicação',
    'Aguarda edição por parte do parceiro',
    'Aguarda eliminação por parte do parceiro'
];

export const pp_status_approved = [
    'Ativo'
]

export const pp_status_neutral = [
    'Desativo'
]

export const pp_status_error = [
    'Reprovado'
]