import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography
} from '@mui/material';

import FormGroup from "../../../../../Chavelusa/FormGroup";

import {
    buildFilterText,
    buildFilterSelect
} from "../../../../../Chavelusa/FormGroup/utils";

const marginOptionsY = [
    { label: 'Sem margem', id: '0' },
    { label: '1', id: '1' },
    { label: '2', id: '2' },
    { label: '3', id: '3' },
    { label: '4', id: '4' },
    { label: '5', id: '5' }
];
const marginOptionsX = [...marginOptionsY];
marginOptionsX.splice(1, 0, { label: 'Máxima', id: 'auto' });

const formFields = [
    {
        ...buildFilterText(
            'Altura',
            'height',
            true,
            (val) => {
                return val > 0 && val <= 100;
            },
            undefined,
            'number'
        ),
        info: 'Valor percentual, entre 1 e 100'
    },
    buildFilterSelect(
        'Largura',
        'select',
        'cols',
        [],
        null,
        true
    ),
    buildFilterSelect('Margem superior', 'select', 'mt', marginOptionsY, null, true),
    buildFilterSelect('Margem inferior', 'select', 'mb', marginOptionsY, null, true),
    buildFilterSelect('Margem esquerda', 'select', 'ml', marginOptionsX, null, true),
    buildFilterSelect('Margem direita', 'select', 'mr', marginOptionsX, null, true),
];

/**
 * ELEMENT COMPONENT GLOBAL FORM 
 * 
 * Element common structure:
 * - cols: int          Number of columns 
 * - height: int        Number of vhs to define height
 * - mt/mb/ml/mr: str   (Optional, default 0) Margin values
 */
const PropertySheetElementsGlobalForm = ({ element, setElement, totalCols, elementOriginal }) => {

    const [initialVals, setInitialVals] = useState(undefined);

    useEffect(() => {
        // Compute select options that depend on element current values
        var colsAvailable = 12 - totalCols;
        if (elementOriginal && elementOriginal.cols)
            colsAvailable += elementOriginal.cols;
        formFields[1].values = Array.from({ length: colsAvailable }, (_, i) => i + 1).map(colN => {
            return {
                label: Math.round((100 / 12) * colN) + '%',
                id: colN
            }
        });

        // Set default values if not set yet
        setInitialVals({
            ...element,
            mt: element.mt !== undefined ? element.mt : '0',
            mb: element.mb !== undefined ? element.mb : '0',
            ml: element.ml !== undefined ? element.ml : '0',
            mr: element.mr !== undefined ? element.mr : '0',
            cols: element.cols !== undefined ? element.cols : colsAvailable
        });
        if(element.cols === undefined) setElement({...element, cols: colsAvailable});
    }, [element]);

    return (
        initialVals
            ?
            <FormGroup
                fields={formFields}
                updateParams={(to_clean, id, val) => {
                    setElement({ ...element, [id]: val });
                }}
                initialVals={initialVals}
                sx={{ width: '100%' }}
            />
            :
            <></>
    );
}

export default PropertySheetElementsGlobalForm;