import React from "react";

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid
} from '@mui/material';

const ChaveLusaDialog = ({ children, open, handleClose, /* Optionals */ title, description, className, width }) => {
    return (
        <Dialog open={open} onClose={handleClose} className={className && className} maxWidth={!!width && width} fullWidth={!!width}>
            <Grid container>
                {
                    title && <DialogTitle>{title}</DialogTitle>
                }
            </Grid>
            <DialogContent>
                {
                    description &&
                    <DialogContentText>{description}</DialogContentText>
                }
                {children}
                {
                    handleClose &&
                    <Button
                        color="primary"
                        variant="outlined"
                        sx={{ mt: 2, width: '100%' }}
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                }
            </DialogContent>
        </Dialog>
    );
}

export default ChaveLusaDialog;