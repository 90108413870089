import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Alert
} from '@mui/material';


import ClientAgentBanner from "../../../CRM/Client/Banner";
import ChaveLusaMap from "../../../Chavelusa/Map";

import { keyValue } from "../utils";

const PropertyPrivateData = ({ property }) => {

    

    return (
        <Grid
            container
            className="elementSelectable disabled"
            direction="column"
            p={3}
            my={3}
        >
            <Grid mb={3}>
                <Typography variant="h6">Proprietário</Typography>
                <ClientAgentBanner
                    clientAgent={{
                        id: property.client_agent ? property.client_agent : '#',
                        client: property.client,
                        agent: property.agent
                    }}
                    hideTags={true}
                />
            </Grid>
            <Grid my={3}>
                <Typography variant="h6">Localização</Typography>
                {property.address && keyValue({ key: "Morada", value: property.address, html: true })}
                {keyValue({ key: "Código-postal", value: property.zipCodeFirst + '-' + property.zipCodeFinal })}
                {property.parish && keyValue({ key: "Freguesia", value: property.parish.name })}
                {property.parish && keyValue({ key: "Concelho", value: property.parish.county.name })}
                {property.parish && keyValue({ key: "Distrito", value: property.parish.county.district.name })}
                {property.coordinates && keyValue({ key: "Coordenadas", value: property.coordinates })}
                {
                    !!property.coordinates && property.coordinates.split(',').length===2 && property.coordinates.split(',').every(n => !isNaN(n)) && 
                    <Grid>
                        <ChaveLusaMap 
                            coordinates={property.coordinates.split(',').map(n => parseFloat(n))}
                        />
                        <Alert severity="info" sx={{mt: 1}}>
                            No site público, o pin que aqui se vê com a localização exata é substituído por um círculo que abrange uma área maior, para não divulgar a localização exata da propriedade.
                        </Alert>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

export default PropertyPrivateData;