import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography
} from '@mui/material';

import {
    Email as EmailIcon
} from '@mui/icons-material';

const TemplateBanner = ({template, selectSelf}) => {
    return (
        <Grid
            container
            direction="row"
            className={"elementSelectable"}
            mt={1}
            onClick={selectSelf}
        >
            <Grid
                xs={2}
                m={0}
                container
            >
                <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                    <EmailIcon color={"primary"} fontSize="inherit"/> 
                </Typography>
            </Grid>
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>{template.name}</Typography>
                <Typography variant="caption" mr={2}>{template.description}</Typography>
            </Grid>
        </Grid>
    );
}

export default TemplateBanner;