import React, { useState, useEffect } from "react";

import {
    Grid,
    Button,
    IconButton,
    Tooltip,
    Alert,
    FormHelperText
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import {
    Save as SaveIcon,
    DeleteOutline as DeleteIcon,
    Refresh as RefreshIcon,
} from '@mui/icons-material';

import FormGroup from "../FormGroup";

/**
 * A component that allows to create forms that consist of multiple FormGroup, encapsulating its logic and adapting
 * it to support the multiple instances
 * 
 * Differences from FormGroup:
 * fields {Array} A list of lists, the last with the structure expected by FormGroup
 * updateParams (Optional) {Method} Extra field index of form updated: (index, to_clean, id, val) 
 * initialVals (Optional) {Array} Array of objects (one per form to) with values to initialize the form (for editting)
 * 
 * Extra attributes:
 * addField (Optional) {Method} When given, a button to add an extra form is added and triggers this method when clicked
 * allowDelete (Optional) {Boolean} When true, user is able to mark form as to delete (its data will be ignored on submit)
 * atLeastOneRequired (Optional) {Boolean} When true, user can't submit form it does not have at least one form
 */
const FormGroupMultipleInline = ({
    fields, sx, updateParams, initialVals = [], onSubmit, disabled, errors, setErrors,  // From FromGroup (with changes)
    addField, allowDelete, atLeastOneRequired // Extra
}) => {

    // Internal state
    const [vals, setVals] = useState(!initialVals.length ? fields.map(_ => { return {}; }) : initialVals);
    const [invalidFields, setInvalidFields] = useState(fields.map(_ => { return {}; }));
    const [toDelete, setToDelete] = useState([]); // Array of indexes of forms to delete

    // Keep internal state with values of each form
    const updateParamsLocal = (i, to_clean, id, val) => {
        // Update value of id and remove value for 
        setVals(valsOld => {
            // let valsCopy = [...valsOld];
            if (to_clean) {
                to_clean.forEach(key => {
                    delete valsOld[i][key];
                });
            }
            valsOld[i][id] = val;
            return valsOld;
        });

        // When a parameter is updated locally, if updateParams is set, also propagate to upper component
        updateParams && updateParams(i, to_clean, id, val);
    };

    // When fields change, adapt local variables to match number of fields and propagate to upper component
    const addFieldLocal = () => {
        setInvalidFields(invOld => {
            invOld.push({});
            return invOld;
        });
        setVals(valsOld => {
            valsOld.push({});
            return valsOld;
        })
        addField && addField();
    };

    return (
        <>
            {
                fields.map((fieldsArray, i) =>
                    <>
                        <Grid container sx={{ width: '100%', flexWrap: 'nowrap' }}>
                            <FormGroup
                                fields={fieldsArray}
                                sx={sx}
                                updateParams={(to_clean, id, val) => updateParamsLocal(i, to_clean, id, val)}
                                initialVals={initialVals.length > i ? initialVals[i] : {}}
                                disabled={
                                    disabled || (allowDelete && toDelete.indexOf(i)>=0)
                                }
                                errors={errors}
                                setErrors={setErrors}
                                setInvalid={(inv) => setInvalidFields(invOld => {
                                    let invCopy = [...invOld];
                                    invCopy[i] = inv;
                                    return invCopy;
                                })}

                            />
                            {
                                allowDelete &&
                                    (toDelete.indexOf(i) < 0
                                    ?
                                    <Tooltip title="Eliminar">
                                        <IconButton
                                            aria-label="Eliminar"
                                            color="secondary"
                                            sx={{
                                                my: 'auto'
                                            }}
                                            onClick={() => setToDelete(d_old => [...d_old, i])}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Recuperar">
                                        <IconButton
                                            aria-label="Recuperar"
                                            color="secondary"
                                            sx={{
                                                my: 'auto'
                                            }}
                                            onClick={() => {
                                                setToDelete(d_old => d_old.filter(d => d!==i));
                                            }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>)
                            }
                        </Grid>
                        {
                            toDelete.indexOf(i)>=0 &&
                            <FormHelperText>
                                Este elemento será eliminado quando o formulário for submetido.
                            </FormHelperText>
                        }
                    </>

                )
            }
            {
                !fields.length &&
                <Alert severity="info" color="primary" sx={{my:3}}>
                    Este formulário encontra-se vazio.
                    {
                        onSubmit && " Pode submetê-lo se o quiser guardar vazio."
                    }
                    {
                        addField && " Para adicionar elementos, clique em \"Adicionar mais\"."
                    }
                </Alert>
            }
            {
                atLeastOneRequired && (vals.length===0 || toDelete.length>=vals.length) &&
                <Alert severity="error" sx={{my:3}}>
                    Este formulário encontra-se vazio. É obrigatório o preenchimento de pelo menos um elemento!
                </Alert>
            }
            {
                addField &&
                <Button
                    color="primary"
                    variant="outlined"
                    sx={{ width: '100%', mt: 3 }}
                    onClick={addFieldLocal}
                >
                    Adicionar mais
                </Button>
            }
            {
                onSubmit &&
                (
                    !disabled
                        ?
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', mt: 3 }}
                            disabled={
                                !invalidFields.filter((_, index) => toDelete.indexOf(index)<0).every(inv => Object.keys(inv).length === 0)
                                || (atLeastOneRequired && (vals.length===0 || toDelete.length>=vals.length))
                            }
                            onClick={onSubmit && (() => onSubmit(vals.filter((_, index) => toDelete.indexOf(index)<0)))}
                        >
                            Submeter
                        </Button>
                        :
                        <LoadingButton
                            loading
                            color="primary"
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            sx={{ width: '100%', mt: 3 }}
                        >
                            Submeter
                        </LoadingButton>
                )
            }
        </>
    );
}

export default FormGroupMultipleInline;