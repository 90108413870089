import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Stepper as MaterialStepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    Link
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ChaveLusaAlert from "../Alert";

/**
 * Stepper encapsulates several components steps in an user-friendly interface that provides user with information about the progress 
 * 
 * Required:
 * @param {Array} steps Array of objects with one of the following structures
 *      {label: <title>, component: <ReactComponent>}
 *      {label: <title>, url: <String>}
 *      Note: Objects without component/url will always be displayed without button
 * @param {Array} activeStepsInitial Indexes of steps accessible (only works if any set to false)
 * @param {Array} completed Array with indexes of steps indexes completed (tick is shown insitead of number)
 * Optional:
 * @param {Boolean} any If true, allows to access any step (if not, only activeStepInitial) 
 * @param {String} (Optional) title The title to display in the header (on top of the stepper)
 * @param {Method} (Optional) goBack When defined, below the steps there is a go back button that triggers this method when clicked  
 * @param {Method} (Optional) onDelete When defined, below the steps there is a delete button that triggers this method when clicked
 * @param {Boolean} (Optional) hasChanged When defined and true asks for confirmation before allowing go back to steps 
 * @param {Boolean} (Options) backToMenu When changes value, showStep is set to False
 * @param {Integer} (Optional) activeStepOverride Defines step active to be shown (on change shows the step provided)
 */
const Stepper = ({ steps, activeStepsInitial=[], completed=[], any=false, 
    // Optionals
    title, goBack, onDelete, hasChanged, backToMenu, activeStepOverride
}) => {

    const [activeStep, setActiveStep] = useState(undefined);
    const [showStep, setShowStep] = useState(false);

    // Alert before closing when changed
    const [alert, setAlert] = useState(undefined);
    const ALERT_CONFIRM_DELETE = {
        title: 'Esta operação é irreversível!',
        text: 'Tem a certeza que pretende eliminar esta entidade?',
        action: onDelete
    };
    const ALERT_CONFIRM_GOBACK = {
        title: 'Quer mesmo regressar ao passo anterior?',
        text: 'O conteúdo desta janela foi alterado sem ser guardado e vai ser perdido.',
        action: () => setShowStep(false)
    };

    // When backToMenu changes, unselect selected step
    useEffect(() => {
        setShowStep(false);
    }, [backToMenu]);

    // Listen to activeStepOverride
    useEffect(() => {
        if (activeStepOverride === undefined) return;
        if (steps[activeStepOverride].component == undefined) return;
        setActiveStep(activeStepOverride);
        setShowStep(true);
    }, [activeStepOverride])

    return (
        <Grid container direction="column" sx={{height: '100%', flexWrap: 'nowrap', width: '100%'}}>
            {
                title && !showStep &&
                <Typography variant="h6" mb={3}>
                    {title}
                </Typography>
            }
            {
                !showStep 
                ?
                <Grid container direction="column">
                    <MaterialStepper orientation="vertical">
                        {
                            steps.map((s, index) => (
                                <Step 
                                    key={s.label} 
                                    active={(!!s.component || !!s.url) && (activeStepsInitial.indexOf(index)>=0 || any)} 
                                    completed={completed.indexOf(index)>=0}
                                >
                                    <StepLabel>{s.label}</StepLabel>
                                    <StepContent>
                                        <Button
                                            color="primary"
                                            variant={completed.indexOf(index)<0 ? "contained" : "outlined"}
                                            onClick={() => {
                                                if (s.url) {
                                                    window.location.href = s.url;
                                                } else {
                                                    setActiveStep(index);
                                                    setShowStep(true);
                                                }
                                            }}
                                        >
                                            {completed.indexOf(index)<0 ? "Iniciar" : "Editar"}
                                        </Button>
                                    </StepContent>
                                </Step>
                            ))
                        }
                    </MaterialStepper>
                    {
                        goBack &&
                        <Button 
                            color="primary" 
                            variant={(any || completed && completed.length === steps.length) ? "contained" : "outlined"} 
                            xs={12} 
                            sx={{mt: 5}}
                            onClick={goBack}
                        >
                            Concluir
                        </Button>
                    }
                    {
                        onDelete &&
                        <Button 
                            color="primary" 
                            variant="outlined" 
                            xs={12} 
                            sx={{mt: 3}}
                            onClick={() => setAlert(ALERT_CONFIRM_DELETE)}
                        >
                            Eliminar
                        </Button>
                    }
                </Grid>
                :
                <StepLabel>
                    {
                        goBack 
                        ?
                            <>
                                <Link
                                    onClick={() => {
                                        hasChanged ? setAlert(ALERT_CONFIRM_GOBACK) : setShowStep(false);
                                    }}
                                    sx={{cursor: 'pointer'}}
                                    underline="hover"
                                    disabled={!goBack}
                                >
                                    <ArrowBackIosIcon fontSize="inherit" />
                                    {title && title}
                                </Link>
                                {title && " / "}
                                {steps[activeStep].label}
                            </>
                        :
                            <>
                                {title && title}
                                {title && " / "}
                                {steps[activeStep].label}
                            </>
                    }
                </StepLabel>
            }
            {
                activeStep>=0 && showStep ? steps[activeStep].component : null
            }

        { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default Stepper;