import {
    Grid,
    Typography,
    Tooltip
} from '@mui/material';

import {
    Info as InfoIcon
} from '@mui/icons-material';

import { colors } from '../../../utils';

export const keyValue = ({ key, value, html, notes }) => {
    return (
        <Grid container direction={html ? 'column' : 'row'}>
            <Typography
                variant='caption'
                sx={{
                    marginTop: html ? 1 : 'auto',
                    mb: html ? 0 : .2
                }
                }>
                {key}
                {notes &&
                    <Tooltip sx={{ mx: 0.5, verticalAlign: 'top' }} title={notes}>
                        <InfoIcon color="primary" fontSize="inherit" />
                    </Tooltip>
                }
            </Typography>
            {
                html
                    ? <Typography
                        dangerouslySetInnerHTML={{ __html: value }}
                        sx={{
                            borderLeft: '2px solid ' + colors.primary,
                            paddingLeft: 1
                        }}
                    ></Typography>
                    : <Typography ml={1}>{value}</Typography>
            }
        </Grid>
    )
}
