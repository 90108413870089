import React, { useState, useEffect } from "react";

import { Grid, Button } from "@mui/material";

import {
    requestGET
} from '../../../utils';

import { colors } from "../../../utils";

// Get url parameters
import {
    useLocation
} from 'react-router-dom';

const secondsTimeSpanToHMS = (s) => {
    var m = Math.floor(s / 60); //Get remaining minutes
    s -= m * 60;
    return (m > 0 ? (m < 10 ? '0' + m : m) + " minutos e " : "") + (s < 10 ? '0' + s : s) + " segundos"; //zero padding on minutes and seconds
}

const Maintenance = () => {

    // Countdown
    const [time, setTime] = useState(60);
    const [timeStr, setTimeStr] = useState('');

    const countdown = () => {
        if (time > 0) {
            setTime(old => old - 1);
            setTimeout(countdown, 1000);
        }
    }

    // Redirect when back up
    const [loading, setLoading] = useState(true);
    const search = useLocation().search;
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/login/user/', null, true)
            .then(res => {
                if (!res.user) { throw res }
                const goBackTo = new URLSearchParams(search).get("goBackTo");
                window.location.href = (!goBackTo || goBackTo.indexOf('404') >= 0 ? '/' : goBackTo);
            })
            .catch(err => {
                setLoading(false);
                setTimeout(countdown, 1000);
            })
    }, []);

    useEffect(() => {
        setTimeStr(secondsTimeSpanToHMS(time));
        time === 0 && window.location.reload();
    }, [time]);

    return (
        <Grid
            sx={{
                p: 5
            }}
        >
            <div
                className="mx-auto col-12 col-lg-9 px-3 px-lg-0 z-100 d-flex flex-column flex-grow-1"
            >
                <h1
                    className="text-center font-weight-bold text-white display-4 mt-4 mb-5"
                    style={{color: colors.primary}}
                >
                    EM MANUTENÇÃO
                </h1>
                <h3 className="text-center text-white display-8 mb-4">
                    Por motivos de manutenção o painel de administração encontra-se indisponível. Prometemos ser breves!
                </h3>
                <p className="text-center text-white">
                    Para sua comodidade, pode deixar a página aberta e iremos recarregá-la a cada minuto (faltam {timeStr}) até que a página volte a estar disponível.
                </p>
                <Button 
                    color="primary" 
                    variant="outlined"
                    onClick={() => window.location.reload()}
                >Recarregar</Button>
            </div>
        </Grid>
    );
}

export default Maintenance;