import {
    Grid,
    Typography,
    Tooltip,
    Chip
} from '@mui/material';

import {
    Chat as ChatIcon,
    AttachFile as AttachFileIcon,
    Event as EventIcon
} from '@mui/icons-material';

import { DateHelper } from "../../../../utils";

export const buildTagsForClientAgentForProperty = (instance) => {
    return (<>
        {
            instance.visits>0 && 
            <Typography variant="caption" mr={2} sx={{width: '100%'}}>
                <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                {
                    instance.visits_next && <span className="bold">Próxima visita a {new Date(instance.visits_next).toLocaleDateString('pt-PT', DateHelper.format_date_time)}</span>
                }
                {
                    instance.visits_last && instance.visits_next && <span>(última a {new Date(instance.visits_last).toLocaleDateString('pt-PT', DateHelper.format_date_time)})</span>
                }
                {
                    instance.visits_last && !instance.visits_next && <span>Última visita a {new Date(instance.visits_last).toLocaleDateString('pt-PT', DateHelper.format_date_time)}</span>
                }
            </Typography>
        }
        {
            instance.classifications>0 &&
            <Typography variant="caption" mr={2} sx={{width: '100%'}}>
                <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                Qualificado com esta propriedade {instance.classification_match>0 && <span className="bold">com correspondência de {Math.ceil(instance.classification_match*100)}%</span>}
            </Typography>
        }
        {
            instance.forms>0 && 
            <Typography variant="caption" mr={2} sx={{width: '100%'}}>
                <ChatIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                Formulário de contacto nesta propriedade enviado a {new Date(instance.forms_last).toLocaleDateString('pt-PT', DateHelper.format_date_time)} {instance.forms_pending && <span className="bold">por processar</span>}
            </Typography>
        }
    </>);
}

