import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import QRCode from "react-qr-code";

import FormGroup from "../../../../../Chavelusa/FormGroup";
import { buildFilterSelect } from "../../../../../Chavelusa/FormGroup/utils";
import PropertySheetElementsGlobalForm from "../GlobalForm";

import { colors } from "../../../../../../utils";
import { TYPE_QR } from "../../enum";

/**
 * QR CODE ELEMENT COMPONENT 
 * 
 * Element structure:
 * COMMON (refer to GlobalForm for complete structure)
 * - type: str          QRCODE
 * SPECIFIC
 * - value: str         Property URL
 * 
 * - color: str         (Optional, default black) Color HEX code
 * - backgroundColor: str   (Optional, default white) Color HEX code
 * - disposition: str   (Optional, default horizontal) vertical/horizontal
 * - align: str         (Optional, default center) left/center/right
 * - alignVertical: str (Optional, default center) top/center/bottom
 */

const colorsOptions = [
    { label: 'Preto puro', id: colors.pureBlack },
    { label: 'Preto legível', id: colors.black },
    { label: 'Cinzento', id: colors.gray },
    { label: 'Branco puro', id: colors.pureWhite },
    { label: 'Branco legível', id: colors.white },
    { label: 'Cor primária', id: colors.primary },
    { label: 'Cor secundária', id: colors.secondary }
];

const formFields = [
    buildFilterSelect(
        'Disposição',
        'select',
        'disposition',
        [
            { label: '100% altura (horizontal)', id: 'horizontal' },
            { label: '100% comprimento (vertical)', id: 'vertical' },
        ],
        null,
        true
    ),
    buildFilterSelect(
        'Alinhamento horizontal',
        'select',
        'align',
        [
            { label: 'Esquerda', id: 'left' },
            { label: 'Centro', id: 'center' },
            { label: 'Direita', id: 'right' }
        ],
        null,
        true
    ),
    buildFilterSelect(
        'Alinhamento vertical',
        'select',
        'alignVertical',
        [
            { label: 'Topo', id: 'top' },
            { label: 'Centro', id: 'center' },
            { label: 'Fundo', id: 'bottom' }
        ],
        null,
        true
    ),
    buildFilterSelect(
        'Cor',
        'select',
        'color',
        colorsOptions,
        null,
        true
    ),
    buildFilterSelect(
        'Fundo',
        'select',
        'backgroundColor',
        colorsOptions,
        null,
        true
    ),
];

const PropertySheetElementQRCode = ({ element, setElement, property, totalCols }) => {

    const edit = !!setElement;

    console.log("PropertySheetElementText", element, edit);

    const [localElement, setLocalElement] = useState({});
    const [localFormFields, setLocalFormFields] = useState(formFields);

    // Set local element when element changes
    useEffect(() => {
        let localCandidate = element ? {...element} : {};
        // Default properties
        if (!('type' in localCandidate)) localCandidate['type'] = TYPE_QR;
        if (!('value' in localCandidate)) localCandidate['value'] = process.env.REACT_APP_PUBLIC_URL + '/' + property.id;
        if (!('color' in localCandidate)) localCandidate['color'] = colors.pureBlack;
        if (!('backgroundColor' in localCandidate)) localCandidate['backgroundColor'] = colors.pureWhite;
        if (!('disposition' in localCandidate)) localCandidate['disposition'] = 'horizontal';
        if (!('align' in localCandidate)) localCandidate['align'] = 'center';
        if (!('alignVertical' in localCandidate)) localCandidate['alignVertical'] = 'center';
        if (!('height' in localCandidate)) localCandidate['height'] = 25;
        setLocalElement(localCandidate);
    }, [element]);

    return (
        <>
            {
                edit &&
                <Typography variant='subtitle'>
                    Pré-visualização
                </Typography>
            }
            <Grid
                xs={edit ? (localElement.cols ? localElement.cols : 12) : 12}
                sx={!edit ? {
                    height: '100%'
                } : {
                    height: localElement.height ? localElement.height + 'vh' : '',
                    border: '1px dashed ' + colors.primary,
                    padding: localElement.padding ? parseInt(localElement.padding) : 0,
                    overflow: 'hidden'
                }}
                container
            >
                {
                    localElement.value &&
                    <QRCode
                        style={{
                            height: localElement.disposition === 'vertical' ? 'auto' : '100%',
                            width: localElement.disposition === 'vertical' ? '100%' : 'auto',
                            'margin-top': !localElement.alignVertical ? 'auto' : localElement.alignVertical === 'center' || localElement.alignVertical === 'bottom' ? 'auto' : 0,
                            'margin-bottom': !localElement.alignVertical ? 'auto' : localElement.alignVertical === 'center' || localElement.alignVertical === 'top' ? 'auto' : 0,
                            'margin-left': !localElement.align ? 'auto' : localElement.align === 'center' || localElement.align === 'right' ? 'auto' : 0,
                            'margin-right': !localElement.align ? 'auto' : localElement.align === 'center' || localElement.align === 'left' ? 'auto' : 0,
                        }}
                        value={localElement.value}
                        bgColor={localElement.backgroundColor ? localElement.backgroundColor : colors.pureWhite}
                        fgColor={localElement.color ? localElement.color : colors.pureBlack}
                    />
                }
            </Grid>
            {
                edit &&
                <Grid mt={3}>
                    <Typography variant='subtitle'>
                        Editar propriedades do código QR
                    </Typography>
                    {
                        localElement && Object.keys(localElement).length > 0 &&
                        <>
                            <FormGroup
                                fields={localFormFields}
                                updateParams={(to_clean, id, val) => {
                                    setLocalElement({ ...localElement, [id]: val });
                                }}
                                initialVals={localElement}
                                sx={{ width: '100%' }}
                            />
                            <PropertySheetElementsGlobalForm
                                element={localElement}
                                setElement={setLocalElement}
                                totalCols={totalCols}
                                elementOriginal={element}
                            />
                        </>
                    }
                    {
                        localElement &&
                        <Button color="primary" variant="contained" sx={{ width: '100%', mt: 1 }} onClick={() => setElement(localElement)}>
                            {!element ? "Adicionar QR" : "Editar QR"}
                        </Button>
                    }
                </Grid>
            }
        </>
    );
}

export default PropertySheetElementQRCode;