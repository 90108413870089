import React, { useState, useEffect } from "react";

import {
    Chip,
    Grid,
    Typography
} from '@material-ui/core';

import {
    LocalOffer as LocalOfferIcon,
    SupervisorAccount as SupervisorAccountIcon,
    ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';

import { is_agent } from "../../../../Services/permissions";


const Sidebar = ({ property }) => {

    return (
        <Grid sx={{ width: '100%' }} pl={3} className="instance-sidebar">
            {
                property.errors && Object.keys(property.errors).length > 0 &&
                <Grid
                    my={4}
                    p={3}
                    sx={{ width: '100%' }}
                    className="elementSelectable disabled"

                >
                    <Typography variant="subtitle1" className="bold" mb={1}>
                        <ErrorOutlineIcon color="warning" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Ficha do imóvel incompleta
                    </Typography>
                    <dl style={{margin: 0}}>
                        {
                            Object.keys(property.errors).map(key =>
                                <>
                                    <dt className="bold">{property.errors[key]['translation']}</dt>
                                    {
                                        property.errors[key]['errors'].map(e => <dd>{e}</dd>)
                                    }
                                </>
                            )
                        }
                    </dl>
                </Grid>
            }
        </Grid>
    );
}

export default Sidebar;