import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';

import FormGroupMultipleInline from "../../../../Chavelusa/FormGroupMultipleInline";

import {
    requestWithBody,
    requestGET,
    handleRequestError
} from "../../../../../utils";

import {
    buildFilterCheckboxMultiple
} from '../../../../Chavelusa/FormGroup/utils';

const ClientClassificationFormFeatures = ({ clientAgentId, classification, success, formChanged }) => {

    const url = process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgentId;

    const [fields, setFields] = useState([]);    
    
    // Get features from API
    const [loading, setLoading] = useState(true);
    const [initialVals, setInitialVals] = useState([]);
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/realstate/featuresGroups/').then(
            response => {
                console.log("Got /realstate/featuresGroups/", response);
                let new_initial_vals = [];
                let classifications_features_ids = classification.features.map(f => f.feature.id);
                // Define one field per feature group
                setFields(
                    response.map(fGroup => {
                        // For each, also set the initial values
                        new_initial_vals.push({
                            'features': fGroup.features.filter(f => classifications_features_ids.indexOf(f.id)>=0).map(f => f.id)
                        });

                        // Return field structure
                        return [buildFilterCheckboxMultiple(
                            fGroup.name,
                            'features',
                            fGroup.features.map(f => {return {label:f.name, id:f.id}}),
                            false
                        )];
                    })
                );
                setInitialVals(new_initial_vals);
                setLoading(false);
            }
        );
    }, []);

    // Submit form
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const onSubmit = (vals) => {
        console.log("Submit qualification features...", vals);
        setSubmitting(true);

        // Adapt the model to what API expects
        let api_model = [];
        vals.forEach(formVal => {
            if ('features' in formVal && formVal['features'].length) 
                api_model.push(...formVal['features'].map(fid => {return {id: fid};}));
        });

        console.log("Submit qualification features restructured...", api_model);

        // PATCH to API
        requestWithBody(
            "PATCH",
            url,
            {features_ids: api_model}
        ).then(response => {
            console.log(
                "Qualification (features) updated with success!",
                response
            );
            success(response);
        }).catch(error => 
            handleRequestError(
                error, 
                fields[0].map(f => f.id), 
                "Error at qualification form features"
            )
            .then(e => setError(e))
        )
        .finally(() => {
            setSubmitting(false);
        });
    }


    return (
        <Grid container direction="column">
            <Typography variant="h6" mb={0}>
                Características
            </Typography>
            {
                !loading &&
                <FormGroupMultipleInline 
                    fields={fields}
                    updateParams={() => formChanged()}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row', 
                        flexWrap: 'wrap',
                        my: 3
                    }}
                    onSubmit={onSubmit}
                    disabled={submitting}
                    errors={error}
                    setErrors={setError}
                    initialVals={initialVals}
                />
            }
            {
                loading && <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
        </Grid>
    );
}

export default ClientClassificationFormFeatures;