import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import ChaveLusaDialog from "../../../../Chavelusa/Dialog";
import PropertySheetElementImage from "./Image";
import PropertySheetElementText from "./Text";
import PropertySheetElementQRCode from "./QRCode";
import PropertySheetElementAgent from "./AgentCard";
import PropertySheetElementNumbers from "./Numbers";

import { TYPE_IMAGE, TYPE_TEXT, TYPE_QR, TYPE_AGENT, TYPE_NUMBERS } from '../enum';

const PropertySheetElementEdit = ({ element, setElement, property, propertyImages, totalCols }) => {

    const [editor, setEditor] = useState(!element ? undefined : element.type);
    return (
        <ChaveLusaDialog
            title={!editor ? 'Adicionar elemento' : 'Editar elemento'}
            open={true}
            handleClose={() => setElement(undefined)}
            width={'md'}
        >
            {
                !editor &&
                <Grid xs={12} container direction="column" wrap="wrap">
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ mb: 1 }}
                        onClick={() => setEditor(TYPE_IMAGE)}
                        disabled={!propertyImages || propertyImages.length === 0}
                    >
                        Imagem
                    </Button>
                    <Button color="primary" variant="contained" sx={{ mb: 1 }} onClick={() => setEditor(TYPE_TEXT)}>
                        Texto
                    </Button>
                    <Button color="primary" variant="contained" sx={{ mb: 1 }} onClick={() => setEditor(TYPE_QR)}>
                        Código QR
                    </Button>
                    <Button color="primary" variant="contained" sx={{ mb: 1 }} onClick={() => setEditor(TYPE_AGENT)}>
                        Cartão do agente
                    </Button>
                    <Button color="primary" variant="contained" sx={{ mb: 1 }} onClick={() => setEditor(TYPE_NUMBERS)}>
                        Números da propriedade
                    </Button>
                </Grid>
            }
            {
                editor === TYPE_IMAGE &&
                <PropertySheetElementImage
                    element={element}
                    setElement={setElement}
                    propertyImages={propertyImages}
                    totalCols={totalCols}
                />
            }
            {
                editor === TYPE_TEXT &&
                <PropertySheetElementText
                    element={element}
                    setElement={setElement}
                    property={property}
                    totalCols={totalCols}
                />
            }
            {
                editor === TYPE_QR &&
                <PropertySheetElementQRCode
                    element={element}
                    setElement={setElement}
                    property={property}
                    totalCols={totalCols}
                />
            }
            {
                editor === TYPE_AGENT &&
                <PropertySheetElementAgent
                    element={element}
                    setElement={setElement}
                    property={property}
                    totalCols={totalCols}
                />
            }
            {
                editor === TYPE_NUMBERS &&
                <PropertySheetElementNumbers
                    element={element}
                    setElement={setElement}
                    property={property}
                    totalCols={totalCols}
                />
            }
        </ChaveLusaDialog>
    );
}

export default PropertySheetElementEdit;