import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Avatar,
    Badge,
    Link,
    Tooltip
} from '@mui/material';

import {
    Event as EventIcon,
    StickyNote2 as StickyNote2Icon,
    Summarize as SummarizeIcon,
    Edit as EditIcon
} from '@mui/icons-material';

import ChaveLusaDialog from "../../../../Chavelusa/Dialog";
import FormGroup from "../../../../Chavelusa/FormGroup";
import Alert from "../../../../Chavelusa/Alert";

import {
    requestWithBody,
    handleRequestError
} from '../../../../../utils';

import {
    buildFilterText,
} from '../../../../Chavelusa/FormGroup/utils';

const fieldsGeneral = [
    buildFilterText('Motivo para o fecho', 'closingNote', false, null, undefined, 'text', true, 3),
];

/**
 * ClientClassificationFormClose component
 * 
 * @param {crm.models.ClientClassification} classification
 * @param {Integer} clientAgentId
 * @param {String} op, can be "open" or "close"
 * @param {Method} updateClassification Called when classification updated with the server response as argument
 * @param {Method} destroy Called when operations finished (submitted or canceled)
 */

const ClientClassificationFormClose = ({ classification, clientAgentId, op, updateClassification, destroy }) => {

    console.log("ClientClassificationFormClose", classification);

    const url = process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgentId;

    // API Calls
    const [error, setError] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const submitForm = (data) => {
        if (!op) return;

        console.log(op + " classification...", data);
        setSubmitting(true);

        let api_data = {
            closed: op === 'close',
            closingNote: ''
        };
        if (data) api_data = { ...api_data, ...data };

        requestWithBody(
            "PATCH",
            url,
            api_data
        ).then(response => {
            console.log(
                "Classification updated with success",
                response
            );
            updateClassification(response);
        }).catch(error =>
            handleRequestError(
                error,
                [],
                "Error updating classification"
            )
            .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    // Render
    if (op && op === 'close')
        return (
            <ChaveLusaDialog
                open={true}
                className={'dont-hover'}
                handleClose={destroy}
            >
                <Typography variant='h6'>Fechar classificação</Typography>
                <FormGroup
                    fields={fieldsGeneral}
                    onSubmit={submitForm}
                    initialVals={{}}

                    disabled={submitting}
                    errors={error}
                    setErrors={setError}

                    sx={{ width: '100%' }}
                />
            </ChaveLusaDialog>
        );
    
        if (op && op === 'open')
            return (
                <Alert
                    title={'Tem a certeza que pretende reabrir a qualificação?'}
                    text={'Esta operação implica a perda das notas de fecho.'}
                    action={() => submitForm(undefined)}
                    close={destroy}
                />
            )
}

export default ClientClassificationFormClose;