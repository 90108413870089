import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import FormGroup from "../../../../../Chavelusa/FormGroup";
import PropertySheetElementsGlobalForm from "../GlobalForm";

import {
    buildFilterSelect,
    buildFilterText,
    buildFilterRichText,
    buildFilterRadio
} from "../../../../../Chavelusa/FormGroup/utils";

import { TYPE_TEXT } from '../../enum';
import { colors } from "../../../../../../utils";


/**
 * TEXT ELEMENT COMPONENT 
 * 
 * Element structure:
 * COMMON (refer to GlobalForm for complete structure)
 * - type: str          TEXT
 * SPECIFIC
 * - content: str       Textual content
 * - variant: str       Typography variant, or "richtext"
 * 
 * - color: str         (Optional, default black) Color HEX code
 * - backgroundColor: str   (Optional, default white) Color HEX code
 * - align: str         (Optional, default left) left/center/right
 * - alignVertical: str (Optional, defaulf center) top/center/bottom
 * - padding: int       (Optional, default 0)
 */

const colorsOptions = [
    { label: 'Preto puro', id: colors.pureBlack },
    { label: 'Preto legível', id: colors.black },
    { label: 'Cinzento', id: colors.gray },
    { label: 'Branco puro', id: colors.pureWhite },
    { label: 'Branco legível', id: colors.white },
    { label: 'Cor primária', id: colors.primary },
    { label: 'Cor secundária', id: colors.secondary }
];

const formFields = [
    buildFilterRadio(
        'Variante',
        'variant',
        [
            { label: 'Título', id: 'h6' },
            { label: 'Corpo', id: 'body1' },
            { label: 'Corpo secundário', id: 'body2' },
            { label: 'Legenda', id: 'caption' },
            { label: 'Editor de texto', id: 'richtext' }
        ],
        true,
        true
    ),
    buildFilterSelect(
        'Alinhamento horizontal',
        'select',
        'align',
        [
            { label: 'Esquerda', id: 'left' },
            { label: 'Centro', id: 'center' },
            { label: 'Direita', id: 'right' }
        ],
        null,
        true
    ),
    buildFilterSelect(
        'Alinhamento vertical',
        'select',
        'alignVertical',
        [
            { label: 'Topo', id: 'top' },
            { label: 'Centro', id: 'center' },
            { label: 'Fundo', id: 'bottom' }
        ],
        null,
        true
    ),
    buildFilterSelect(
        'Cor',
        'select',
        'color',
        colorsOptions,
        null,
        true
    ),
    buildFilterSelect(
        'Fundo',
        'select',
        'backgroundColor',
        colorsOptions,
        null,
        true
    ),
    {...buildFilterText(
        'Espaçamento interior',
        'padding',
        true,
        (val) => { return val >= 0; },
        undefined,
        'number'
    ), acceptsZero: true},
];

const PropertySheetElementText = ({ element, setElement, property, totalCols }) => {

    const edit = !!setElement;

    console.log("PropertySheetElementText", element, edit);

    const [localElement, setLocalElement] = useState({});
    const [localFormFields, setLocalFormFields] = useState(formFields);
    const [suggestions, setSuggestions] = useState([]);

    // Set local element when element changes
    useEffect(() => {
        let localCandidate = element ? {...element} : {};
        // Default properties
        if (!('type' in localCandidate)) localCandidate['type'] = TYPE_TEXT;
        if (!('align' in localCandidate)) localCandidate['align'] = 'left';
        if (!('alignVertical' in localCandidate)) localCandidate['alignVertical'] = 'center';
        if (!('color' in localCandidate)) localCandidate['color'] = colors.black;
        if (!('backgroundColor' in localCandidate)) localCandidate['backgroundColor'] = colors.pureWhite;
        if (!('padding' in localCandidate)) localCandidate['padding'] = 0;
        if (!('height' in localCandidate)) localCandidate['height'] = 10;
        setLocalElement(localCandidate);
    }, [element]);

    // Build suggestions 
    useEffect(() => {
        const s = [];

        if (!property)
            return;

        if (property.agent) s.push({ label: 'Agente', value: property.agent.name  });
        if (property.energyClass) s.push({ label: 'Classe energética', value: property.energyClass_display });
        if (property.parish) s.push({ label: 'Concelho', value: property.parish.county.name });
        if (property.conservation) s.push({ label: 'Conservação', value: property.conservation_display });
        if (property.description) s.push({ label: 'Descrição', value: property.description, richTextOnly: true });
        if (property.state) s.push({ label: 'Estado', value: property.state_display });
        if (property.address) s.push({ label: 'Morada', value: property.address });
        if (property.price) s.push({ label: 'Preço', value: property.price });
        if (property.type) s.push({ label: 'Tipo', value: property.type.name });
        if (property.title) s.push({ label: 'Título', value: property.title });
        s.push({ label: 'URL Anúncio', value: process.env.REACT_APP_PUBLIC_URL.replaceAll("http://", "").replaceAll("https://", "") + '/' + property.id});

        setSuggestions(s);
    }, [property]);

    // Addapt form fields to text variant
    useEffect(() => {
        console.log("PropertySheetElementText localElement", localElement);

        if (localElement.variant && localElement.variant !== 'richtext') // Normal text
            setLocalFormFields([
                formFields[0], // Variant
                buildFilterText(
                    'Texto',
                    'content',
                    true
                ),
                ...formFields.slice(1).filter(f => f.id!=='content'),
            ]);
        else if (localElement.variant && localElement.variant === 'richtext') // Rich text
            setLocalFormFields([
                formFields[0], // Variant
                buildFilterRichText(
                    'Texto',
                    'content',
                    true
                ),
                ...formFields.slice(1).filter(f => f.id!=='content'),
            ]);
        else // No text variant selected, remove content field
            setLocalFormFields([
                ...formFields.filter(f => f.id!=='content'),
            ]);
    }, [localElement]);

    return (
        <>
            {
                edit &&
                <Typography variant='subtitle'>
                    Pré-visualização
                </Typography>
            }
            <Grid
                xs={edit ? (localElement.cols ? localElement.cols : 12) : 12}
                sx={!edit ? {
                    height: '100%'
                } : {
                    backgroundColor: localElement.backgroundColor ? localElement.backgroundColor : 'none',
                    height: localElement.height ? localElement.height + 'vh' : '',
                    border: '1px dashed ' + colors.primary,
                    padding: localElement.padding ? parseInt(localElement.padding) : 0,
                    overflow: 'hidden',
                    mx: 'auto'
                }}
                container
            >
                {
                    localElement && localElement.variant && localElement.content &&
                    <Typography
                        variant={localElement.variant !== 'richtext' ? localElement.variant : 'body2'}
                        sx={{
                            color: localElement.color ? localElement.color : colors.black,
                            textAlign: localElement.align ? localElement.align : 'left',
                            mt: !localElement.alignVertical ? 'auto' : localElement.alignVertical==='center' || localElement.alignVertical==='bottom' ? 'auto' : 0,
                            mb: !localElement.alignVertical ? 'auto' : localElement.alignVertical==='center' || localElement.alignVertical==='top' ? 'auto' : 0,
                            width: '100%'
                        }}
                        dangerouslySetInnerHTML={{ __html: localElement.content }}
                    ></Typography>
                }
            </Grid>
            {
                edit &&
                <Grid mt={3}>
                    <Typography variant='subtitle'>
                        Editar propriedades do texto
                    </Typography>
                    {
                        localElement && Object.keys(localElement).length > 0 &&
                        <>
                            {
                                suggestions && suggestions.length > 0 && localElement && localElement.variant &&
                                <Grid sx={{my: 1}}>
                                    <Typography>Utilizar valores da propriedade...</Typography>
                                    <Grid container direction="row" wrap="wrap">
                                        {
                                            suggestions.filter(s => localElement.variant==='richtext' || !s.richTextOnly).map(
                                                s =>
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant={localElement && localElement.content && localElement.content === s.value ? "contained" : "outlined"}
                                                        onClick={() => setLocalElement(olde => {return {...olde, content: s.value}})}
                                                        sx={{mr: 1, mb: 1}}
                                                    >{s.label}</Button>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <FormGroup
                                fields={localFormFields}
                                updateParams={(to_clean, id, val) => {
                                    setLocalElement({ ...localElement, [id]: val });
                                }}
                                initialVals={localElement}
                                sx={{ width: '100%' }}
                            />
                            <PropertySheetElementsGlobalForm
                                element={localElement}
                                setElement={setLocalElement}
                                totalCols={totalCols}
                                elementOriginal={element}
                            />
                        </>
                    }
                    {
                        localElement && localElement.content && // TODO! Add validations
                        <Button color="primary" variant="contained" sx={{ width: '100%', mt: 1 }} onClick={() => setElement(localElement)}>
                            {!element ? "Adicionar texto": "Editar texto"}
                        </Button>
                    }
                </Grid>
            }
        </>
    );
}

export default PropertySheetElementText;