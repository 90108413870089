import React, { useState, useEffect } from "react";

import {
    Grid,
    Drawer,
    IconButton,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Link
} from '@mui/material';

import {
    Inbox as InboxIcon,
    Mail as MailIcon,
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';

import './index.css';

import { getMenu } from './menu';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Sidebar = ({ open, setOpen }) => {

    return (
        <Grid
            sx={{ width: open ? 'auto' : 0 }}
        >
            <Drawer
                variant="persistent"
                open={open}
                sx={{ height: '100%' }}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(old => !old)}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                {getMenu().map(menuGroup => (
                    <>
                        <Divider textAlign="left">{menuGroup.label && menuGroup.label}</Divider>
                        <List>
                            {
                                menuGroup.menu.map(m => 
                                    <Link
                                        href={m.url}
                                        underline="none"
                                        color="black"
                                        target={m.newTab && "_blank"}
                                        
                                    >
                                        <ListItemButton
                                            key={m.label}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                                my: 2
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {m.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={m.label} sx={{ display: open ? 'block' : 'none', my: 0 }} />
                                        </ListItemButton>
                                    </Link>
                                )
                            }
                        </List>
                    </>
                ))}
            </Drawer>
        </Grid>
    );
}

export default Sidebar;