import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    Avatar,
    Badge,
    Link,
    Tooltip
} from '@mui/material';

import {
    Event as EventIcon,
    StickyNote2 as StickyNote2Icon,
    Summarize as SummarizeIcon,
    Edit as EditIcon
} from '@mui/icons-material';

import ChaveLusaDialog from "../../../../Chavelusa/Dialog";
import FormGroup from "../../../../Chavelusa/FormGroup";
import Stepper from "../../../../Chavelusa/Stepper";

import {
    is_agent,
    canWriteSensitiveData
} from '../../../../../../Services/permissions';

import {
    DateHelper,
    FileHelper,
    requestWithBody,
    handleRequestError
} from '../../../../../utils';

// File upload
import FilesUploader from '../../../../Chavelusa/FilesUploader';

import {
    buildFilterText,
    buildFilterDateTime,
} from '../../../../Chavelusa/FormGroup/utils';

const fieldsGeneral = [
    buildFilterDateTime('Data e hora', 'moment', true, true, true),
    buildFilterText('Notas', 'notes', false, null, undefined, 'text', true, 3),
];

const ClientClassificationPropertyVisits = ({visits, addNew, addNewCancel, updateVisit, classification, clientProperty, disabled}) => {

    console.log("ClientClassificationPropertyVisits", visits);

    // Visit entity to edit in form (when set, visit edit form is openned)
    const [scheduleVisit, setScheduleVisit] = useState(undefined); 
    useEffect(() => {
        // When addNew changes to true, set scheduleVisit to null, because won't edit anything
        addNew && setScheduleVisit(undefined);
    }, [addNew]);

    const visitNameReadable = (v) => {
        // Visit files in server are saved with prefix that must not be shown in the UI (_visit is old sintax, _v i new)
        return FileHelper.getFileNameIgnoreId(FileHelper.getFileNameIgnoreId(v.document, `_v${v.id}_`), `_visit${v.id}_`)
    }
    const visitFileUrl = (v) => {
        // On PATCH requests, the file URL does not start with the server name, so we need to add it 
        return v.document.indexOf("http")>=0 ? v.document : process.env.REACT_APP_SERVER_URL + v.document;
    }

    // When scheduleVisit changes, update url
    // Form fields are distinct if creating or editting (file upload only available in the last one)
    const [url, setUrl] = useState("");
    const [initialFiles, setInitialFiles] = useState([]);
    useEffect(() => {
        if (scheduleVisit) {
            setUrl(process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/visits/' + scheduleVisit.id + '/');
            if (scheduleVisit.document) {
                setInitialFiles([{label: visitNameReadable(scheduleVisit), url: visitFileUrl(scheduleVisit)}]);
            } else {
                setInitialFiles([]);
            }
        } else {
            setUrl(process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/visits/');
            setInitialFiles([]);
        }

        fieldsGeneral[0].disabled = scheduleVisit && scheduleVisit.moment && !canWriteSensitiveData;
    }, [scheduleVisit]);


    // API Calls
    const [error , setError] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const submitVisit = (data, del=false) => {
        console.log("SUBMIT FILE", data);
        setSubmitting(true);

        let api_data = null;
        if (!del)
            api_data = {
                clientproperty: clientProperty,
                moment: data.moment,
                notes: data.notes
            }

        requestWithBody(
            scheduleVisit ? (del ? "DELETE" : "PATCH") : "POST",
            url,
            api_data
        ).then(response => {
            console.log(
                scheduleVisit ? (del ? "Visit deleted with success!" : "Visit updated with success!") : "Visit created with success!", 
                response
            );
            if (del) {
                updateVisit(scheduleVisit, del);
            } else {
                setScheduleVisit(response);
                setStepperGoBackToMenuFlag(old => !old);
            }
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                scheduleVisit ? (del ? "Error deleting Visit!" : "Error updating Visit!") : "Error creating Visit!"
            )
            .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    const [visitHover, setVisitHover] = useState(undefined);

    // Stepper properties
    const [stepperGoBackToMenuFlag, setStepperGoBackToMenuFlag] = useState(true);
    let steps = [
        {
            label: 'Dados da visita',
            component: (<FormGroup
                fields={fieldsGeneral}
                onSubmit={submitVisit}
                initialVals={scheduleVisit ? scheduleVisit : {}}
                
                disabled={submitting}
                errors={error}
                setErrors={setError}
                
                sx={{width: '100%'}}
            />)
        },
        {
            label: 'Ficha de visita',
            component: (<FilesUploader 
                id={'document'}
                url={url}
                filesAttr={'document'}
                
                initialFiles={initialFiles}
                maxFiles={1}
                update={(v) => {
                    setScheduleVisit(v);
                    setStepperGoBackToMenuFlag(old => !old);
                }}
                allowDelete={canWriteSensitiveData}
            />)
        }
    ];

    // Render
    return (
        <Grid p={3}>
            <Typography variant='h6'>Visitas</Typography>
            {
                visits.sort((a, b) => new Date(a.moment) > new Date(b.moment)).map(v => // Ordered from oldest to newest
                    {
                        // How many days in the future?
                        const days_left = DateHelper.diff_days(new Date(v.moment), new Date());
                        const hours_left = DateHelper.diff_hours(new Date(v.moment), new Date());
                        const minutes_left = DateHelper.diff_minutes(new Date(v.moment), new Date());
                        return (
                                <Grid container my={1} direction="row" wrap="nowrap">
                                    {
                                        disabled || (!is_agent && !canWriteSensitiveData)
                                        ?
                                        <Avatar 
                                            sx={{bgcolor: 'primary', mr:1}}
                                        >
                                            <EventIcon/>
                                        </Avatar>
                                        :
                                        <Tooltip title="Editar" placement="left">
                                            <Avatar 
                                                sx={{bgcolor: 'primary', mr:1, cursor: 'pointer'}}
                                                onMouseEnter={() => setVisitHover(v.id)}
                                                onMouseLeave={() => setVisitHover(undefined)}
                                                onClick={() => setScheduleVisit(v)}
                                            >
                                                <EventIcon sx={{display: visitHover && visitHover===v.id ? 'none' : 'block'}}/>
                                                <EditIcon sx={{display: visitHover && visitHover===v.id ? 'block' : 'none'}}/>
                                            </Avatar>
                                        </Tooltip>
                                    }

                                    <Grid>
                                        {
                                            days_left>=0 
                                            ? <Badge 
                                                badgeContent={`Faltam ${days_left ? days_left : hours_left ? hours_left : minutes_left} ${days_left ? 'dias' : hours_left ? 'horas' : 'minutos'}`} 
                                                color={'primary'}
                                                className={hours_left<24 && 'animate animate__pulse'}
                                            >
                                                <Typography variant='subtitle'>{new Date(v.moment).toLocaleDateString('pt-PT', DateHelper.format_date_time)}</Typography>
                                            </Badge>
                                            : <Typography variant='subtitle'>{new Date(v.moment).toLocaleDateString('pt-PT', DateHelper.format_date_time)}</Typography>
                                        }
                                        {
                                            v.document &&
                                            <Link href={visitFileUrl(v)} underline={'none'} target={'_blank'}>
                                                <Typography>
                                                    <SummarizeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                                                    Anexo ({visitNameReadable(v)})
                                                </Typography>
                                            </Link>
                                        }
                                        {
                                            v.notes && 
                                            <Typography>
                                                <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                                                {v.notes}
                                            </Typography>
                                        }
                                    </Grid>

                                </Grid>

                        );
                    }
                )
            }
            <ChaveLusaDialog
                open={addNew || scheduleVisit}
                className='dont-hover'
            >
                <Stepper
                    steps={steps}
                    title={addNew ? 'Agendar visita' : 'Editar visita'}

                    any={!!scheduleVisit}
                    activeStepsInitial={[0]}
                    completed={(scheduleVisit && (() => {
                        let done = [0];
                        scheduleVisit && scheduleVisit.document && done.push(1);
                        return done;
                    })())}
                    goBack={() => {
                        addNewCancel();
                        scheduleVisit && updateVisit(scheduleVisit);
                        setScheduleVisit(undefined);
                    }}
                    onDelete={canWriteSensitiveData && scheduleVisit ? () => submitVisit(null, true) : undefined}
                    backToMenu={stepperGoBackToMenuFlag}
                />
            </ChaveLusaDialog>
        </Grid>
    );
}

export default ClientClassificationPropertyVisits;