import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    Avatar,
    Badge,
    Link,
    Tooltip
} from '@mui/material';

import {
    StickyNote2 as StickyNote2Icon,
    Summarize as SummarizeIcon,
    Edit as EditIcon
} from '@mui/icons-material';

import HandshakeIcon from '@mui/icons-material/Handshake';

import ChaveLusaDialog from "../../../../Chavelusa/Dialog";
import FormGroup from "../../../../Chavelusa/FormGroup";
import Stepper from "../../../../Chavelusa/Stepper";

import {
    is_agent,
    canWriteSensitiveData
} from '../../../../../../Services/permissions';

import {
    FileHelper,
    requestWithBody,
    handleRequestError
} from '../../../../../utils';

// File upload
import FilesUploader from '../../../../Chavelusa/FilesUploader';

import {
    buildFilterRadio,
    buildFilterText
} from '../../../../Chavelusa/FormGroup/utils';

const fieldsGeneral = [
    buildFilterRadio('Proponente', 'proponent', [
        {label: 'Comprador', id: 'buyer'},
        {label: 'Proprietário', id: 'owner'},
    ], true),
    buildFilterText('Valor', 'value', true, null, undefined, 'number'),
    buildFilterText('Notas', 'notes', false, null, undefined, 'text', true, 3),
];

const ClientClassificationPropertyProposals = ({proposals, addNew, addNewCancel, updateEntity, classification, clientProperty, disabled}) => {

    console.log("ClientClassificationPropertyProposals", proposals);

    // Visit entity to edit in form (when set, visit edit form is openned)
    const [entityEdit, setEntityEdit] = useState(undefined); 
    useEffect(() => {
        // When addNew changes to true, set entityEdit to null, because won't edit anything
        addNew && setEntityEdit(undefined);
    }, [addNew]);

    const fileNameReadable = (v) => {
        // Visit files in server are saved with prefix that must not be shown in the UI (_proposal is old sintax, _p i new)
        return FileHelper.getFileNameIgnoreId(FileHelper.getFileNameIgnoreId(v.document, `_p${v.id}_`), `_proposal${v.id}_`)
    }
    const fileUrl = (v) => {
        // On PATCH requests, the file URL does not start with the server name, so we need to add it 
        return v.document.indexOf("http")>=0 ? v.document : process.env.REACT_APP_SERVER_URL + v.document;
    }

    // When entityEdit changes, update url
    // Form fields are distinct if creating or editting (file upload only available in the last one)
    const [url, setUrl] = useState("");
    const [initialFiles, setInitialFiles] = useState([]);
    useEffect(() => {
        if (entityEdit) {
            setUrl(process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/proposals/' + entityEdit.id + '/');
            if (entityEdit.document) {
                setInitialFiles([{label: fileNameReadable(entityEdit), url: fileUrl(entityEdit)}]);
            } else {
                setInitialFiles([]);
            }
        } else {
            setUrl(process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/proposals/');
            setInitialFiles([]);
        }

        fieldsGeneral[0].disabled = entityEdit && !canWriteSensitiveData;
        fieldsGeneral[1].disabled = entityEdit && entityEdit.value && !canWriteSensitiveData;
    }, [entityEdit]);


    // API Calls
    const [error , setError] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const submitEntity = (data, del=false) => {
        console.log("SUBMIT FORM", data);
        setSubmitting(true);

        let api_data = null;
        if (!del)
            api_data = {
                clientproperty: clientProperty,
                owner: data.proponent==='owner',
                value: data.value,
                notes: data.notes
            }

        requestWithBody(
            entityEdit ? (del ? "DELETE" : "PATCH") : "POST",
            url,
            api_data
        ).then(response => {
            console.log(
                entityEdit ? (del ? "Proposal deleted with success!" : "Proposal updated with success!") : "Proposal created with success!", 
                response
            );
            if (del) {
                updateEntity(entityEdit, del);
            } else {
                setEntityEdit(response);
                setStepperGoBackToMenuFlag(old => !old);
            }
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                entityEdit ? (del ? "Error deleting Proposal!" : "Error updating Proposal!") : "Error creating Proposal!"
            )
            .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    const [visitHover, setVisitHover] = useState(undefined);

    // Stepper properties
    const [stepperGoBackToMenuFlag, setStepperGoBackToMenuFlag] = useState(true);
    let steps = [
        {
            label: 'Dados da proposta',
            component: (<FormGroup
                fields={fieldsGeneral}
                onSubmit={submitEntity}
                initialVals={entityEdit ? {...entityEdit, proponent: entityEdit.owner ? 'owner' : 'buyer'} : {}}
                
                disabled={submitting}
                errors={error}
                setErrors={setError}
                
                sx={{width: '100%'}}
            />)
        },
        {
            label: 'Ficha da proposta',
            component: (<FilesUploader 
                id={'document'}
                url={url}
                filesAttr={'document'}
                
                initialFiles={initialFiles}
                maxFiles={1}
                update={(v) => {
                    setEntityEdit(v);
                    setStepperGoBackToMenuFlag(old => !old);
                }}
                allowDelete={canWriteSensitiveData}
            />)
        }
    ];

    // Render
    return (
        <Grid p={3}>
            <Typography variant='h6'>Propostas</Typography>
            {
                proposals.sort((a, b) => new Date(a.moment) > new Date(b.moment)).map((v, i) => // Ordered from oldest to newest
                    (
                        <Grid container my={1} direction="row" wrap="nowrap">
                            {
                                disabled || (!is_agent && !canWriteSensitiveData)
                                ?
                                <Avatar 
                                    sx={{bgcolor: 'primary', mr:1}}
                                >
                                    <HandshakeIcon/>
                                </Avatar>
                                :
                                <Tooltip title="Editar" placement="left">
                                    <Avatar 
                                        sx={{bgcolor: 'primary', mr:1, cursor: 'pointer'}}
                                        onMouseEnter={() => setVisitHover(v.id)}
                                        onMouseLeave={() => setVisitHover(undefined)}
                                        onClick={() => setEntityEdit(v)}
                                    >
                                        <HandshakeIcon sx={{display: visitHover && visitHover===v.id ? 'none' : 'block'}}/>
                                        <EditIcon sx={{display: visitHover && visitHover===v.id ? 'block' : 'none'}}/>
                                    </Avatar>
                                </Tooltip>
                            }

                            <Grid>
                                <Badge 
                                    badgeContent={new Date(v.moment).toLocaleDateString("pt-PT")} 
                                    color={'primary'}
                                >
                                    <Typography variant='subtitle'>{v.value.toLocaleString()}€ pelo {v.owner ? 'Proprietário' : 'Comprador'}</Typography>
                                </Badge>
                                {
                                    v.document &&
                                    <Link href={fileUrl(v)} underline={'none'} target={'_blank'}>
                                        <Typography>
                                            <SummarizeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                                            Anexo ({fileNameReadable(v)})
                                        </Typography>
                                    </Link>
                                }
                                {
                                    v.notes && 
                                    <Typography>
                                        <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }}/> 
                                        {v.notes}
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                    )
                )
            }
            <ChaveLusaDialog
                open={addNew || entityEdit}
                className='dont-hover'
            >
                <Stepper
                    steps={steps}
                    title={addNew ? 'Nova proposta' : 'Editar proposta'}

                    any={!!entityEdit}
                    activeStepsInitial={[0]}
                    completed={(entityEdit && (() => {
                        let done = [0];
                        entityEdit && entityEdit.document && done.push(1);
                        return done;
                    })())}
                    goBack={() => {
                        addNewCancel();
                        entityEdit && updateEntity(entityEdit);
                        setEntityEdit(undefined);
                    }}
                    onDelete={canWriteSensitiveData && entityEdit ? () => submitEntity(null, true) : undefined}
                    backToMenu={stepperGoBackToMenuFlag}
                />
            </ChaveLusaDialog>
        </Grid>
    );
}

export default ClientClassificationPropertyProposals;