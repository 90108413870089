import React, { useState, useEffect } from "react";

import {
    Grid,
    Tooltip
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Alert from "../Alert";

/**
 * close: method triggered on close
 * align: left/right (default: right)
 * background: <color code> (default: #ffffff)
 * width: <string> (default: 50%)
 */

const Window = ({ children, close, align, background, width, hasChanged }) => {

    // Alert before closing when changed
    const [alert, setAlert] = useState(undefined);
    const ALERT_CONFIRM_CLOSE = {
        title: 'Quer mesmo fechar esta janela?',
        text: 'O conteúdo desta janela foi alterado sem ser guardado e vai ser perdido.',
        action: close
    };

    // Grow animation when rendered
    const [grow, setGrow] = useState(false);
    const [showContent, setShowContent] = useState(false);
    useEffect(() => {
        setGrow(true);
        // Wait for grow animation before showing content
        setTimeout(() => {
            setShowContent(true);
        }, 400);

        // returned function will be called on component unmount 
        return () => {
            setShowContent(false);
            setGrow(false);
        }
    }, []);

    return (
        <Grid
            sx={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                height: '100%',
                right: !align ? 0 : 'none',
                left: align == 'left' ? 0 : 'none',
                background: background ? background : '#ffffff',
                width: !grow ? '0' : width ? width : '50%',
                overflowY: 'scroll'
            }}
            p={3}
            mr={1}
            className="scalable"
            container
        >
            {
                showContent &&
                <Grid sx={{position: 'relative', width: '100%'}}>
                    <Tooltip title="Fechar">
                        <CloseIcon
                            className="clickable"
                            onClick={() => {
                                hasChanged ? setAlert(ALERT_CONFIRM_CLOSE) : close()
                            }}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                margin: 3
                            }}
                        />
                    </Tooltip>
                    {children}
                </Grid>
            }

            { /* Operations confirmation box */}
            {
                alert && 
                <Alert 
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default Window;