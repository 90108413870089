import React, { useState, useEffect } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

/**
 * Usage for confirmation operations
    {
        title: 'Ocorreu um erro',
        text: 'Por favor tente novamente e se o problema persistir entre em contacto com o suporte técnico',
        (Optional) action: () => { ... }
    }
 * Usage for error messages
    {
        error: true
    } 
 */
const Alert = ({ error, title, text, action, close }) => {

    const [open, setOpen] = useState(true);

    const handleClose = (success) => {
        setOpen(false);
        if (success && action)
            action();
        close();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {
                    (error && !title)
                    ? "Ocorreu um erro..."
                    : title
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        (error && !text)
                        ? "Por favor tente novamente e se o problema persistir entre em contacto com o suporte técnico."
                        : text
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    !error &&
                    <Button onClick={() => handleClose(false)}>
                        Cancelar
                    </Button>
                }
                <Button 
                    onClick={() => handleClose(true)} 
                    autoFocus
                >
                    Continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Alert;