/**
 * DATE filters
 */
export const buildDateFiltersSuggestions = (time) => { // TIME {start: <Str:ISODate>, to:end: <Str:ISODate>}
    var suggestions = [];
    var startDate = new Date(time.start);
    var endDate = new Date(time.end);

    // Last 7, 30 days
    var daysBetween = (endDate - startDate) / 24 * 60 * 60 * 1000;
    [7, 30].forEach(days => {
        if (daysBetween >= days) { 
            var from7DaysAgo = new Date(endDate);
            from7DaysAgo.setDate(from7DaysAgo.getDate() - (days-1));
            suggestions.push({
                label: 'Últimos ' + days + ' dias', 
                from: from7DaysAgo.toISOString(),
                to: time.end
            });
        }
    });
    // Last 3 natural months
    [1, 2, 3].forEach(months => {
        if (daysBetween>=30*months) { // Last month
            var lastMonthFirstDay = new Date(endDate);
            lastMonthFirstDay.setMonth(lastMonthFirstDay.getMonth()-months);
            lastMonthFirstDay.setDate(1);
            var lastMonthLastDay = new Date(lastMonthFirstDay.getFullYear(), lastMonthFirstDay.getMonth()+1, 0);
            var monthStr = lastMonthFirstDay.toLocaleString('pt-PT', { month: 'long' });
            suggestions.push({
                label: monthStr.charAt(0).toUpperCase() + monthStr.slice(1) + ' ' + lastMonthFirstDay.getFullYear(),
                from: lastMonthFirstDay.toISOString(),
                to: lastMonthLastDay.toISOString()
            });
        }
    });
    // Last 3 Quarters and 3 semesters
    [3, 6].forEach(interval => {
        const currentQuarter = Math.floor((endDate.getMonth() / interval));
        [0, 1, 2].forEach(quarter => {
            const startFullQuarter = new Date(endDate.getFullYear(), currentQuarter * interval - interval*quarter, 1);
            const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + interval, 0);
            if (endFullQuarter < startDate) return;
            const quarterComputed = Math.floor((startFullQuarter.getMonth() / interval));
            suggestions.push({
                label: (quarterComputed+1) + (interval===3 ? 'º trimestre ' : 'º semestre ') + startFullQuarter.getFullYear(),
                from: startFullQuarter.toISOString(),
                to: endFullQuarter.toISOString()
            })
        });
    });
    // Last and current year
    [0, 1, 2].forEach(years => {
        var lastYearFirstDay = new Date(endDate);
        lastYearFirstDay.setFullYear(lastYearFirstDay.getFullYear()-years);
        lastYearFirstDay.setDate(1);
        lastYearFirstDay.setMonth(0);
        var lastYearLastDay = new Date(lastYearFirstDay.getFullYear(), 12, 0);
        if (lastYearLastDay<startDate) return;
        suggestions.push({
            label: lastYearFirstDay.getFullYear(),
            from: lastYearFirstDay.toISOString(),
            to: lastYearLastDay.toISOString()
        });
    });
    suggestions.push({
        label: 'Sempre',
        from: time.start,
        to: time.end
    })
    return suggestions;
}