import React, { useState, useEffect } from "react";

import {
    Grid,
    TextField,
    Button
} from '@mui/material';

import { 
    requestWithBody,
    buildDictionaryForAttributesWithKey,
    handleRequestError
} from "../../../utils";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
 * Optional parameters...
 * @param {String} title Ex. "notas" (must start with lowercase!) 
 * @param {String} deactivated Deactivates edit functionality
 */
const FormNotes = ({ 
    noteOriginal, url, attribute, onUpdate, formChanged,
    // Optional vv
    title, deactivated
}) => {

    const [note, setNote] = useState(noteOriginal ? noteOriginal : "");
    const [changed, setChanged] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const updateNotes = () => {
        setErrorUpdate(false);
        let body = buildDictionaryForAttributesWithKey(attribute, note);
        console.log("Updating note with body...", body);
        setSubmitting(true);

        requestWithBody(
            "PATCH",
            url,
            body
        ).then(response => {
            console.log("Note updated with success", response);
            setChanged(false);
            if (onUpdate) {
                onUpdate(response);
            }
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                "Error updating note!",
            )
            .then(() => setErrorUpdate(true))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    return (
        <Grid
            sm={12}
            my={3}
            container
            className="form-notes"
        >
            <TextField
                sx={{
                    width: '100%',
                }}
                label={
                    !noteOriginal && note && changed ? (title ? "A adicionar " + title : "A adicionar notas")
                    : !noteOriginal ? (title ? "Clique e escreva para adicionar " + title : "Clique e escreva para adicionar notas") 
                    : noteOriginal && note && (noteOriginal===note || !changed) ? (title ? capitalizeFirstLetter(title) : "Notas")
                    : (title ? "A editar " + title : "A editar notas")
                }
                multiline
                minRows={1}
                value={note}
                onChange={(e) => {
                    setNote(e.target.value); 
                    setChanged(true); 
                    formChanged && formChanged();
                }}
                onClick={() => setChanged(true)}
                error={errorUpdate}
                helperText={errorUpdate && 'Ocorreu um erro a guardar as alterações, por favor tente novamente.'}
                disabled={deactivated}
            />

            {
                ((!noteOriginal && note && changed) || (noteOriginal && note!=noteOriginal && changed)) &&
                (
                    !submitting 
                    ?
                    <Button 
                        color="primary" 
                        variant="contained" 
                        sx={{mt: 1, ml: 'auto'}}
                        onClick={updateNotes}
                    >
                        {!noteOriginal ? 'Adicionar notas' : 'Guardar'}
                    </Button>
                    :
                    <LoadingButton
                        loading
                        color="primary" 
                        variant="contained" 
                        sx={{mt: 1, ml: 'auto'}}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                    >
                        {!noteOriginal ? 'Adicionar notas' : 'Guardar'}
                    </LoadingButton>
                )
            }
        </Grid>
    );
}

export default FormNotes;