import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip
} from '@mui/material';

import {
    Archive as ArchiveIcon,
    Person as PersonIcon,
    SupervisorAccount as SupervisorAccountIcon,
    AssignmentLate as AssignmentLateIcon,
    Chat as ChatIcon,
    Event as EventIcon
} from '@mui/icons-material';


import { is_agent } from "../../../../../Services/permissions";
import { DateHelper } from "../../../../utils";

/**
 * Banner for ClientAgent
 * 
 * @param clientAgent   {crm.api.serializers.ClientAgent.ClientAgentSerializer}
 * @param extraTags     {React component} (Optional)        Aditional tags to add to the default tags area
 * @param nextVisit     {String:Datetime field} (Optional)  If defined, a chip is added announcing next visit moment
 * @param hideTags      {Boolean} (Optional) If true, tags are now shown
 * */
const ClientAgentBanner = ({ clientAgent, extraTags, nextVisit, hideTags }) => {

    console.log("COMPONENT ClientAgentBanner", clientAgent);

    return (
        <Grid
            container
            direction="row"
            className={"elementSelectable"}
            my={4}
            onClick={() => {window.open('/clientes/' + clientAgent.id, '_blank')}}
        >
            {
                <Grid
                    xs={2}
                    m={0}
                    container
                >
                    <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                        <PersonIcon color={hideTags ? 'primary' : clientAgent.archived ? "secondary" : !clientAgent.classified ? "warning" : "primary"} fontSize="inherit"/> 
                    </Typography>
                </Grid>
            }
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>{clientAgent.client.name}</Typography>
                <Grid container>
                    {
                        !is_agent && 
                        <Typography variant="caption" mr={2}>
                            <Tooltip title="Agente">
                                <SupervisorAccountIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                            </Tooltip>
                            {clientAgent.agent.name}
                        </Typography>
                    }
                    {
                        extraTags && extraTags
                    }
                </Grid>
                {
                    !hideTags && 
                    <Grid mt={3} direction={'column'} container>
                        {
                            nextVisit && 
                            <Chip
                                label={"Próxima visita a " + new Date(nextVisit).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                                icon={<EventIcon fontSize="small" />}
                                color="primary"
                                sx={{ mt: 1 }}
                            />
                        }
                        {
                            clientAgent.forms_pending &&
                            <Chip
                                label="Formulário de contacto pendente"
                                icon={<ChatIcon fontSize="small" />}
                                color="warning"
                                sx={{ mt: 1 }}
                            />
                        }
                        {
                            !clientAgent.classified &&
                            <Chip
                                label="Qualificação pendente"
                                icon={<AssignmentLateIcon fontSize="small" />}
                                color="warning"
                                sx={{ mt: 1 }}
                            />
                        }
                        {
                            clientAgent.archived &&
                            <Chip
                                label="Arquivado"
                                icon={<ArchiveIcon fontSize="small" />}
                                color="secondary"
                                sx={{ mt: 1 }}
                            />
                        }
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

export default ClientAgentBanner;