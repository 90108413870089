import React, { useState, useEffect } from "react";
import { Outlet } from 'react-router-dom';

import { 
    Grid,
    Typography
} from '@mui/material';

import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';

import '../MainLayout/index.css';

const CleanLayout = () => {

    return (
        <Grid 
            container 
            direction="row"
            sx={{flexGrow: 1}}
            wrap="nowrap"
        >
            <Grid 
                container 
                direction="column"
                sx={{flexGrow: 1}}
                wrap="nowrap"
            >
                <Grid
                    sx={{display: {xs: 'none', md: 'block', flexGrow: 1}}}
                    container
                >
                    <Outlet/>
                </Grid>
                <Grid
                    sx={{
                        p: 5, 
                        display: {xs: 'flex', md: 'none'}, 
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <PhonelinkOffIcon fontsize="large" />
                    <Typography variant="h6" align="center">
                        Versão mobile indisponível
                    </Typography>
                    <Typography variant="body2" align="center">
                        Estamos a trabalhar no desenvolvimento da versão mobile do painel de administração. Será contactado quando estiver disponível.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CleanLayout;