import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Chip,
    Tooltip,
    CircularProgress
} from '@mui/material';

import {
    requestGET
} from '../../../../utils';

const Message = ({html, subject, sender, recipients, sent, model, reference, id}) => {



    const [loading, setLoading] = useState(true);
    const [localHtml, setLocalHtml] = useState(undefined);

    useEffect(() => {
        // If html provided, show html
        if (html) {
            setLocalHtml(html);
            setLoading(false);
            return;
        }
        // Else, if model and ref, load message from API
        if (model && reference && id) {
            getMessage();
            return;
        }
        setLocalHtml("<h1>Ocorreu um erro ao gerar a previsualização</h1>");
    }, [html, model, reference]);

    const getMessage = () => {
        console.log("getMessage", id, reference, model);
        requestGET(process.env.REACT_APP_API_URL + '/backoffice/messages/' + id +'/?model=' + model + '&ref=' + reference)
        .then(response => {
            console.log("GOT message", response);
            setLocalHtml(response['html']);
            setLoading(false);
        })
    }

    return (
        <Grid sx={{height: '100%', width: '100%'}} container direction="column">
            {
                subject &&
                <Grid container mb={1}>
                    <Typography sx={{ mr: 1 }}>Assunto:</Typography>
                    <Typography sx={{fontWeight: 'bold'}}>{subject}</Typography>
                </Grid>
            }
            {
                sender &&
                <Grid container mb={1}>
                    <Typography sx={{ mr: 1 }}>De:</Typography>
                    <Tooltip title={sender.contact}><Chip label={sender.name} variant="outlined" /></Tooltip>
                </Grid>
            }
            {
                recipients &&
                <Grid container mb={1}>
                    <Typography sx={{ mr: 1 }}>Para:</Typography>
                    {
                        recipients.map(r =>
                            <Tooltip title={r.contact}><Chip label={r.name} variant="outlined" /></Tooltip>
                        )
                    }
                </Grid>
            }
            {
                sent &&
                <Grid container mb={1}>
                    <Typography>{sent}</Typography>
                </Grid>
            }
            <Grid container sx={{flexGrow: 1}}>
                {
                    !loading && localHtml
                    ?
                    <iframe
                        srcdoc={localHtml}
                        frameborder={0}
                        height={'100%'}
                        width={'100%'}
                    ></iframe>
                    : 
                    <CircularProgress sx={{ my: 'auto', mx: 'auto' }} />
                }
            </Grid>
        </Grid>
    );
}

export default Message;