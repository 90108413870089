import './App.css';
import 'intro.js/introjs.css';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

import { colors } from './Views/utils';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, far, fas);



// Theme customization
// https://mui.com/pt/styles/basics/#using-the-theme-context
const theme = createTheme({
  palette: {
    background: {
      default: colors.background
    },
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tv: 2500
    },
  },
});

function App() {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {routing}
      </div>
    </ThemeProvider>
  );
}

export default App;
