/**
 * This method changes the iframe top window url
 * @param {*} event 
 * @param {*} slug 
 * @param {*} id 
 */
export const openChaveLusaAdmin = (event, slug, id = null, extra = '', newTab = false) => {
    if (event)
        event.preventDefault();

    let url = "";

    if (id) {
        url = process.env.REACT_APP_ADMIN_URL + slug + id + extra;
    } else {
        url = process.env.REACT_APP_ADMIN_URL + slug + extra;
    }

    if (!newTab) {
        window.top.window.location.href = url;
    } else {
        window.open(url, '_blank').focus();
    }
}

/**
 * Builds an url parameter String given an object of parameters
 * @param {Object} params An object with key: value, both String/Number values 
 * @param {Array} list of parameters to ignore when building params
 * @returns {String} paramString The parameter String
 */
export const buildParams = (params, ignoreParams=[]) => {
    let paramString = '?';
    for (const [key, value] of Object.entries(params)) {
        if (value && !ignoreParams.includes(key)) {
            paramString += `${key}=${value}&`;
        }
    }
    return paramString;
}

/**
 * Evaluates a request response and throws an error if it is not success
 * If success, returns json response
 * @param {} response 
 * @returns 
 */
export const getJSONResponse = (response, redirectToLogin = false) => {
    if (response.status >= 200 && response.status <= 299) {
        if (response.headers && response.headers.get('content-type') === 'application/json')
            return response.json();
        return null;
    } else if (response.status === 403 && redirectToLogin) {
        // If user is not logged, redirect to login page
        console.error("User does not have persmissions, redirecting to login...", response);
        redirectToLoginLocal();
        throw response.json();
    } else {
        console.error("Error getting JSON response", response);
        throw response.json();
        // This is a promise, use .then() to wait for the value 
        // ATTENTION! The error should be processed with utils#handleRequestError method!
    }
}

const redirectToLoginLocal = () => {
    window.location.href = process.env.REACT_APP_LOGIN_URL + '?next=' + window.location.href;
}

/**
 * Make get request
 */
export const requestGET = (url, signal = null, redirectToLogin = undefined) => {
    return fetch(url, {
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'pt-PT'
        },
        signal: signal
    }).then((r) => getJSONResponse(r, redirectToLogin));
}

/**
 * Make request with body
 */
export const requestWithBody = (method, url, body, signal = null) => {
    return fetch(url, {
        credentials: 'include',
        method: method,
        headers: {
            'X-CSRFTOKEN': getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'pt-PT'
        },
        signal: signal,
        body: JSON.stringify(body)
    }).then(getJSONResponse);
}

/**
 * Make request with body
 */
export const requestFile = (method, url, body) => {
    return fetch(url, {
        credentials: 'include',
        method: method,
        headers: {
            'X-CSRFTOKEN': getCookie('csrftoken'),
            'Accept': '*/*',
            // 'Content-Type': undefined,
            'Accept-Language': 'pt-PT'
        },
        body: body
    }).then(getJSONResponse);
}

/**
 * Handles request error and return error object with expected error structure for FormGroup
 * 
 * @param {Object} error The error passed to the fetch#catch method, to be processed
 * @param {Array} (Optional) fieldsIds If fetch was for a form with fields, pass the list of ids that can have an error
 * @param {String} (Optional) msgLog If defined, the error messages will be logged in the console
 * @param {Method} (Optional) customErrorEvaluator(errorBody) 
 *          When error decoded to JSON, if does not have attr error or fieldIds, this method is called with the 
 *          JSON that can search the error for custom error types and return its own error object.
 *          If returns null, the UNKNOWN error object ir returned.
 * 
 * Example usages: 
    .catch(error => 
        handleRequestError(
            error, 
            [], 
            "Error getting client forms",
        )
    )
    .catch(error => 
        handleRequestError(
            error, 
            fields.map(f => f.id), 
            "Error at qualification form locations",
            (e) => {return {if() {'error': MYCUSTOMERROR, <my custom error attrs>}; return null;}}
        )
        .then(e => setError(e))
    )
 */
export const handleRequestError = (error, fieldsIds = [], msgLog = undefined, customErrorEvaluator = undefined) => {
    msgLog && console.error(msgLog + " / START", error);
    if (!(error instanceof Promise)) {
        msgLog && console.error(msgLog + " / ERROR NOT PROMISE");
        return { 'error': 'UNKNOWN' };
    }
    return error.then(errorBody => {
        msgLog && console.error(msgLog + " / JSON", errorBody);
        if ('error' in errorBody || fieldsIds.some(fid => fid in errorBody)) {
            return errorBody;
        }
        if (customErrorEvaluator && customErrorEvaluator(error)) {
            return customErrorEvaluator(error);
        }
        return { ...errorBody, 'error': 'UNKNOWN' };
    }).catch((e) => {
        msgLog && console.error(msgLog + " / CATCH", e);
        return { 'error': 'UNKNOWN' };
    });
}

/**
 * Get cookie by name
 */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

/**
 * Given a strinbg with attributes divided by dots (.), create dictionary with structure and assign the value
 */
export const buildDictionaryForAttributesWithKey = (attributes, key) => {
    console.log("buildDictionaryForAttributesWithKey", attributes, key);
    const attrs = attributes.split('.');
    if (attrs.length > 1) {
        return { [attrs[0]]: buildDictionaryForAttributesWithKey(attrs.slice(1, attrs.length).join('.'), key) };
    } else if (attrs.length == 1) {
        return { [attrs[0]]: key };
    }
    return {};
}

/**
 * Given a big string, reduce it to X characters
 */
export const reduceString = (str, maxChars) => {
    if (!str) {
        return "";
    }
    if (str.length <= maxChars) {
        return str;
    }
    return str.slice(0, maxChars) + '...';
}

/**
 * Date formatting
 * 
 * Usage: new Date(<dateVar>).toLocaleDateString('pt-PT', options) 
 * Replace options with one of the variables exported below
 * 
 * Example output:
 * date_format_date_time: 7 de setembro de 2021, 20:49
 * date_format_date: 7 de setembro de 2021
 */
export const DateHelper = {
    format_date_time: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    format_date: { year: 'numeric', month: 'long', day: 'numeric' },
    format_hour: { hour: 'numeric', minute: 'numeric' }, // Use toLocaleTimeString instead
    diff_days: (date1, date2) => {
        return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
    },
    diff_hours: (date1, date2) => {
        return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60 * 60));
    },
    diff_minutes: (date1, date2) => {
        return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60));
    },
    datesAreOnSameDay: (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
}


/**
 * JS extenders
 */
export const JSExtender = {
    hasSomeParentTheClass: (element, classname) => {
        if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && JSExtender.hasSomeParentTheClass(element.parentNode, classname);
    }
}

/**
 * FILE HELPER
 */
export const FileHelper = {
    getFileNameIgnoreId: (filename, idStr) => {
        // Remove id 
        if (filename.indexOf(idStr) >= 0)
            filename = filename.substring(filename.indexOf(idStr) + idStr.length, filename.length);
        // Remove URL from file name
        if (filename && filename.indexOf("/") >= 0) {
            var filename_strip = filename.split('/');
            filename = filename_strip[filename_strip.length - 1];
        }
        // Remove extension
        if (filename.split(".").length == 2)
            filename = filename.split(".")[0]
        // Replace separation symbols for spaces
        if (filename)
            filename = filename.replaceAll("-", " ").replaceAll("_", " ")
        return filename;
    }
}

/**
 * Useful variables
 */
export const production = getCookie('env') === 'PROD';
export const colors = {
    primary: !!getCookie('primary_color') ? getCookie('primary_color') : '#000000',
    secondary: '#6c757d',
    background: '#F8F8F8',

    pureWhite: '#ffffff',
    pureBlack: '#000000',
    white: '#f2f2f2',
    black: '#363636',
    gray: '#d9d9d9',
};