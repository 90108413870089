import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';

import FormGroup from '../../../../Chavelusa/FormGroup';
import FormGroupMultipleInline from "../../../../Chavelusa/FormGroupMultipleInline";

import {
    requestWithBody,
    requestGET,
    handleRequestError
} from "../../../../../utils";

import {
    buildFilterSelect
} from '../../../../Chavelusa/FormGroup/utils';

const fields_initial = [
    buildFilterSelect("Distrito", "select", "district", [], null, true),
    buildFilterSelect("Concelho", "select", "county", [], [{ id: 'district', values: ['*'] }], false),
    buildFilterSelect("Freguesia", "select", "parish", [], [{ id: 'district', values: ['*'] }, { id: 'county', values: ['*'] }], false)
];

const ClientClassificationFormLocations = ({ clientAgentId, classification, success, formChanged }) => {

    const url = process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgentId;

    // Form fields
    const [fields, setFields] = useState(
        !classification.locations.length
            ? []
            : classification.locations.map(location => {
                let fields_clone = fields_initial.map(fi => { return { ...fi }; });
                if (location.parish) {
                    fields_clone[0]['values'] = [{ id: location.parish.county.district.id, label: location.parish.county.district.name }];
                    fields_clone[1]['values'] = [{ id: location.parish.county.id, label: location.parish.county.name }];
                    fields_clone[2]['values'] = [{ id: location.parish.id, label: location.parish.name }];
                } else if (location.county) {
                    fields_clone[0]['values'] = [{ id: location.county.district.id, label: location.county.district.name }];
                    fields_clone[1]['values'] = [{ id: location.county.id, label: location.county.name }];
                } else if (location.district) {
                    fields_clone[0]['values'] = [{ id: location.district.id, label: location.district.name }];
                }
                return fields_clone;
            })
    );
    const [initialVals, setInitialVals] = useState([]);

    useEffect(() => {
        setInitialVals(
            !classification.locations.length
                ? []
                : classification.locations.map(
                    location => {
                        let location_val = {};
                        if (location.parish) {
                            location_val['parish'] = location.parish.id;
                            location_val['county'] = location.parish.county.id;
                            location_val['district'] = location.parish.county.district.id;
                        } else if (location.county) {
                            location_val['county'] = location.county.id;
                            location_val['district'] = location.county.district.id;
                        } else if (location.district) {
                            location_val['district'] = location.district.id;
                        }
                        return location_val;
                    }
                )
        );
    }, []);

    // Load values to populate form from API
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/realstate/districts/').then(
            response => {
                console.log("Got /realstate/districts/", response);
                fields_initial[0]['values'] = response.map(r => { return { label: r.name, id: r.id }; });
                if (!fields.length)
                    setFields([fields_initial]);
                setLoading(false);
            }
        );
    }, []);

    // Form dynamic 
    const addField = () => {
        // Clone fields objects to avoid conflicts wityh references
        setFields(f => [...f, fields_initial.map(fi => { return { ...fi }; })]);
    };

    // Form update
    const updateParams = (index, to_clean, id, val) => {
        formChanged();
        
        console.log("updateParams", index, to_clean, id, val);

        // When district updates, clean county and parish, update county
        if (id === 'district' && val) {
            setFields(f => {
                // Clone array, so that FormGroup can listen to props changes
                let f_copy = [...f];
                f_copy[index][1]['values'] = [];
                f_copy[index][2]['values'] = [];
                return f_copy;
            });

            requestGET(process.env.REACT_APP_API_URL + '/realstate/counties/?district=' + val).then(
                response => {
                    console.log("updateParams Got /realstate/counties/?district=" + val, response);
                    setFields(f => {
                        // Clone array, so that FormGroup can listen to props changes
                        let f_copy = [...f];
                        f_copy[index][1]['values'] = response.map(r => { return { label: r.name, id: r.id }; });
                        return f_copy;
                    });
                }
            );
        }

        // When county updates, clean parish, update parish
        if (id === 'county' && val) {
            setFields(f => {
                // Clone array, so that FormGroup can listen to props changes
                let f_copy = [...f];
                f_copy[index][2]['values'] = [];
                return f_copy;
            });

            requestGET(process.env.REACT_APP_API_URL + '/realstate/parishes/?county=' + val).then(
                response => {
                    console.log("updateParams Got /realstate/parishes/?county=" + val, response);
                    setFields(f => {
                        // Clone array, so that FormGroup can listen to props changes
                        let f_copy = [...f];
                        f_copy[index][2]['values'] = response.map(r => { return { label: r.name, id: r.id }; });
                        return f_copy;
                    });
                }
            );
        }
    }

    // Submit form
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const onSubmit = (vals) => {
        console.log("Submit qualification locations...", vals);
        setSubmitting(true);

        // Adapt model to what API expects
        let api_model = {
            districts_ids: vals.filter(v => 'district' in v && v['district'] && !('county' in v && v['county']) && !('parish' in v && v['parish'])).map(v => { return { id: v.district } }),
            counties_ids: vals.filter(v => 'county' in v && v['county'] && !('parish' in v && v['parish'])).map(v => { return { id: v.county } }),
            parishes_ids: vals.filter(v => 'parish' in v && v['parish']).map(v => { return { id: v.parish } })
        };

        console.log("Submit qualification locations restructured...", api_model);
        // PATCH to API
        requestWithBody(
            "PATCH",
            url,
            api_model
        ).then(response => {
            console.log(
                "Qualification (locations) updated with success!",
                response
            );
            success(response);
        }).catch(error => 
            handleRequestError(
                error, 
                fields[0].map(f => f.id), 
                "Error at qualification form locations"
            )
            .then(e => setError(e))
        )
        .finally(() => {
            setSubmitting(false);
        });
    }

    return (
        classification &&
        <Grid container direction="column">
            <Typography variant="h6" mb={0}>
                Localizações
            </Typography>
            {
                !loading &&
                <FormGroupMultipleInline
                    fields={fields}
                    updateParams={updateParams}
                    onSubmit={onSubmit}
                    disabled={submitting}
                    errors={error}
                    setErrors={setError}
                    initialVals={initialVals}
                    sx={{
                        minWidth: '30%',
                        maxWidth: '30%',
                        mr: 1,
                        my: 1
                    }}

                    addField={addField}
                    allowDelete={true}
                />
            }
            {
                loading && <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
        </Grid>
    );
}

export default ClientClassificationFormLocations;