import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import {
    Home as HomeIcon,
    LocationOn as LocationOnIcon,
    AttachFile as AttachFileIcon,
    SquareFoot as SquareFootIcon,
    Roofing as RoofingIcon,
    KingBed as KingBedIcon,
    Shower as ShowerIcon,
    Garage as GarageIcon
} from '@mui/icons-material';

import FormGroup from "../../../../../Chavelusa/FormGroup";
import PropertySheetElementsGlobalForm from "../GlobalForm";

import { buildFilterText, buildFilterSwitch, buildFilterSelect } from "../../../../../Chavelusa/FormGroup/utils";
import { colors } from "../../../../../../utils";
import { TYPE_NUMBERS } from '../../enum';

/**
 * NUMBERS ELEMENT COMPONENT 
 * 
 * Element structure:
 * COMMON (refer to GlobalForm for complete structure)
 * - type: str                  NUMBERS
 * SPECIFIC
 * - ref: int                   Property id
 * - area: int              
 * - areaconstructed: int        
 * - bedrooms: int
 * - bathrooms: int
 * - garage: boolean
 * 
 * - direction: str             (Optional, default row) Flex direction
 */

const formFields = [
    buildFilterText('Referência', 'ref'),
    buildFilterText('Área do lote', 'area'),
    buildFilterText('Área útil', 'areaconstructed'),
    buildFilterText('Quartos', 'bedrooms'),
    buildFilterText('Casas de banho', 'bathrooms'),
    buildFilterSwitch('Garagem', 'garage', false),
    buildFilterSelect('Direção', 'select', 'direction', [
        {label: 'Em linha', id:'row'},
        {label: 'Em coluna', id:'column'}
    ])
];
const PropertySheetElementNumbers = ({ element, setElement, property, totalCols }) => {

    const edit = !!setElement;

    console.log("PropertySheetElementNumbers", element, edit);

    const [localElement, setLocalElement] = useState({});

    useEffect(() => {
        let localCandidate = element ? { ...element } : {};
        // Default properties
        if (!('height' in localCandidate)) localCandidate['height'] = 10;
        if (!('cols' in localCandidate)) localCandidate['cols'] = 12;
        if (!('type' in localCandidate)) localCandidate['type'] = TYPE_NUMBERS;
        if (!('direction' in localCandidate)) localCandidate['direction'] = 'row';
        if (property) {
            if (!('ref' in localCandidate)) localCandidate['ref'] = property.id;
            if (!('area' in localCandidate)) localCandidate['area'] = property.area;
            if (!('areaconstructed' in localCandidate)) localCandidate['areaconstructed'] = property.areaconstructed;
            if (!('bedrooms' in localCandidate)) localCandidate['bedrooms'] = property.bedrooms;
            if (!('bathrooms' in localCandidate)) localCandidate['bathrooms'] = property.bathrooms;
            if (!('garage' in localCandidate)) localCandidate['garage'] = property.feature.some(f => f.name.includes("Garagem"));
        }
        setLocalElement(localCandidate);
    }, [element]);

    useEffect(() => {
        console.log("PropertySheetElementNumbers localElement", localElement);
    }, [localElement]);

    return (
        <Grid
            xs={12}
            sx={edit ? {} : {
                height: '100%'
            }}
        >
            {
                edit &&
                <Typography variant='subtitle'>
                    Pré-visualização
                </Typography>
            }
            <Grid
                xs={edit ? (localElement.cols ? localElement.cols : 12) : 12}
                sx={!edit ? {
                    height: '100%',
                } : {
                    height: localElement.height ? localElement.height + 'vh' : '',
                    border: '1px dashed ' + colors.primary,
                    overflow: 'hidden',
                    mx: 'auto'
                }}
                container
                direction={localElement.direction ? localElement.direction : "row"}
                wrap="wrap"
                alignItems='center'
                justifyContent='center'
            >
                {
                    localElement.ref &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { width: '100%', alignContent: 'center' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><HomeIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /> Ref.</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>{localElement.ref}</Typography>
                    </Grid>
                }
                {
                    localElement.area &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { mx: 'auto', width: '100%' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><SquareFootIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /> Lote (m²)</Typography>
                        <Typography variant="subtitle1">{localElement.area}</Typography>
                    </Grid>
                }
                {
                    localElement.areaconstructed &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { mx: 'auto', width: '100%' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><RoofingIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /> Útil (m²)</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>{localElement.areaconstructed}</Typography>
                    </Grid>
                }
                {
                    localElement.bedrooms &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { mx: 'auto', width: '100%' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><KingBedIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /> Quartos</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>{localElement.bedrooms}</Typography>
                    </Grid>
                }
                {
                    localElement.bathrooms &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { mx: 'auto', width: '100%' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><ShowerIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /> WCs</Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>{localElement.bathrooms}</Typography>
                    </Grid>
                }
                {
                    localElement.garage &&
                    <Grid container sx={localElement.direction && localElement.direction==='row' ? { mx: 1, width: 'auto' } : { mx: 'auto', width: '100%' }} alignItems="flex-end">
                        <Typography variant="caption" sx={{ mr: 1, mb: .5 }}><GarageIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: colors.primary }} /></Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>Garagem</Typography>
                    </Grid>
                }
            </Grid>
            {
                edit &&
                <>
                    <Grid mt={3}>
                        <Typography variant='subtitle'>
                            Editar números
                        </Typography>
                        {
                            localElement && Object.keys(localElement).length > 0 &&
                            <>
                                <FormGroup
                                    fields={formFields}
                                    updateParams={(to_clean, id, val) => {
                                        setLocalElement({ ...localElement, [id]: val });
                                    }}
                                    initialVals={localElement}
                                    sx={{ width: '100%' }}
                                />
                                <PropertySheetElementsGlobalForm
                                    element={localElement}
                                    setElement={setLocalElement}
                                    totalCols={totalCols}
                                    elementOriginal={element}
                                />
                            </>
                        }
                        {
                            localElement &&
                            <Button color="primary" variant="contained" sx={{ width: '100%', mt: 1 }} onClick={() => setElement(localElement)}>
                                {!element ? "Adicionar números" : "Editar números"}
                            </Button>
                        }
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default PropertySheetElementNumbers;