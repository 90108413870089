export const tutorial = [
    {
        element: '.client-header',
        intro: 'Na parte superior da página pode consultar os dados do cliente, assim como as suas etiquetas de estado.'
    },
    {
        element: '.client-header .operations',
        intro: 'Nesta secção pode alterar os dados e estado do cliente.'
    },
    {
        element: '.client-header .form-notes',
        intro: 'Utilize este campo para fazer anotações. O que escrever aqui será visível na listagem dos clientes para ajudar a encontrar o cliente que procura.'
    },
    {
        element: '.client-feed',
        intro: 'No feed é onde poderá ver toda a atividade do cliente.'
    },
    {
        element: '.client-feed .client-feed-tabs',
        intro: 'No seperador FEED vai ver todos os formulários de contacto, qualificações, propriedades e mensagens ordenados por ordem cronológica (os alterados mais recentemente primeiro). Pode, no entanto, utilizar os separadores à direita para consultar as categorias específicas.'
    },
    {
        element: '.client-feed .client-feed-operations',
        intro: 'Quando o separador selecionado suportar, verá nesta secção as operações disponíveis para a categoria selecionada.'
    },
    {
        element: '.client-feed .client-feed-cards',
        intro: 'Por fim, aqui verá os cartões com a atividade do seu cliente. Clique sobre cada cartão para consultar os detalhes e realizar operações sobre eles.'
    },
];