import React, { useState } from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid
} from '@material-ui/core';

/**
 * A modal for user to download files
 * 
 * @param {Boolean} open Show modal?
 * @param {method} handleClose Called to close modal
 * @param {String} title Title of file to display with modal title
 * @param {String} description Text that describes the file being downloaded
 * @param {String} url Link to download the file from  
 */
const DownloadModal = ({ open, handleClose, title, description, url }) => {

    // State: initial > downloading
    const [state, setState] = useState('initial');

    const closeModal = () => {
        // Reset internal state
        setState('initial');
        // Propagate closing to upper component
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={closeModal}
        >
            <DialogTitle>
                {state === 'initial' && `Exportar ${title}`}
                {state === 'downloading' && `A descarregar...`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText dangerouslySetInnerHTML={{ __html: 
                    state === 'initial' ? description
                    : state === 'downloading' ? `O download deve começar dentro de segundos. Se não começar, clique em tentar novamente.`
                    : ''
                }}>
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{flexWrap: 'wrap'}}>
                <Button 
                    variant={state === 'initial' ? 'contained' : 'outlined'} 
                    href={url} 
                    target='_blank' 
                    sx={{ 
                        my: {xs: 'auto', sm: 0},
                        mr: {xs: 'auto', sm: 1},
                        width: {xs: '100%', sm: 'auto'},
                        mb: {xs: 1, sm:0}
                    }} 
                    onClick={() => setState('downloading')}
                >
                    {state === 'initial' && 'Descarregar ficheiro'}
                    {state === 'downloading' && 'Tentar novamente'}
                </Button>
                <Button 
                    variant={state === 'initial' ? 'outlined' : 'contained'} 
                    onClick={closeModal} 
                    autoFocus
                    sx={{width: {xs: '100%', sm: 'auto'}}}
                >
                    {state === 'initial' && 'Cancelar'}
                    {state === 'downloading' && 'Feito'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadModal;
