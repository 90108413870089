import React, { useState, useEffect, useRef } from "react";

import {
    Grid,
    Typography,
    Button,
    Tooltip,
    Alert,
    IconButton
} from '@mui/material';

import {
    AddCircle as AddCircleIcon,
    RemoveCircle as RemoveCircleIcon,
    Edit as EditIcon,
    ArrowCircleUp as ArrowCircleUpIcon,
    ArrowCircleDown as ArrowCircleDownIcon,
    ArrowCircleLeft as ArrowCircleLeftIcon,
    ArrowCircleRight as ArrowCircleRightIcon
} from '@mui/icons-material';

import ReactToPrint from 'react-to-print';

import FormGroup from "../../../../Chavelusa/FormGroup";
import { buildFilterRadio } from "../../../../Chavelusa/FormGroup/utils";

import PropertySheetElementEdit from "../Elements";
import PropertySheetElementImage from "../Elements/Image";
import PropertySheetElementText from "../Elements/Text";
import PropertySheetElementQRCode from "../Elements/QRCode";
import PropertySheetElementAgent from "../Elements/AgentCard";
import PropertySheetElementNumbers from "../Elements/Numbers";

import { colors } from "../../../../../utils";
import { TYPE_IMAGE, TYPE_TEXT, TYPE_QR, TYPE_AGENT, TYPE_NUMBERS } from '../enum';
import { PROPERTY_SHEETS_TEMPLATES } from "../templates";


const PropertySheetTemplateCreator = ({ property, propertyImages, close }) => {

    const ref = useRef();

    const [template, setTemplate] = useState([]); // List of lists (rows), each a list of objects (elements)
    const [editRow, setEditRow] = useState(undefined); // On hover, show edit buttons
    const [editCol, setEditCol] = useState(undefined); // On hover, show edit buttons
    const [editSheet, setEditSheet] = useState(undefined); // On hover, show edit buttons
    const [editElement, setEditElement] = useState(undefined); // keys: element, rowIndex, colIndex, totalCols
    const [pageOrientation, setPageOrientation] = useState('portrait');

    const [totalHeight, setTotalHeight] = useState(0);
    useEffect(() => {
        var updatedHeight = 0;
        template.forEach(row => {
            var maxHeight = 0;
            row.forEach(element => {
                if (element.height > maxHeight)
                    maxHeight = element.height;
            });
            updatedHeight += maxHeight;
        });
        setTotalHeight(updatedHeight);
    }, [template]);

    return (
        <Grid container direction="column">
            <Typography variant="h6" mb={3}>Gerar folha de propriedade</Typography>
            {
                template && template.length > 0 && template.some(r => r.length > 0)
                    ?
                    <>
                        <FormGroup
                            fields={[
                                buildFilterRadio('Orientação', 'pageOrientation', [
                                    { label: 'Vertical', id: 'portrait' },
                                    { label: 'Horizontal', id: 'landscape' }
                                ], true, true)
                            ]}
                            updateParams={(to_clean, id, val) => {
                                if (id !== 'pageOrientation') return;
                                setPageOrientation(val);
                            }}
                            initialVals={{ pageOrientation: pageOrientation }}
                            sx={{ width: '100%' }}
                        />
                        <ReactToPrint
                            trigger={() => { // https://www.npmjs.com/package/react-to-print
                                return (<Button
                                    color="primary"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                >
                                    Descarregar
                                </Button>);
                            }}
                            content={() => ref.current.children[0]}
                        />
                    </>
                    :
                    <Grid mb={3}>
                        <Typography variant='subtitle2'>Utilizar modelo</Typography>
                        <Grid container direction="row" wrap="wrap">
                            {
                                PROPERTY_SHEETS_TEMPLATES.map(t =>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!t.isValid(property, propertyImages)}
                                        onClick={() => {
                                            setTemplate(t.getTemplate(property, propertyImages));
                                            setPageOrientation(t.orientation);
                                        }}
                                        sx={{ mr: 1, mb: 1 }}
                                    >
                                        {t.label}
                                    </Button>
                                )
                            }
                        </Grid>
                    </Grid>
            }
            {
                totalHeight > 100 &&
                <Alert severity="warning" sx={{ mb: 3 }}>
                    As secções totalizam uma altura superior a 100%, pelo que o documento deverá ter mais do que 1 página.
                </Alert>
            }
            <Grid
                sx={{ border: '1px dashed', borderColor: colors.primary, p: 2, '@page': { size: pageOrientation } }} id="#propertySheetGrid" ref={ref}
            >
                <Grid
                    sx={{ height: '100%', width: '100%' }}
                    p={2}
                    onMouseEnter={() => setEditSheet(true)}
                    onMouseLeave={() => {
                        setEditSheet(false);
                        setEditRow(undefined);
                        setEditCol(undefined);
                    }}
                >
                    {/** SECTIONS */}
                    {
                        // ROW
                        template.map(
                            (row, i) => {
                                let totalCols = row.map(r => r.cols).filter(c => !!c).reduce((a, b) => a + b, 0);
                                return (
                                    <Grid container direction="row" wrap="nowrap"
                                        onMouseEnter={() => setEditRow(i)}
                                    >
                                        {
                                            // ELEMENT
                                            row.map(
                                                (element, elementI) =>
                                                    <Grid
                                                        sx={{
                                                            position: 'relative',
                                                            height: element.height && element.height + 'vh',
                                                            mt: !element.mt ? 0 : !isNaN(element.mt) ? parseInt(element.mt) : element.mt,
                                                            mb: !element.mb ? 0 : !isNaN(element.mb) ? parseInt(element.mb) : element.mb,
                                                            ml: !element.ml ? 0 : !isNaN(element.ml) ? parseInt(element.ml) : element.ml,
                                                            mr: !element.mr ? 0 : !isNaN(element.mr) ? parseInt(element.mr) : element.mr,
                                                            backgroundColor: element.backgroundColor ? element.backgroundColor : 'none',
                                                            padding: element.padding ? parseInt(element.padding) : 0,
                                                            overflow: 'hidden'
                                                        }}
                                                        xs={
                                                            // When editting element, make sure it has at least 3 cols, to allow user to see all operations available
                                                            editSheet && editRow === i && editCol === elementI ? Math.min(element.cols+2, 12) : element.cols
                                                        }
                                                        onMouseEnter={() => setEditCol(elementI)}
                                                    >
                                                        {
                                                            element.type === TYPE_IMAGE &&
                                                            <PropertySheetElementImage element={element} />
                                                        }
                                                        {
                                                            element.type === TYPE_TEXT &&
                                                            <PropertySheetElementText element={element} />
                                                        }
                                                        {
                                                            element.type === TYPE_QR &&
                                                            <PropertySheetElementQRCode element={element} />
                                                        }
                                                        {
                                                            element.type == TYPE_AGENT && 
                                                            <PropertySheetElementAgent element={element} />
                                                        }
                                                        {
                                                            element.type == TYPE_NUMBERS && 
                                                            <PropertySheetElementNumbers element={element} />
                                                        }
                                                        {
                                                            // ELEMENT OPERATIONS
                                                            editSheet && editRow === i && editCol === elementI &&
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                wrap="wrap"
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundColor: 'rgb(255,255,255,0.8)',
                                                                    p: 0,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Tooltip title="Editar elemento">
                                                                    <IconButton
                                                                        color="secondary"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={() => setEditElement({
                                                                            element: element,
                                                                            rowIndex: i,
                                                                            colIndex: elementI,
                                                                            totalCols: totalCols
                                                                        })}
                                                                    >
                                                                        <EditIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Eliminar elemento">
                                                                    <IconButton
                                                                        color="error"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={() => setTemplate(old => {
                                                                            var n = [...old];
                                                                            n[i].splice(elementI, 1);
                                                                            return n;
                                                                        })}
                                                                    >
                                                                        <RemoveCircleIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {
                                                                    row.length > 1 && elementI > 0 &&
                                                                    <Tooltip title="Mover para a esquerda">
                                                                        <IconButton
                                                                            color="secondary"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onClick={() => setTemplate(old => {
                                                                                var n = [...old];
                                                                                n[i].splice(elementI - 1, 0, n[i].splice(elementI, 1)[0]);
                                                                                return n;
                                                                            })}
                                                                        >
                                                                            <ArrowCircleLeftIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    row.length > 1 && elementI + 1 < row.length &&
                                                                    <Tooltip title="Mover para a direita">
                                                                        <IconButton
                                                                            color="secondary"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onClick={() => setTemplate(old => {
                                                                                var n = [...old];
                                                                                n[i].splice(elementI + 1, 0, n[i].splice(elementI, 1)[0]);
                                                                                return n;
                                                                            })}
                                                                        >
                                                                            <ArrowCircleRightIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </Grid>
                                                        }
                                                    </Grid>
                                            )
                                        }
                                        {
                                            // ROW OPERATIONS
                                            (editSheet && editRow === i || row && row.length === 0) && 
                                            <Grid
                                                my={1} container direction="row" wrap="nowrap"
                                                sx={{
                                                    flexGrow: row && row.length === 0 ? 1 : 0,
                                                    justifyContent: row && row.length === 0 ? 'center' : 'flex-end',
                                                    borderLeft: row && row.length === 0 ? '' : '1px solid ' + colors.primary,
                                                    marginLeft: row && row.length === 0 ? 0 : 'auto',
                                                    paddingLeft: row && row.length === 0 ? 0 : 1,
                                                    width: 'auto',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {
                                                    template.length > 1 && i > 0 && // When has content, allow to reorder
                                                    <Tooltip title="Mover para cima">
                                                        <IconButton
                                                            color="secondary"
                                                            variant="outlined"
                                                            ssize="medium"
                                                            onClick={() => setTemplate(oldT => {
                                                                var newT = [...oldT];
                                                                newT.splice(i - 1, 0, newT.splice(i, 1)[0]);
                                                                return newT;
                                                            })}
                                                        >
                                                            <ArrowCircleUpIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {
                                                    template.length > 1 && i + 1 < template.length && // When has content, allow to reorder
                                                    <Tooltip title="Mover para baixo">
                                                        <IconButton
                                                            color="secondary"
                                                            variant="outlined"
                                                            size="medium"
                                                            onClick={() => setTemplate(oldT => {
                                                                var newT = [...oldT];
                                                                newT.splice(i + 1, 0, newT.splice(i, 1)[0]);
                                                                return newT;
                                                            })}
                                                        >
                                                            <ArrowCircleDownIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {
                                                    totalCols < 12 && // If reached 12 cols, don't allow to add any more elements
                                                    <Tooltip title="Adicionar elemento">
                                                        <IconButton
                                                            color="secondary"
                                                            variant="outlined"
                                                            size="medium"
                                                            onClick={() => setEditElement({
                                                                rowIndex: i,
                                                                totalCols: totalCols
                                                            })}
                                                        >
                                                            <AddCircleIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                <Tooltip title="Remover secção">
                                                    <IconButton
                                                        color="error"
                                                        variant="outlined"
                                                        size="medium"
                                                        onClick={() => setTemplate(old => {
                                                            var n = [...old];
                                                            n.splice(i, 1);
                                                            return n;
                                                        })}
                                                    >
                                                        <RemoveCircleIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        }
                                    </Grid>
                                )
                            }
                        )
                    }
                    {/** ADD NEW SECTION */}
                    {
                        (editSheet || template && template.length === 0) &&
                        <Button
                            color="secondary"
                            variant="outlined"
                            sx={{ width: '100%', mt: 1 }}
                            onClick={() => setTemplate(old => [...old, []])}
                        >
                            <AddCircleIcon /> Adicionar secção
                        </Button>
                    }
                </Grid>
            </Grid>
            {/** ELEMENT EDIT DIALOG */}
            {
                editElement && Object.keys(editElement).length > 0 &&
                <PropertySheetElementEdit
                    element={editElement.element}
                    setElement={(el) => {
                        console.log("PropertySheetElementEdit setElement", el);
                        if (el) {
                            setTemplate(old => {
                                var n = [...old];
                                if (editElement.colIndex !== undefined) // If colIndex, editting
                                    n[editElement.rowIndex][editElement.colIndex] = el;
                                else // Otherwise, adding new one
                                    n[editElement.rowIndex].push(el);
                                return n;
                            });
                        }
                        setEditElement(undefined);
                    }}
                    property={property}
                    propertyImages={propertyImages}
                    totalCols={editElement.totalCols}
                />
            }
        </Grid>
    );
}

export default PropertySheetTemplateCreator;