import React, { useState, useEffect } from "react";

import {
    useParams
} from "react-router-dom";

import {
    requestGET,
    requestWithBody,
    handleRequestError,
    DateHelper
} from '../../../utils';

import {
    validatorEmail, validatorPhone
} from '../../../Components/Chavelusa/FormGroup/utils';

import {
    Grid,
    Typography,
    CircularProgress,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Button,
    Alert,
    Chip,
    Tooltip,
    FormControlLabel,
    Radio,
    RadioGroup,
    Snackbar
} from '@mui/material';

import {
    Person as PersonIcon,
    Event as EventIcon,
    OpenInNew as OpenInNewIcon
} from '@mui/icons-material';

import ContactFormBanner from '../../../Components/CRM/ContactForm/Banner';

const CLIENTS_DISTINCT = 'distinct';
const CLIENTS_SAME = 'same';

const ClientDuplicatePage = () => {

    const url = process.env.REACT_APP_API_URL + '/crm/client_duplicates/';

    // URL Data
    let { id } = useParams();

    // Get user data from API
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null); // crm.api.serializers.ClientDuplicateDetails
    const [form, setForm] = useState({}); // Form to edit client duplicate attrs
    const [formKeep, setFormKeep] = useState({}); // Form to choose attrs to keep when clients are the same
    const [conflict, setConflict] = useState(undefined); // undefined, CLIENT_DISTINCT or CLIENT_SAME

    const [newClient, setNewClient] = useState(undefined);

    useEffect(() => {
        loadClient();
        setNewClient(undefined);
    }, [id]);

    const loadClient = () => {
        // Load client agent instance from API
        requestGET(url + id + '/')
            .then(response => {
                setForm({
                    email: { val: response.email, required: true, validator: validatorEmail, error: null },
                    email2: { val: response.email2, required: false, validator: validatorEmail, error: null },
                    phone: { val: response.phone, required: true, validator: validatorPhone, error: null },
                    phone2: { val: response.phone2, required: false, validator: validatorPhone, error: null }
                });
                setFormKeep({ email: response.email, email2: response.email2, phone: response.phone, phone2: response.phone2, name: response.name, agent: response.agent.id });
                setClient(response);
                response.duplicates.length > 1 && setConflict(CLIENTS_DISTINCT);
                console.log("GOT Client", response);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting client",
                ).then(() => window.location.replace("/clientes/duplicados"))
            ).finally(() => {
                setLoading(false);
            });
    }

    const [submitting, setSubmitting] = useState(false);
    const solveConflict = (solution) => {
        console.log("solveConflict()", solution); // UNBLOCK_CREATE_NEW
        setSubmitting(true);

        // Build request body
        let body = {
            op: solution
        };
        if (solution === 'UNBLOCK_CREATE_NEW') {
            body['client'] = { ...client };
            Object.keys(form).forEach(key => { body['client'][key] = form[key]['val'] });
        } else if (solution === 'UNBLOCK_MERGE') {
            body['client'] = { ...formKeep };
            body['merge'] = client.duplicates[0].id;
        }

        requestWithBody(
            "PATCH",
            url + id + '/',
            body
        ).then(response => {
            notify("Cliente desbloqueado com sucesso!");
            console.log(
                "Client conflict solved!",
                response
            );
            setNewClient(response['client']);
        }).catch(error =>
            handleRequestError(
                error,
                [],
                "Error solving client conflict"
            )
                .then(e => {
                    console.log("HERE CATCH", e);
                    if ('detail' in e && e['detail']) {
                        notify(e['detail'], 'error');
                    } else {
                        notify('Ocorreu um problema, por favor tente novamente.', 'error');
                    }
                })
        ).finally(() => {
            setSubmitting(false);
        });

    }

    // Client duplicate form
    const updateField = (id, val) => {
        setForm(old => {
            let instance = { ...old[id] };
            const id_reverse = id.indexOf('2') >= 0 ? id.replace('2', '') : id + '2'; // Compare each field with clients primary and secondary attributes for that field
            instance['val'] = val;
            instance['error'] = false;
            if (instance['required'] && !val) {
                instance['error'] = 'Obrigatório';
            } else if (!!val) {
                if (instance['validator'] && !instance['validator'](val)) {
                    instance['error'] = 'Valor inválido';
                } else if (client.duplicates.some(d => d[id] && d[id].trim() === val.trim()) || client.duplicates.some(d => d[id_reverse] && d[id_reverse].trim() === val.trim())) {
                    instance['error'] = 'Conflicto com cliente existente';
                } else {
                    instance['error'] = false;
                }
            }
            old[id] = instance;
            return { ...old };
        });
    }

    // Helpers 
    const highlightText = (text, error) => <Typography color={error ? 'error' : 'secondary'} sx={{ fontSize: 'inherit', fontWeight: error ? 500 : 'inherit' }}>{text}</Typography>;

    const getTextField = (id, required) => <TextField
        id={id}
        variant="standard"
        value={form[id]['val']}
        required={required}
        onChange={(event) => updateField(id, event.target.value)}
        error={!!form[id]['error']}
        helperText={form[id]['error']}
        type={'text'}
    />

    const getDuplicateField = (id) => {
        var id_reverse = id.indexOf('2') >= 0 ? id.replace('2', '') : id + '2';
        var duplicate = client.duplicates.some(d => (d[id] && client[id] && d[id].trim() === client[id].trim()) || (d[id_reverse] && client[id] && d[id_reverse].trim() === client[id].trim()));
        if (conflict && conflict === CLIENTS_DISTINCT && duplicate)
            return getTextField(id, form[id].required);
        return highlightText(client[id], duplicate);
    }

    // Notifications
    const [notification, setNotification] = useState(undefined);
    const notify = (text, status = "success") => {
        setNotification({
            text: text,
            status: status
        })
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
        >
            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            {
                !loading && client &&
                // MAIN CONTAINER
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                >
                    { /* HEADER */}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                    >
                        <Typography variant='h4' sx={{ fontWeight: 500 }}>
                            Cliente duplicado
                        </Typography>

                        {
                            newClient == undefined
                                ?
                                <>
                                    {
                                        // Header tags
                                    }
                                    <Grid container direction="row" mt={2}>
                                        <Grid mr={1}>
                                            <Tooltip title="Criado a">
                                                <Chip
                                                    label={new Date(client.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                                                    icon={<EventIcon fontSize="small" />}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid mr={1}>
                                            <Tooltip title="Criado por">
                                                <Chip
                                                    label={
                                                        client.creator
                                                            ? (client.creator.user.first_name + ' ' + client.creator.user.last_name)
                                                            : "Sistema"
                                                    }
                                                    icon={<PersonIcon fontSize="small" />}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    {
                                        // Contact form
                                    }
                                    {
                                        client.forms.length > 0 &&
                                        <>
                                            <Typography variant="h6" mt={5}>
                                                {client.forms.length === 1 ? 'Formulário' : 'Formulários'} de contacto
                                            </Typography>
                                            {
                                                client.forms.map(form => <ContactFormBanner form={form} />)
                                            }
                                        </>
                                    }

                                    {
                                        // Conflicts table
                                    }
                                    <Typography variant="h6" mt={5}>
                                        Conflitos identificados
                                    </Typography>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell variant="head">Atributo\Cliente</TableCell>
                                                {
                                                    client.duplicates.map((d, i) =>
                                                        <TableCell variant="head">
                                                            Conflito {i + 1}
                                                            {
                                                                d.clientAgent &&
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{ ml: 1 }}
                                                                    href={'/clientes/' + d.clientAgent}
                                                                    target='_blank'
                                                                >
                                                                    Ficha<OpenInNewIcon sx={{ ml: 1 }} fontSize="inherit" />
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                                <TableCell variant="head">{client.duplicates.length > 0 ? 'DUPLICADO' : 'Cliente'}</TableCell>
                                            </TableRow>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, name: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.name}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Nome</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.name} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.name, false)}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.name} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(client.name, false)}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, email: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.email}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Email</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.email} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.email, d.email && form.email.val && d.email.trim() === form.email.val.trim() || d.email && form.email2.val && d.email.trim() === form.email2.val.trim())}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.email} control={<Radio />} label="" />
                                                            }
                                                            {getDuplicateField('email')}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, email2: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.email2}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Email sec.</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.email2} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.email2, d.email2 && form.email2.val && d.email2.trim() === form.email2.val.trim() || d.email2 && form.email.val && d.email2.trim() === form.email.val.trim())}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.email2} control={<Radio />} label="" />
                                                            }
                                                            {getDuplicateField('email2')}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, phone: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.phone}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Contacto</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.phone} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.phone, d.phone && form.phone.val && d.phone.trim() === form.phone.val.trim() || d.phone && form.phone2.val && d.phone.trim() === form.phone2.val.trim())}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.phone} control={<Radio />} label="" />
                                                            }
                                                            {getDuplicateField('phone')}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, phone2: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.phone2}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Contacto sec.</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.phone2} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.phone2, d.phone2 && form.phone2.val && d.phone2.trim() === form.phone2.val.trim() || d.phone2 && form.phone.val && d.phone2.trim() === form.phone.val.trim())}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.phone2} control={<Radio />} label="" />
                                                            }
                                                            {getDuplicateField('phone2')}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                            <RadioGroup
                                                onChange={(event) => setFormKeep(old => { return { ...old, agent: event.target.value } })}
                                                sx={{ display: 'contents' }}
                                                defaultValue={formKeep.agent}
                                            >
                                                <TableRow>
                                                    <TableCell variant="head">Agente</TableCell>
                                                    {client.duplicates.map((d) => <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={d.agent.id} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(d.agent.name, false)}
                                                        </Grid>
                                                    </TableCell>)}
                                                    <TableCell>
                                                        <Grid container sx={{ alignItems: 'center' }}>
                                                            {
                                                                conflict && conflict.indexOf(CLIENTS_SAME) >= 0 &&
                                                                <FormControlLabel value={client.agent.id} control={<Radio />} label="" />
                                                            }
                                                            {highlightText(client.agent.name, false)}
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </RadioGroup>
                                        </TableBody>
                                    </Table>

                                    {
                                        // Conflicts resolution
                                    }
                                    <Typography variant="h6" mt={5}>
                                        Resolução dos conflitos
                                    </Typography>
                                    {
                                        client.duplicates.length > 0 &&
                                        <RadioGroup
                                            onChange={(event) => setConflict(event.target.value)}
                                            defaultValue={client.duplicates.length > 1 && CLIENTS_DISTINCT}
                                        >
                                            <Typography my={1}>
                                                Após a análise da tabela acima, assinale o tipo de conflito abaixo.
                                            </Typography>
                                            <Grid container direction="row">
                                                <Grid xs={client.duplicates.length === 1 ? 6 : 12} pr={3}>
                                                    <FormControlLabel value={CLIENTS_DISTINCT} control={<Radio />} label="Os clientes NÃO SÃO a mesma entidade"
                                                    />
                                                    <Grid sx={{ opacity: conflict === CLIENTS_DISTINCT ? 1 : 0.1 }} py={1} xs={12}>
                                                        <Typography>
                                                            Para permitir a criação do novo cliente, corrija os campos duplicados na tabela de conflitos de maneira a eliminá-los e em seguida clique no botão abaixo.
                                                        </Typography>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={submitting || conflict !== CLIENTS_DISTINCT || Object.keys(form).some(id => {
                                                                // Compare each field with clients primary and secondary attributes for that field
                                                                var id_reverse = id.indexOf('2') >= 0 ? id.replace('2', '') : id + '2';
                                                                return client.duplicates.some(
                                                                    c => form[id]['error']
                                                                        || c[id] && form[id]['val'] && c[id].trim() === form[id]['val'].trim()
                                                                        || c[id_reverse] && form[id]['val'] && c[id_reverse].trim() === form[id]['val'].trim()
                                                                );
                                                            })}
                                                            onClick={() => solveConflict('UNBLOCK_CREATE_NEW')}
                                                            sx={{ mt: 3, width: '100%' }}
                                                        >Criar novo cliente</Button>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    client.duplicates.length === 1 &&
                                                    <Grid xs={6} pl={3}>
                                                        <FormControlLabel value={CLIENTS_SAME} control={<Radio />} label="Os clientes SÃO a mesma entidade" checked={conflict && conflict.indexOf(CLIENTS_SAME) >= 0} />
                                                        <Grid direction="row" sx={{ opacity: conflict === CLIENTS_SAME ? 1 : 0.1 }} py={1} xs={12}>
                                                            <Typography>
                                                                Antes de proceder à junção do cliente original com o duplicado, escolha para cada atributo na tabela de conflitos o que deseja manter (o valor do cliente original ou do duplicado). Quando terminar clique no botão abaixo.
                                                            </Typography>
                                                            {
                                                                parseInt(formKeep.agent) !== client.duplicates[0].agent.id &&
                                                                <Alert severity="warning" sx={{ mt: 3 }}>
                                                                    ATENÇÃO! Escolheu alterar o agente do cliente original. Ao continuar, o agente original irá perder acesso à ficha deste cliente sem aviso prévio.
                                                                </Alert>
                                                            }
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                disabled={submitting || conflict !== CLIENTS_SAME}
                                                                onClick={() => solveConflict('UNBLOCK_MERGE')}
                                                                sx={{ mt: 3, width: '100%' }}
                                                            >Proceder à junção dos clientes</Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </RadioGroup>
                                    }

                                    {
                                        client.duplicates.length === 0 &&
                                        <>
                                            <Typography my={1}>
                                                Os conflitos identificados não persistem na base de dados, provavelmente devido à edição do cliente com o qual colidia.
                                            </Typography>
                                            <Typography m1={3}>
                                                Para proceder ao desbloqueio clique no botão abaixo.
                                            </Typography>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => solveConflict('UNBLOCK_NO_COLLISIONS')}
                                                sx={{ mt: 3 }}
                                                disabled={submitting}
                                            >Desbloquear cliente</Button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {
                                        // Client created
                                    }
                                    <Typography variant="h6" mt={5}>
                                        Desbloqueio bem sucedido!
                                    </Typography>
                                    <Typography my={1}>
                                        A operação de desbloqueio foi realizada com sucesso.
                                    </Typography>
                                    <Grid container>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            sx={{ mt: 3, mr: 1, flexGrow: 1 }}
                                            href={'/clientes/' + newClient.id}
                                        >Página do cliente</Button>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            sx={{ mt: 3, ml: 1, flexGrow: 1 }}
                                            href={'/clientes/duplicados'}
                                        >Clientes duplicados</Button>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </Grid>
            }

            {
                <Snackbar
                    open={notification != undefined}
                    autoHideDuration={6000}
                    onClose={() => setNotification(undefined)}
                >
                    <Alert onClose={() => setNotification(undefined)} severity={notification && notification.status} sx={{ width: '100%' }}>
                        {notification && notification.text}
                    </Alert>
                </Snackbar>
            }
        </Grid >
    );
}

export default ClientDuplicatePage;