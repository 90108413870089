import React, {useState, useEffect} from "react";

import {
    requestGET
} from '../../../../../utils';

import { 
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';

import TemplateBanner from "../../Banner";

const TemplatesFormChoice = ({selectTemplate}) => {

    const [emailTemplates, setEmailTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    // When component loads, get templates from API 
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/backoffice/emailTemplates/')
        .then(response => {
            console.log("GOT templates", response);
            setEmailTemplates(response);
            setLoading(false);
        })
    }, []);

    return (
        <Grid container direction="column">
            <Typography variant="h6">Escolher modelo</Typography>
            {
                loading && <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            {
                emailTemplates.map(t => <Grid>
                    <TemplateBanner 
                        template={t}
                        selectSelf={() => selectTemplate(t.id)}
                    />
                </Grid>)
            }
        </Grid>
    );
}

export default TemplatesFormChoice;