import { TYPE_IMAGE, TYPE_TEXT, TYPE_QR, TYPE_AGENT, TYPE_NUMBERS } from "./enum";
import { colors } from "../../../../utils";

export const PROPERTY_SHEETS_TEMPLATES = [
    {
        label: 'Folha de montra vertical',
        orientation: 'portrait',
        isValid: (property, propertyImages) => {
            if (!propertyImages || propertyImages.length<3) return false;
            return property.title && property.parish && property.state && property.price && property.description;
        },
        getTemplate: (property, propertyImages) => {
            return [
                // 1st line, header image
                [
                    {
                        cols: 12,
                        height: 31,
                        type: TYPE_IMAGE,
                        url: propertyImages[0].image_url,
                        mb: 2
                    }
                ],
                // 2nd and 3rd lines, header with main details
                [
                    {
                        cols: 8,
                        height: 5,
                        type: TYPE_TEXT,
                        content: property.title,
                        variant: 'h6',
                        color: colors.primary,
                        mr: 1
                    },
                    {
                        cols: 4,
                        height: 5,
                        type: TYPE_TEXT,
                        content: property.parish.county.name,
                        variant: 'body1',
                        alignVertical: 'bottom',
                        align: 'right',
                        color: colors.primary
                    }
                ],
                [
                    {
                        cols: 8,
                        height: 5,
                        type: TYPE_TEXT,
                        content: property.state_display,
                        variant: 'body1',
                        alignVertical: 'top',
                        color: colors.primary,
                        mr: 1
                    },
                    {
                        cols: 4,
                        height: 5,
                        type: TYPE_TEXT,
                        content: property.price.toLocaleString() + '€',
                        variant: 'h6',
                        color: colors.primary,
                        align: 'right'
                    }
                ],
                // 4th line is description and agent card
                [
                    {
                        cols: 9,
                        height: 30,
                        type: TYPE_TEXT,
                        content: property.description,
                        variant: 'richtext',
                        mr: 1
                    },
                    {
                        cols: 3,
                        height: 30,
                        type: TYPE_AGENT,
                        image: property.agent.image,
                        name: property.agent.name,
                        contact: property.agent.contact.toString().toLocaleString()
                    }
                ],
                // 5th line is numbers
                [
                    {
                        cols: 12,
                        height: 4,
                        type: TYPE_NUMBERS,
                        ref: '',
                        area: property.area,
                        areaconstructed: property.areaconstructed,
                        bedrooms: property.bedrooms,
                        bathrooms: property.bathrooms,
                        garage: property.feature.some(f => f.name.includes("Garagem")),
                        direction: 'row'  
                    }
                ],
                // 6th line is more photos
                [
                    {
                        cols: 6,
                        height: 20,
                        type: TYPE_IMAGE,
                        url: propertyImages[1].image_url,
                        mr: 1,
                        mt: 2
                    },
                    {
                        cols: 6,
                        height: 20,
                        type: TYPE_IMAGE,
                        url: propertyImages[2].image_url,
                        mt: 2,
                    }
                ],
                // 7th line is website url
                [
                    {
                        cols: 1,
                        height: 5,
                        type: TYPE_IMAGE,
                        url: '/static/assets/logo.png',
                        size: 'auto 100%',
                        mt: 2,
                    },
                    {
                        cols: 10,
                        height: 5,
                        type: TYPE_TEXT,
                        content: process.env.REACT_APP_PUBLIC_URL.replaceAll("http://", "").replaceAll("https://", "") + '/' + property.id,
                        variant: 'body1',
                        color: colors.pureWhite,
                        backgroundColor: colors.primary,
                        align: 'center',
                        mr: 1,
                        ml: 1,
                        mt: 2
                    },
                    {
                        cols: 1,
                        height: 5,
                        type: TYPE_QR,
                        value: process.env.REACT_APP_PUBLIC_URL + '/' + property.id,
                        disposition: 'horizontal',
                        color: colors.primary,
                        align: 'right',
                        mt: 2
                    }
                ],
                // 7th line is AMI license,
                [
                    {
                        cols: 12,
                        height: 3,
                        type: TYPE_TEXT,
                        content: 'Licença 17523-AMI',
                        variant: 'caption',
                        align: 'center',
                        mr: 1
                    }
                ]
            ]
        }
    }
]