import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Button,
    CircularProgress,
    Link
} from '@mui/material';

import {
    Event as EventIcon,
    Link as LinkIcon,
    AttachFile as AttachFileIcon,
    Person as PersonIcon
} from '@mui/icons-material';

import {
    is_agent,
    USER_ID,
    canWriteSensitiveData,
    canWriteGenericData
} from "../../../../../Services/permissions";

import {
    requestWithBody,
    handleRequestError,
    DateHelper,
    requestGET
} from "../../../../utils";


import FilePreview from "react-file-preview-latest";

import PropertyDocumentBanner from "../Banner";
import ChaveLusaAlert from "../../../Chavelusa/Alert";

import { PROPERTY_STATUS_ARCHIVED, PROPERTY_STATUS_ALLOW_WRITE_ALWAYS } from "../../../../../Models/property";

const SUPPORTED_DOCS = [
    '.pdf', '.png', '.jpeg', '.jpg'
];

const PropertyDocumentWindow = ({ doc, property, updateElement, setNotify, close }) => {

    console.log("COMPONENT PropertyDocumentWindow", doc);

    // Operations
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [edit, setEdit] = useState(false);
    const [alert, setAlert] = useState(undefined);

    const deleteDoc = () => {
        setLoadingRequest(true);
        requestWithBody("DELETE", process.env.REACT_APP_API_URL + '/realstate/documents/' + doc.id + '/?property=' + doc.property, null)
            .then(response => {
                console.log("DOCUMENT DELETE SUCCESS", response);
                updateElement(doc, "Documento eliminado com sucesso", true);
                close();
            }).catch(error => {
                handleRequestError(
                    error,
                    [],
                    "Error deleting document",
                ).then(e => {
                    setNotify("Ocorreu um erro, tente novamente", true);
                });
            }).finally(() => {
                setLoadingRequest(false);
            });
    }

    const ALERT_CONFIRM_DELETE = {
        title: 'Quer mesmo eliminar este documento?',
        text: 'Esta operação é irreversível!',
        action: deleteDoc
    };

    return (
        <Grid container direction="column" sx={{ flexGrow: 1, height: '100%' }}>
            <Typography variant="h6" mb={0} sx={{ width: '95%', wordBreak: 'break-all' }}>{doc.name}</Typography>

            { /* Operations */}
            {
                property.status !== PROPERTY_STATUS_ARCHIVED &&
                <Grid my={3} container direction='row'>
                    {
                        ((PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(property.status) || !doc.category) && (is_agent || canWriteGenericData) || canWriteSensitiveData) &&
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setEdit(true)}
                            disabled={edit}
                            sx={{ mr: 1, mb: 1 }}
                        >Categorizar</Button>
                    }
                    {
                        (PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(property.status) || canWriteSensitiveData) &&
                        <Button
                            color="warning"
                            variant="outlined"
                            onClick={() => setAlert(ALERT_CONFIRM_DELETE)}
                            sx={{ mr: 1, mb: 1 }}
                            disabled={loadingRequest}
                        >Eliminar</Button>
                    }
                </Grid>
            }

            {
                edit &&
                <PropertyDocumentBanner
                    doc={doc}
                    property={property}
                    selected={false}
                    updateElement={(i, m) => {
                        updateElement(i, m);
                        setEdit(false);
                    }}
                    setNotify={setNotify}
                    editionMode={true}
                />
            }

            { /* Details */}
            <Grid mb={3}>
                {
                    doc.category &&
                    <Typography>
                        <Tooltip title="Categoria">
                            <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {doc.category.name}
                    </Typography>
                }
                <Typography>
                    <Tooltip title="Carregado a">
                        <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {new Date(doc.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                </Typography>
                {
                    doc.author &&
                    <Typography>
                        <Tooltip title="Carregado por">
                            <PersonIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {doc.author.user.name}
                    </Typography>
                }
                <Link href={doc.document} target="_blank" underline="none">
                    <Typography>
                        <LinkIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Descarregar ficheiro
                    </Typography>
                </Link>
            </Grid>

            { /* OPERATIONS */}

            { /* PREVIEW */}
            <Grid container sx={{ flexGrow: 1 }}>
                {
                    SUPPORTED_DOCS.some(e => doc.document.toLowerCase().indexOf(e) >= 0)
                        ?
                        <FilePreview
                            type={"url"}
                            url={doc.document}
                            width={'100%'}
                            height={'100%'}
                        />
                        :
                        <Grid>
                            <Typography>
                                O formato deste ficheiro não é suportado para pré-visualização.
                            </Typography>
                        </Grid>
                }
            </Grid>

            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default PropertyDocumentWindow;