import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip,
    Button
} from '@mui/material';

import {
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    Event as EventIcon,
    Home as HomeIcon,
    KingBed as KingBedIcon,
    Assignment as AssignmentIcon,
    Euro as EuroIcon,
    Delete as DeleteIcon,
    SyncLock as SyncLockIcon
} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
    is_agent,
    canWriteSensitiveData
} from "../../../../../Services/permissions";

import { openChaveLusaAdmin, requestWithBody, handleRequestError } from "../../../../utils";

import Alert from "../../../Chavelusa/Alert";
import PropertyCardHorizontal from "../../../Realstate/Property/CardHorizontal";
import FormNotes from "../../../Chavelusa/FormNotes";

import PropertyList from '../../../../Pages/Realstate/PropertyList';

import ClientClassificationPropertyVisits from "../Properties/Visits";
import ClientClassificationPropertyProposals from "../Properties/Proposals";
import ClientClassificationFormClose from '../Forms/FormClose';

const ClientClassificationWindow = ({ classification, clientAgent, updateElement, openEdit, windowChanged, setWindow, backToClassificationWindow }) => {

    console.log("COMPONENT ClientClassificationWindow", classification);

    // Operations
    const [alert, setAlert] = useState(undefined);

    const [closeClassificationOp, setCloseClassificationOp] = useState(undefined);

    const addProperties = (pids) => {
        console.log("Adding properties", pids);

        requestWithBody(
            'PATCH',
            process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgent.id,
            { properties_ids: pids.map(id => {return {id: id}}) }
        ).then(response => {
            console.log("GOT RESPONSE adding properties", response);
            updateElement(response);
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                "Error at qualification add property"
            )
        );
    }
    const deleteProperty = (pid) => {
        console.log("deleteProperty", pid);

        requestWithBody(
            'PATCH',
            process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgent.id,
            { property_id_remove: {id: pid} }
        ).then(response => {
            console.log("GOT RESPONSE remove property", response);
            updateElement(response);
        }).catch(error => 
            handleRequestError(
                error, 
                ['property_id_remove'], 
                "Error at qualification add property"
            ).then((err) => {
                console.log("then", err, 'property_id_remove' in err, err['property_id_remove'].join("; "))
                if ('property_id_remove' in err) {
                    setAlert({
                        error: true,
                        text: err['property_id_remove'].join("; ")
                    })
                }
            })
        );
    }

    const updateVisit = (property, visit, del) => {
        let visits = [];
        let updated = false;
        // Filter to remove deleted if del==true
        if (del) {
            visits = property.visits.filter(v => (v.id!=visit.id));
        }
        // Otherwise, update (or add if does not exist yet)
        else {
            visits = property.visits.map(v => {
                if (v.id===visit.id) {
                    updated = true;
                    return visit;
                }   
                return v;
            });
            !updated && visits.push(visit);
        }
        property.visits = visits;
        classification.last_changed = new Date();
        updateElement(classification, 
            del ? "A visita foi eliminada com sucesso"
            : updated ? " A visita foi atualizada com sucesso"
            : "A visita foi adicionada com sucesso"
        );
    }
    const updateProposal = (property, proposal, del) => {
        let proposals = [];
        let updated = false;
        // Filter to remove deleted if del==true
        if (del) {
            proposals = property.proposals.filter(p => (p.id!=proposal.id));
        }
        // Otherwise, update (or add if does not exist yet)
        else {
            proposals = property.proposals.map(p => {
                if (p.id===proposal.id) {
                    updated = true;
                    return proposal;
                }   
                return p;
            });
            !updated && proposals.push(proposal);
        }
        property.proposals = proposals;
        classification.last_changed = new Date();
        updateElement(classification, 
            del ? "A proposta foi eliminada com sucesso"
            : updated ? " A proposta foi atualizada com sucesso"
            : "A proposta foi adicionada com sucesso"
        );
    }

    const [addVisit, setAddVisit] = useState(undefined); // Id of ClientProperty to add visit
    const [addProposal, setAddProposal] = useState(undefined); // Id of ClientProperty to add proposal

    return (
        <Grid sx={{width:'100%'}}>
            <Typography variant="h6" mb={0}>Qualificação</Typography>
            <Typography variant="caption" mb={0}>
                Última alteração a {new Date(classification.last_changed).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
            </Typography>

            { /* State */}
            <Grid xs={12}>
                <Chip
                    label={!classification.generated_by ? (classification.closed ? "Qualificação fechada" : "Qualificação aberta") : ("Qualificação gerada pelo agente " + classification.generated_by.name)}
                    icon={classification.editable ? <AssignmentIcon fontSize="small" /> : <SyncLockIcon fontSize="small" />}
                    color={(classification.closed || !classification.editable) ? "secondary" : "primary"}
                    variant={classification.closed ? "contained" : "outlined"}
                    sx={{ my: 3 }}
                />
            </Grid>

            { /* Operations */}
            {

            }
            <Grid mb={3} xs={12} container direction='row'>
                {
                    !classification.closed && classification.editable && (is_agent || canWriteSensitiveData) && 
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={openEdit}
                        sx={{mr:1, mb: 1}}
                    >Editar</Button>
                }
                {
                    classification.editable && (is_agent || canWriteSensitiveData) && 
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setCloseClassificationOp(classification.closed ? 'open' : 'close')}
                        sx={{mr:1, mb: 1}}
                    >{classification.closed ? "Abrir" : "Fechar"}</Button>
                }
            </Grid>

            {
                classification.closed && 
                <FormNotes
                    noteOriginal={classification.closingNote}
                    url={process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgent.id}
                    attribute={'closingNote'}
                    onUpdate={updateElement}
                    formChanged={windowChanged}
                    title={"notas de fecho"}
                    deactivated={!classification.editable || !(is_agent || canWriteSensitiveData)}
                />
            }

            <FormNotes
                noteOriginal={classification.notes}
                url={process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgent.id}
                attribute={'notes'}
                onUpdate={updateElement}
                formChanged={windowChanged}
                deactivated={classification.closed || !classification.editable || !(is_agent || canWriteSensitiveData)}
            />

            { /* Details */}
            <Grid my={3}>
                <Typography>
                    <Tooltip title="Tipo de cliente">
                        <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {classification.clientType}
                    {classification.transfer && " / Aceita trespasse"}
                </Typography>
                <Typography>
                    <Tooltip title="Tipos de propriedade">
                        <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {classification.propertyTypes.map(pt => pt.propertyType.name).join(', ')}
                </Typography>
                <Typography>
                    <Tooltip title="Localizações">
                        <LocationOnIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {classification.locations.map(l =>
                        l.parish ? l.parish.name + ', ' + l.parish.county.name + ', ' + l.parish.county.district.name
                            : l.county ? l.county.name + ', ' + l.county.district.name
                                : l.district ? l.district.name
                                    : ''
                    ).join(' / ')}
                </Typography>
                <Typography>
                    <Tooltip title="Quartos">
                        <KingBedIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {classification.bedrooms}
                </Typography>
                <Typography>
                    <Tooltip title="Investimento">
                        <EuroIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {classification.investment.toLocaleString()}€
                    {classification.financing && " / Tem financiamento"}
                </Typography>
                <Typography>
                    <Tooltip title="Criada a">
                        <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    </Tooltip>
                    {new Date(classification.created).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </Typography>
            </Grid>

            { /* Features */}
            <Grid my={3}>
                <Typography variant="h6">Características procuradas</Typography>
                <Grid container>
                    {
                        classification.features.map(f =>
                            <Chip icon={f.feature.icon && <FontAwesomeIcon icon={f.feature.icon.replace("fa-", "").split(" ")} />} label={f.feature.name} color="primary" variant="outlined" />
                        )
                    }
                    {
                        !classification.features.length &&
                        <Typography variant="caption">Não foram definidas características procuradas (para adicionar edite a qualificação).</Typography>
                    }
                </Grid>
            </Grid>

            { /* Properties */}
            <Grid my={3}>
                <Grid container>
                    <Typography variant="h6">Propriedades</Typography>
                    {
                        !classification.closed && classification.editable && (is_agent || canWriteSensitiveData) &&
                        <Button
                            color="primary"
                            variant={classification.properties.length ? "outlined" : "contained"}
                            sx={{ ml: 'auto' }}
                            onClick={() => setWindow(<PropertyList
                                goBack={backToClassificationWindow}
                                urlAlt={'/crm/classifications/' + classification.id + '/properties'}
                                utilsAlt={'utils_propertyClientMatch.js'}
                                initialParams={{
                                    'state': classification.clientType === 'Comprador' ? 'S' : 'R',
                                    'agent': clientAgent.agent.id,
                                    'ignore': classification.properties.map(p => p.property.id)
                                }}
                                onSubmit={addProperties}
                                defaultOrderBy={'match'}
                            />)}
                        >
                            Adicionar
                        </Button>
                    }
                </Grid>
                {
                    classification.properties.sort((a, b) => new Date(a.last_changed) < new Date(b.last_changed)).map(p =>
                        <PropertyCardHorizontal
                            property={p.property}
                            click={() => window.open('/propriedades/' + p.property.id, '_blank')}
                            my={2}
                            actions={
                                (
                                    <Grid container>
                                        {
                                            !classification.closed && classification.editable && (is_agent || canWriteSensitiveData) &&
                                            <>
                                                <Tooltip title="Remover propriedade">
                                                    <Button
                                                        color="primary" 
                                                        sx={{mb:1, mr:1}}
                                                        variant={p.visits.length || p.proposals.length ? "outlined" : "contained"}
                                                        onClick={() => deleteProperty(p.id)}

                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Button 
                                                    color="primary" 
                                                    sx={{mb:1, mr:1}}
                                                    variant={p.visits.length ? "outlined" : "contained"}
                                                    onClick={() => setAddVisit(p.id)}
                                                >Agendar visita</Button>
                                                <Button 
                                                    color="primary" 
                                                    sx={{mb:1, mr:1}}
                                                    variant={p.proposals.length ? "outlined" : "contained"}
                                                    onClick={() => setAddProposal(p.id)}
                                                >Adicionar proposta</Button>
                                            </>
                                        }
                                    </Grid>
                                )
                            }
                            insideElements={
                                <Grid>
                                    {
                                        (p.visits.length>0 || addVisit === p.id) &&
                                        <ClientClassificationPropertyVisits 
                                            visits={p.visits} 
                                            addNew={(addVisit && addVisit === p.id)}
                                            addNewCancel={() => setAddVisit(undefined)}
                                            updateVisit={(visit, del) => updateVisit(p, visit, del)}
                                            classification={classification}
                                            clientProperty={p.id}
                                            disabled={classification.closed || !classification.editable}
                                        />
                                    }
                                    {
                                        (p.proposals.length>0 || addProposal === p.id) && 
                                        <ClientClassificationPropertyProposals 
                                            proposals={p.proposals}
                                            addNew={(addProposal && addProposal === p.id)}
                                            addNewCancel={() => setAddProposal(undefined)}
                                            updateEntity={(proposal, del) => updateProposal(p, proposal, del)}
                                            classification={classification}
                                            clientProperty={p.id}
                                            disabled={classification.closed || !classification.editable}
                                        />
                                    }
                                </Grid>
                            }
                        />
                    )
                }
                {
                    !classification.properties.length &&
                    <Typography variant="caption">Não há propriedadeas associadas a esta qualificação.</Typography>
                }
            </Grid>

            { /* Operations confirmation box */}
            {
                alert &&
                <Alert
                    error={alert.error}
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }

            {
                closeClassificationOp &&
                <ClientClassificationFormClose 
                    classification={classification}
                    clientAgentId={clientAgent.id}
                    op={closeClassificationOp}
                    updateClassification={updateElement}
                    destroy={() => setCloseClassificationOp(undefined)}
                />
            }
        </Grid>
    );
}

export default ClientClassificationWindow;