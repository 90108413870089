import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    CircularProgress,
    Paper
} from '@mui/material';

import { colors } from "../../../../utils";

import {
    Chart,
    BarSeries,
    Title,
    ArgumentAxis,
    ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';

import {
    requestGET
} from '../../../../utils';

const PropertyStatsBanner = ({ start, end, subject, property }) => {
    // Based on https://dribbble.com/shots/2641178-Stats-Exploration
    // Charts docs: https://devexpress.github.io/devextreme-reactive/react/chart/demos/overview/multiple-axes/

    const [stats, setStats] = useState(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        // Request backend for new stats when timespan changes
        requestGET(process.env.REACT_APP_API_URL + '/realstate/stats?property=' + property.id + '&subject=' + subject + '&from=' + start.split('T')[0] + '&to=' + end.split('T')[0])
            .then(response => {
                console.log("GOT stats for", subject, response);
                setStats(response);
                setLoading(false);
            });

    }, [start, end]);

    return (
        <Grid
            container
            direction="row"
            className="elementSelectable disabled"
            wrap="wrap"
            my={4}
        >
            {
                stats && stats.title && 
                <Typography px={3} pt={3} sx={{width: '100%'}} variant='h6'>{stats.title}</Typography>
            }
            {
                loading
                    ?
                    <CircularProgress sx={{ mt: 'auto', mx: 'auto', p: 3 }} />
                    :
                    <>
                        {
                            stats.numbers && stats.numbers.length>0 && stats.numbers.map(n => 
                                <Grid
                                    xs={4}
                                    m={0}
                                    p={3}
                                    container
                                    direction="column"
                                >
                                    <Typography variant="h3">{n.bigNumber}</Typography>
                                    <Typography variant="subtitle">{n.bigNumberSubtitle}</Typography>
                                    {
                                        n.description &&
                                        <Typography variant="body2" mt={2}>{n.description}</Typography>
                                    }
                                </Grid>
                            )
                        }
                        {
                            stats.data != undefined &&
                            <Grid
                                xs={8}
                                m={0}
                                p={3}
                                container
                                sx={{ alignContent: 'center', alignItems: 'center' }}
                            >
                                {
                                    stats.data && stats.data.length > 0
                                        ? <Grid sx={{width: '100%'}}>
                                            <Chart
                                                data={stats.data}
                                            >
                                                <ArgumentAxis />
                                                <ValueAxis max={7} />

                                                <BarSeries
                                                    valueField="number"
                                                    argumentField="label"
                                                    color={colors.primary}
                                                    maxBarWidth={10}
                                                />
                                                <Animation />
                                            </Chart>
                                        </Grid>
                                        : <Typography>Sem dados para mostrar</Typography>
                                }
                            </Grid>
                        }
                    </>
            }
        </Grid>
    );
}

export default PropertyStatsBanner;