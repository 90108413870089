import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Tooltip,
    Chip
} from '@mui/material';

import {
    Event as EventIcon,
    Computer as ComputerIcon,
    Chat as ChatIcon,
    Home as HomeIcon
} from '@mui/icons-material'

import ContactFormWindow from '../Window';

/**
 * Contact form banner component
 * If setWindow is given, it will display a minified version (display form status and not message)
 * It not, show all details (not status, but shows message)
 * 
 * Mandatory
 * @param {crm.api.serializers.ContactForm} form  
 * 
 * Optional parameters
 * @param {crm.api.serializers.ClientAgent} clientAgent   Used to get clientAgent id to update form state with API
 * @param {Method} setWindow                    Method called to open form window (it is given the form component to display in the window as parameter)
 * @param {Method} updateElement                Method called when form state is updated (it is given crm.api.serializers.ContactForm object as parameter)
 * @param {Boolean} selected                    Boolean with selection state (changes background color)
 */

const ContactFormBanner = ({ form, clientAgent, setWindow, updateElement, selected }) => {

    console.log("COMPONENT ContactForm", form);

    // Handle form window
    const open = () => {
        if (!selected) {
            console.log("Opening form window...");
            setWindow(<ContactFormWindow form={form} clientAgent={clientAgent} updateElement={updateElement} />);
        } else {
            setWindow(null);
        }
    }

    // When form instance is updated, if selected, update window
    useEffect(() => {
        if (selected) {
            setWindow(<ContactFormWindow form={form} clientAgent={clientAgent} updateElement={updateElement} />);
        }
    }, [form]);

    return (
        <Grid
            container
            direction="row"
            className={(!setWindow ? 'disabled ' : '') + (!selected ? "elementSelectable" : "elementSelectable selected")}
            my={4}
            onClick={setWindow && open}
        >
            {
                form.property.images && form.property.images.length>0 && 
                <Grid
                    xs={4}
                    m={0}
                    sx={{
                        backgroundImage: 'url(' + process.env.REACT_APP_STATIC + form.property.images[0].image + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                    className="boxImageTriangle"
                >
                </Grid>
            }
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={8}
            >
                <Typography variant="h6" mb={0}>Formulário de contacto</Typography>
                <Grid container>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Propriedade">
                            <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {form.property.id}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Submetido a">
                            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {new Date(form.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </Typography>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Submetido através de">
                            <ComputerIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {form.app}
                    </Typography>
                </Grid>
                {
                    setWindow && !form.processed &&
                    <Chip
                        label="Formulário de contacto pendente"
                        icon={<ChatIcon fontSize="small" />}
                        color="warning"
                        sx={{ mt: 3 }}
                    />
                }
                {
                    !setWindow && form.message &&
                    <>
                        <Typography mt={3} variant='subtitle'>Mensagem</Typography>
                        <Typography sx={{whiteSpace: 'break-spaces'}}>{form.message}</Typography>
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default ContactFormBanner;